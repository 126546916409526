import React, { useCallback, useMemo, useState } from "react";
import {
  Steps,
  Form,
  Input,
  Select,
  message,
  Spin,
  Pagination,
  Modal,
  Upload,
} from "antd";

import {
  Box,
  Button,
  Grid,
  Paper,
  Typography,
  Avatar,
  useTheme,
  TextField,
  FormControlLabel,
  Switch,
  FormControl,
  InputLabel,
  MenuItem,
  Select as MuiSelect,
} from "@mui/material";
import { UserOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import {
  boxLoadingState,
  productsSelectorList,
} from "../../../../selectors/productSelector";
import {
  BoxModel,
  Product,
  ProductType,
} from "../../../../@types/products-model";
import { RcFile, UploadFile } from "antd/es/upload/interface";
import { PlusOutlined } from "@ant-design/icons";
import { AppDispatch } from "../../../../store";
import {
  createBoxPackage,
  getBoxPackages,
} from "../../../../store/thunks/box-thunk";
import logging from "../../../../logging";

const getBase64 = (file: RcFile): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });

const { Step } = Steps;
const { Option } = Select;

const AddBox: React.FC = () => {
  const productsData: Product[] | null = useSelector(productsSelectorList);
  const isLoading: boolean = useSelector(boxLoadingState);

  const dispatch: AppDispatch = useDispatch();

  const [currentStep, setCurrentStep] = useState(0);
  const [form] = Form.useForm();
  const theme = useTheme();
  const [selectedProducts, setSelectedProducts] = useState<Product[]>([]);

  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [fileList, setFileList] = useState<UploadFile[]>([]);

  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 10; // Number of items per page in the dropdown

  // Memoized filtering and pagination
  const filteredProducts = useMemo(() => {
    const filtered =
      productsData?.filter((product) =>
        product.name.toLowerCase().includes(searchTerm.toLowerCase()),
      ) || [];
    const pageIndex = (currentPage - 1) * pageSize;
    return filtered.slice(pageIndex, pageIndex + pageSize);
  }, [searchTerm, currentPage, pageSize, productsData]);

  const handleCancel = () => setPreviewOpen(false);

  const handlePreview = async (file: UploadFile) => {
    console.log("preview", file);

    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as RcFile);
    }

    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
  };

  const handlePictureChange = (info: { fileList: any }) => {
    let fileList = info.fileList;
    fileList = fileList.slice(-1);
    setFileList(fileList);
  };
  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  const onSearch = (value: string) => {
    setSearchTerm(value);
    setCurrentPage(1); // Reset to the first page on new search
  };

  const next = () => {
    form
      .validateFields()
      .then(() => {
        setCurrentStep((current) => current + 1);
      })
      .catch((info) => {
        console.error("Validate Failed:", info);
        message.error("Please fill out the required fields before proceeding.");
      });
  };

  const prev = () => {
    setCurrentStep((current) => current - 1);
  };

  const onProductSelect = (
    selectedValues: { key: string; label?: React.ReactNode }[],
  ) => {
    const newSelectedProducts = selectedValues
      .map(({ key }) => {
        return (
          productsData?.find((product) => product.productID === key) || null
        );
      })
      .filter((product) => product !== null);

    setSelectedProducts(newSelectedProducts as Product[]);
    form.setFieldsValue({
      products: newSelectedProducts.map((product) => ({
        key: product?.productID,
        label: product?.name,
      })),
    });
  };

  const getImageUrl = (image: string | File | null): string | undefined => {
    if (typeof image === "string") return image;
    if (image instanceof File) return URL.createObjectURL(image);
    return undefined;
  };

  const steps = [
    {
      title: "Select Products",
      content: (
        <Form.Item
          name="products"
          label="Choose Products"
          rules={[
            { required: true, message: "Please select at least one product!" },
          ]}
        >
          <Select
            mode="multiple"
            placeholder="Select products"
            showSearch
            labelInValue
            value={selectedProducts.map((product) => ({
              key: product.productID,
              label: product.name,
            }))}
            filterOption={(input, option) =>
              option?.label
                ?.toString()
                .toLowerCase()
                .includes(input.toLowerCase()) ?? false
            }
            onSearch={onSearch}
            onChange={onProductSelect}
            notFoundContent={<Spin size="small" />}
            optionLabelProp="label"
            dropdownRender={(menu) => (
              <>
                {menu}
                <Box sx={{ p: 2 }}>
                  <Pagination
                    simple
                    current={currentPage}
                    total={productsData.length}
                    pageSize={pageSize}
                    onChange={setCurrentPage}
                  />
                </Box>
              </>
            )}
          >
            {filteredProducts.map((product) => (
              <Option key={product.productID} value={product.productID}>
                <Box display="flex" alignItems="center">
                  <Avatar src={getImageUrl(product.picture)}>
                    {!product.picture && <UserOutlined />}
                  </Avatar>

                  <Typography variant="body2" style={{ marginLeft: 8 }}>
                    {product.name} - NGN{product.price}
                  </Typography>
                </Box>
              </Option>
            ))}
          </Select>
        </Form.Item>
      ),
    },
    {
      title: "Add Probability",
      content: (
        <div>
          {selectedProducts.map((product, index) => (
            <Form.Item
              key={product.productID}
              label={`Probability for ${product.name}`}
              name={`products[${index}].probability`}
              rules={[{ required: true, message: "Probability is required!" }]}
            >
              <Box display="flex" alignItems="center">
                <Avatar
                  src={getImageUrl(product?.picture)}
                  sx={{ marginRight: 2 }}
                >
                  {!product?.picture && <UserOutlined />}
                </Avatar>
                <Input type="number" placeholder="Enter probability" />
              </Box>
            </Form.Item>
          ))}
        </div>
      ),
    },

    {
      title: "Box Details",
      content: (
        <div>
          <TextField
            fullWidth
            label="Box Name"
            variant="outlined"
            margin="normal"
            required
            value={form.getFieldValue("name")}
            onChange={(e) => form.setFieldsValue({ name: e.target.value })}
          />
          <Upload
            action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
            listType="picture-card"
            fileList={fileList}
            onPreview={handlePreview}
            onChange={handlePictureChange}
            beforeUpload={() => false} // Prevent auto-uploading
          >
            {fileList.length >= 1 ? null : uploadButton}
          </Upload>
          <Modal open={previewOpen} footer={null} onCancel={handleCancel}>
            <img alt="example" style={{ width: "100%" }} src={previewImage} />
          </Modal>

          <TextField
            fullWidth
            label="Description"
            variant="outlined"
            margin="normal"
            multiline
            rows={4}
            value={form.getFieldValue("description")}
            onChange={(e) =>
              form.setFieldsValue({ description: e.target.value })
            }
          />
          <TextField
            fullWidth
            label="Price Offer"
            variant="outlined"
            margin="normal"
            type="number"
            required
            value={form.getFieldValue("priceOffer")}
            onChange={(e) =>
              form.setFieldsValue({ priceOffer: e.target.value })
            }
          />
          <TextField
            fullWidth
            label="RTP Percentage"
            variant="outlined"
            margin="normal"
            type="number"
            required
            value={form.getFieldValue("rtp")}
            onChange={(e) => form.setFieldsValue({ rtp: e.target.value })}
          />
          <FormControl fullWidth margin="normal">
            <InputLabel>Status</InputLabel>
            <MuiSelect
              name="status"
              value={form.getFieldValue("status")}
              label="Status"
              onChange={(e) => form.setFieldsValue({ status: e.target.value })}
            >
              <MenuItem value="new">New</MenuItem>
              <MenuItem value="hot">Hot</MenuItem>
              <MenuItem value="updated">Updated</MenuItem>
            </MuiSelect>
          </FormControl>
          <FormControlLabel
            control={
              <Switch
                checked={form.getFieldValue("recommended") || false}
                onChange={(e) =>
                  form.setFieldsValue({ recommended: e.target.checked })
                }
              />
            }
            label="Recommended"
          />
        </div>
      ),
    },
  ];

  const handleSubmit = async () => {
    try {
      const values = form.getFieldsValue();
      const formData = createFormData(values); // Assuming createFormData correctly formats your data

      const action = createBoxPackage(formData);
      const resultAction = await dispatch(action);

      if (
        typeof resultAction.payload === "string" ||
        "error" in resultAction.payload
      ) {
        logging.error(resultAction.payload);
      } else if (createBoxPackage.fulfilled.match(resultAction)) {
        await dispatch(getBoxPackages());
        logging.info("Box successful created!");

        form.resetFields();
        setCurrentStep(0);
      } else {
        // Additional error handling if needed
        logging.error(resultAction.error);
      }
    } catch (error) {
      console.error("Failed to create box:", error);
      message.error("Failed to submit form.");
    }
  };

  const createFormData = (values: BoxModel) => {
    const formData = new FormData();
    let clonedProducts = JSON.parse(JSON.stringify(values.products));

    clonedProducts.forEach((product: ProductType, index: number) => {
      clonedProducts[index].probability = parseFloat(
        product.probability.toString(),
      );
    });

    const boxData: Partial<BoxModel> = {
      name: values.name,
      status: values.status,
      description: values.description,
      priceOffer: parseFloat(values.priceOffer.toString()),
      rtp: parseFloat(values.rtp.toString()),
      recommended: values.recommended,
      category: values.category,
      products: clonedProducts,
      level: parseFloat(values.level.toString()),
    };

    formData.append("box_data", JSON.stringify(boxData));

    if (fileList.length > 0 && fileList[0].originFileObj) {
      formData.append("box_picture", fileList[0].originFileObj);
    }

    return formData;
  };

  if (isLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <Spin size="large" />
      </Box>
    );
  }

  return (
    <Form form={form} layout="vertical" onFinish={handleSubmit}>
      <Box padding={theme.spacing(3)}>
        <Grid container spacing={3}>
          <Grid item xs={12} mb={2}>
            <Paper
              elevation={3}
              sx={{ p: 2, display: "flex", flexDirection: "column" }}
            >
              <Steps current={currentStep}>
                {steps.map((item) => (
                  <Step key={item.title} title={item.title} />
                ))}
              </Steps>
            </Paper>
          </Grid>

          <Grid item xs={12} mb={2} mt={2} sx={{ minHeight: 200 }}>
            <Paper
              elevation={3}
              sx={{ p: 2, display: "flex", flexDirection: "column" }}
            >
              <Box className="steps-content">{steps[currentStep].content}</Box>
            </Paper>
          </Grid>
          <Grid
            item
            xs={12}
            mb={2}
            mt={2}
            display="flex"
            justifyContent="flex-end"
          >
            <Box className="steps-action">
              {currentStep > 0 && (
                <Button
                  style={{ margin: "0 8px" }}
                  variant="contained"
                  color="secondary"
                  onClick={prev}
                >
                  Previous
                </Button>
              )}
              {currentStep < steps.length - 1 && (
                <Button color="primary" variant="contained" onClick={next}>
                  Next
                </Button>
              )}
              {currentStep === steps.length - 1 && (
                <Button color="primary" variant="contained" type="submit">
                  Submit
                </Button>
              )}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Form>
  );
};

export default AddBox;
