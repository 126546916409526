import React, { useEffect, useMemo } from "react";
import { Box, Grid, Typography, useTheme } from "@mui/material";

import { useDispatch, useSelector } from "react-redux";
import {
  usersSelectorList,
  usersSelectorLoadingState,
} from "../../../../selectors/userSelector";
import { UserModel } from "../../../../@types/user-model";
import ReusableTable from "../../../../components/reusable-table";
import { CustomColumn } from "../../../../@types/table";
import { searchQuery } from "../../../../selectors/seacrhSelector";
import { resetSearch } from "../../../../store/slices/search-slice";
import { AppDispatch } from "../../../../store";
import { allWalletsData } from "../../../../selectors/walletSelector";
import { WalletInfo } from "../../../../@types/wallet";
import { formatCurrency } from "../../../../utils";
import { useNavigate } from "react-router-dom";

interface DisplayWalletInfo extends WalletInfo {
  userName: string;
  totalAmount: number;
}

const WalletList = () => {
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  const allWallets: WalletInfo[] | null = useSelector(allWalletsData);
  const usersData: UserModel[] | null = useSelector(usersSelectorList);

  const isLoading = useSelector(usersSelectorLoadingState);
  const search = useSelector(searchQuery);

  const theme = useTheme();

  const columns: CustomColumn<DisplayWalletInfo>[] = useMemo(
    () => [
      { id: "walletID", label: "Wallet ID" },
      { id: "userName", label: "User Name" },
      {
        id: "totalAmount",
        label: "Total Amount",
        format: (value) => formatCurrency(value),
      },
    ],
    [],
  );

  useEffect(() => {
    dispatch(resetSearch());
  }, [dispatch]);

  const displayWallets = useMemo(() => {
    return (
      allWallets?.map((wallet) => {
        const user = usersData?.find((user) => user.userId === wallet.userID);
        return {
          ...wallet,
          userName: `${user?.firstName || ""} ${user?.lastName || ""}`,
          totalAmount:
            wallet.mainAccount + wallet.bonusAccount + wallet.buyBackAccount,
        };
      }) || []
    );
  }, [allWallets, usersData]);

  const filteredWallets = useMemo(() => {
    if (!search) return displayWallets;
    return displayWallets.filter(
      (wallet) =>
        wallet.userName.toLowerCase().includes(search.toLowerCase()) ||
        wallet.walletID.toLowerCase().includes(search.toLowerCase()) ||
        wallet.totalAmount.toString().includes(search.toLowerCase()),
    );
  }, [displayWallets, search]);

  const handleUserClick = (wallet: WalletInfo) => {
    navigate(`/dashboard/wallets/${wallet.walletID}`);
  };

  return (
    <Box padding={theme.spacing(3)}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography
            variant="h4"
            gutterBottom
            component="div"
            sx={{ fontWeight: "bold" }}
          >
            Users
          </Typography>
        </Grid>
        {isLoading ? (
          <Grid
            item
            xs={12}
            display="flex"
            justifyContent="center"
            alignContent="center"
          >
            <Typography> Loading...</Typography>
          </Grid>
        ) : (
          <Grid item xs={12}>
            <ReusableTable
              data={filteredWallets}
              columns={columns}
              paginationOptions={{
                rowsPerPage: 20,
                rowsPerPageOptions: [10, 20, 30],
                showPagination: true,
              }}
              enableRowClick={true}
              onRowClick={(item) => handleUserClick(item)}
            />
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default WalletList;
