import React, { useEffect, useMemo, useState } from "react";
import { Avatar, Box, Grid, Typography, useTheme } from "@mui/material";

import { useDispatch, useSelector } from "react-redux";

import ReusableTable from "../../../../components/reusable-table";
import dayjs, { Dayjs } from "dayjs";
import { CustomColumn } from "../../../../@types/table";
import { searchQuery } from "../../../../selectors/seacrhSelector";
import { resetSearch } from "../../../../store/slices/search-slice";
import { AppDispatch } from "../../../../store";

import { resetUserMsgError } from "../../../../store/slices/user-slice";
import { useNavigate } from "react-router-dom";
import {
  boxesSelectorList,
  productsHistorySelectorList,
  productsLoadingState,
  productsSelectorList,
} from "../../../../selectors/productSelector";
import {
  BoxModel,
  Product,
  UserProductHistory,
} from "../../../../@types/products-model";
import { usersSelectorList } from "../../../../selectors/userSelector";
import { UserModel } from "../../../../@types/user-model";
import { DatePicker, Spin } from "antd";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import { getStatusColor } from "../../../../utils";

dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);

const Winnnings = () => {
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  const winningData: UserProductHistory[] | null = useSelector(
    productsHistorySelectorList,
  );
  const usersData: UserModel[] | null = useSelector(usersSelectorList);
  const productsData: Product[] | null = useSelector(productsSelectorList);
  const packageDetails: BoxModel[] | null = useSelector(boxesSelectorList);

  const [startDate, setStartDate] = useState<dayjs.Dayjs | null>(null);
  const [endDate, setEndDate] = useState<dayjs.Dayjs | null>(null);

  const isLoading = useSelector(productsLoadingState);
  const search = useSelector(searchQuery);

  const theme = useTheme();

  useEffect(() => {
    dispatch(resetSearch());
    dispatch(resetUserMsgError());
  }, [dispatch]);

  const handleStartDateChange = (date: Dayjs | null) => {
    setStartDate(date);
  };
  const handleEndDateChange = (date: Dayjs | null) => {
    setEndDate(date);
  };

  //   if (!winningData || !usersData || !productsData || !packageDetails) {
  //     return (
  //       <Box
  //         display="flex"
  //         justifyContent="center"
  //         alignItems="center"
  //         height="100vh"
  //       >
  //         <Spin size="large" />
  //       </Box>
  //     );
  //   }

  const getUserDetails = (userID: string) => {
    const user = usersData.find((user) => user.userId === userID);
    return (
      user || {
        firstName: "Unknown",
        lastName: "Unknown",
        phoneNumber: "Unknown",
      }
    );
  };

  const getProductDetails = (productID: string) => {
    const product = productsData.find(
      (product) => product.productID === productID,
    );
    return product || { name: "Unknown", picture: "" };
  };

  const getBoxDetails = (boxID: string) => {
    const box = packageDetails.find((box) => box.boxID === boxID);
    return box || { name: "Unknown", picture: "" };
  };

  const columns: CustomColumn<UserProductHistory>[] = useMemo(
    () => [
      {
        id: "userID",
        label: "User",
        accessor: (item) => {
          const user = getUserDetails(item.userID);
          return `${user.firstName} ${user.lastName}`;
        },
        format: (value) =>
          `${getUserDetails(value).firstName} ${getUserDetails(value).lastName}` ||
          "No user details",
      },
      {
        id: "userID",
        label: "User PhoneNumber",

        format: (value) =>
          getUserDetails(value).phoneNumber || "No Phone Number",
      },
      { id: "historyID", label: "Winning ID" },
      { id: "refID", label: "Reference ID" },

      {
        id: "productID",
        label: "Product Name",
        format: (value) => {
          const product = getProductDetails(value);
          // Determine the source type and handle appropriately
          const imageSrc =
            typeof product.picture === "string" || !product.picture
              ? product.picture // Use the string URL directly, or provide a default if null/undefined
              : URL.createObjectURL(product.picture); // Only call this if data.picture is a Blob/File
          if (imageSrc) {
            return (
              <Box display="flex" alignItems="center">
                <Avatar
                  src={imageSrc}
                  alt="Product Image"
                  sx={{ marginRight: 1 }}
                />
                <span>{product.name}</span>
              </Box>
            );
          } else {
            return <Avatar />;
          }
        },
      },
      {
        id: "boxID",
        label: "Box Name",
        format: (value) => {
          const product = getBoxDetails(value);
          // Determine the source type and handle appropriately
          const imageSrc =
            typeof product.picture === "string" || !product.picture
              ? product.picture // Use the string URL directly, or provide a default if null/undefined
              : URL.createObjectURL(product.picture); // Only call this if data.picture is a Blob/File
          if (imageSrc) {
            return (
              <Box display="flex" alignItems="center">
                <Avatar
                  src={imageSrc}
                  alt="Product Image"
                  sx={{ marginRight: 1 }}
                />
                <span>{product.name}</span>
              </Box>
            );
          } else {
            return <Avatar />;
          }
        },
      },

      {
        id: "dispatchStatus",
        label: "Status",
        format: (value: string) => (
          <Box sx={{ color: getStatusColor(value) }}>{value}</Box>
        ),
      },
      {
        id: "createdAt",
        label: "Date Won",
        format: (value: string) => dayjs(value).format("YYYY-MM hh:mm"),
      },
    ],
    [getUserDetails, getProductDetails, getBoxDetails, getStatusColor],
  );

  const filteredStaffsData = useMemo(() => {
    const lowerDateBound = startDate ? dayjs(startDate).startOf("day") : null;
    const upperDateBound = endDate ? dayjs(endDate).endOf("day") : null;

    return winningData?.filter((winnings) => {
      // Date checking
      const createdAt = dayjs(winnings.createdAt);
      const matchesStartDate =
        !lowerDateBound || createdAt.isSameOrAfter(lowerDateBound);
      const matchesEndDate =
        !upperDateBound || createdAt.isSameOrBefore(upperDateBound);
      const matchesDate = matchesStartDate && matchesEndDate;

      // Search checking
      const searchLower = search.toLowerCase();
      const matchesSearch =
        !search || // Check only if search is not empty
        winnings.historyID.toLowerCase().includes(searchLower) ||
        winnings.refID.toLowerCase().includes(searchLower) ||
        winnings.dispatchStatus.toLowerCase().includes(searchLower) ||
        getUserDetails(winnings.userID)
          .firstName.toLowerCase()
          .includes(searchLower) ||
        getUserDetails(winnings.userID)
          .lastName.toLowerCase()
          .includes(searchLower);

      return matchesDate && matchesSearch;
    });
  }, [winningData, search, startDate, endDate]);

  const sortedStaffsData = React.useMemo(() => {
    // Create a copy of usersData before sorting
    return [...(filteredStaffsData || [])].sort(
      (a, b) => dayjs(b.createdAt).unix() - dayjs(a.createdAt).unix(),
    );
  }, [filteredStaffsData]);

  const handleUserClick = (winning: UserProductHistory) => {
    navigate(`/dashboard/winnings/${winning.historyID}`);
  };

  if (isLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <Spin size="large" />
      </Box>
    );
  }
  return (
    <Box padding={theme.spacing(3)}>
      <Grid container spacing={3}>
        <Grid item xs={12} display="flex" justifyContent="space-between">
          <Typography
            variant="h4"
            gutterBottom
            component="div"
            sx={{ fontWeight: "bold" }}
          >
            Winnings
          </Typography>
          <Grid item>
            <Grid item mr={2}>
              <DatePicker
                placeholder="Select Start Date"
                value={startDate}
                onChange={handleStartDateChange}
                format="D MMMM, YYYY"
                style={{ width: 200, height: 37 }}
              />
            </Grid>
            <Grid item>
              <DatePicker
                placeholder="Select End Date"
                value={endDate}
                onChange={handleEndDateChange}
                format="D MMMM, YYYY"
                style={{ width: 200, height: 37 }}
              />
            </Grid>
          </Grid>
        </Grid>
        {isLoading ? (
          <Grid
            item
            xs={12}
            display="flex"
            justifyContent="center"
            alignContent="center"
          >
            <Typography> Loading...</Typography>
          </Grid>
        ) : (
          <Grid item xs={12}>
            <ReusableTable
              data={sortedStaffsData || []}
              columns={columns}
              paginationOptions={{
                rowsPerPage: 20,
                rowsPerPageOptions: [10, 20, 30],
                showPagination: true,
              }}
              enableRowClick={true}
              onRowClick={(item) => handleUserClick(item)}
            />
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default Winnnings;
