// import { GameType } from '../@types/game';
import { SettingsData } from "../@types/game-model";
import ApiService from "./api-service";

class GameService extends ApiService {
  async getGameSettings(): Promise<any> {
    try {
      const response = await this.getNonCachingInstance().get(
        `/settings/get-settings`,
      );
      return response.data;
    } catch (error) {
      console.error("Failed to fetch game settings:", error);
      throw error;
    }
  }
  async updateGameSettings(payload: SettingsData): Promise<any> {
    try {
      const response = await this.getNonCachingInstance().post(
        `/settings/update-settings`,
        payload,
      );
      return response.data;
    } catch (error) {
      console.error("Failed to update  game  settings:", error);
      throw error;
    }
  }

  async getAllUserSpinStats(): Promise<any> {
    try {
      const response = await this.getNonCachingInstance().get(
        `/stats/get-all-palyers-stats`,
      );
      return response.data;
    } catch (error) {
      console.error("Failed to fetch users stats:", error);
      throw error;
    }
  }
  async createGameSettings(payload: SettingsData): Promise<any> {
    try {
      const response = await this.getNonCachingInstance().post(
        `/settings/create-settings`,
        payload,
      );
      return response.data;
    } catch (error) {
      console.error("Failed to create game settings :", error);
      throw error;
    }
  }
  async getGameRecord(): Promise<any> {
    try {
      const response = await this.getNonCachingInstance().get(
        `/records/get-game-records`,
      );
      return response.data;
    } catch (error) {
      console.error("Failed to fetch game records:", error);
      throw error;
    }
  }
}

const gameService = new GameService();
export default gameService;
