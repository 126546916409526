import React from "react";

import { TextField, Box, Paper, Grid, Button } from "@mui/material";
import { Upload, Modal, Spin, message } from "antd";
import { Form, Formik, FormikHelpers, FormikProps } from "formik";
import { useRef, useState } from "react";
import { Product } from "../../../../@types/products-model";
import { RcFile, UploadFile } from "antd/es/upload/interface";
import { PlusOutlined } from "@ant-design/icons";
import logging from "../../../../logging";
import { useDispatch, useSelector } from "react-redux";
import { productsLoadingState } from "../../../../selectors/productSelector";
import { getBoxPackages } from "../../../../store/thunks/box-thunk";
import { AppDispatch } from "../../../../store";
import { createProducts } from "../../../../store/thunks/product-thunk";
import * as Yup from "yup";

const getBase64 = (file: RcFile): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Product Name is required"),
  price: Yup.string().required("Product Price is required"),
  brand: Yup.string().required("Product Brand is required"),
  picture: Yup.mixed().required("Product Picture is required"),
  description: Yup.string().required("Description is required"),
});

const AddProduct: React.FC = () => {
  const formikRef = useRef<FormikProps<any>>(null);
  const isLoading: boolean = useSelector(productsLoadingState);
  const dispatch: AppDispatch = useDispatch();

  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const initialValues: Partial<Product> = {
    name: "",
    description: "",
    price: 0,
    picture: null,
    brand: "",
  };

  const handlePreview = async (file: UploadFile) => {
    console.log("preview", file);

    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as RcFile);
    }

    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
  };

  const handleCancel = () => setPreviewOpen(false);

  const handlePictureChange = (info: { fileList: any }) => {
    let fileList = info.fileList;
    fileList = fileList.slice(-1);
    setFileList(fileList);
  };
  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  const handleSubmit = async (
    values: Partial<Product>,
    actions: FormikHelpers<Partial<Product>>,
  ) => {
    try {
      const formData = createFormData(values);
      const action = createProducts(formData);
      const resultAction = await dispatch(action);

      if (
        typeof resultAction.payload === "string" ||
        "error" in resultAction.payload
      ) {
        logging.error(resultAction.payload);
      } else if (createProducts.fulfilled.match(resultAction)) {
        await dispatch(getBoxPackages());
        logging.info("Product successful created!");
      } else {
        // Additional error handling if needed
        logging.error(resultAction.error);
      }
    } catch (error) {
      console.error("Failed to create product:", error);
      message.error("Failed to submit form.");
    }

    actions.setSubmitting(false);
  };

  const createFormData = (values: Partial<Product>) => {
    const formData = new FormData();

    const productData: Partial<Product> = {
      name: values.name,
      brand: values.brand,
      description: values.description,
      price: values.price ? parseFloat(values.price.toString()) : 0,
    };

    formData.append("product_data", JSON.stringify(productData));

    if (fileList.length > 0 && fileList[0].originFileObj) {
      formData.append("product_picture", fileList[0].originFileObj);
    }

    return formData;
  };
  if (isLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <Spin size="large" />
      </Box>
    );
  }
  return (
    <Formik
      innerRef={formikRef}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values, actions) => {
        handleSubmit(values, actions);
      }}
      validateOnBlur={true}
      validateOnChange={true}
      enableReinitialize={true}
    >
      {({ errors, touched, values, handleChange, setFieldValue }) => (
        <Form>
          <Box>
            <Grid container spacing={2}>
              <Paper elevation={3} sx={{ p: 4 }}>
                <Upload
                  action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                  listType="picture-card"
                  fileList={fileList}
                  onPreview={handlePreview}
                  onChange={handlePictureChange}
                  beforeUpload={() => false} // Prevent auto-uploading
                >
                  {fileList.length >= 1 ? null : uploadButton}
                </Upload>
                <Modal open={previewOpen} footer={null} onCancel={handleCancel}>
                  <img
                    alt="example"
                    style={{ width: "100%" }}
                    src={previewImage}
                  />
                </Modal>

                <TextField
                  fullWidth
                  label="Product Name"
                  name="name"
                  margin="normal"
                  required
                  value={values.name}
                  error={touched.name && !!errors.name}
                  helperText={
                    touched.name && typeof errors.name === "string"
                      ? errors.name
                      : ""
                  }
                  onChange={handleChange}
                />
                <TextField
                  fullWidth
                  label="Price"
                  name="price"
                  required
                  margin="normal"
                  value={values.price}
                  onChange={handleChange}
                  error={touched.price && !!errors.price}
                  helperText={
                    touched.price && typeof errors.price === "string"
                      ? errors.price
                      : ""
                  }
                />

                <TextField
                  fullWidth
                  label="Brand"
                  required
                  name="brand"
                  margin="normal"
                  value={values.brand}
                  onChange={handleChange}
                  error={touched.brand && !!errors.brand}
                  helperText={
                    touched.brand && typeof errors.brand === "string"
                      ? errors.brand
                      : ""
                  }
                />
                <TextField
                  fullWidth
                  label="Description"
                  name="description"
                  required
                  value={values.description}
                  onChange={handleChange}
                  multiline
                  rows={4}
                  margin="normal"
                  error={touched.description && !!errors.description}
                  helperText={
                    touched.description &&
                    typeof errors.description === "string"
                      ? errors.description
                      : ""
                  }
                />
                <Button
                  color="primary"
                  fullWidth
                  variant="contained"
                  type="submit"
                  sx={{ mt: 4 }}
                >
                  Submit
                </Button>
              </Paper>
            </Grid>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export default AddProduct;
