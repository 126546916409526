import { createSlice } from "@reduxjs/toolkit";
import { StaffState, StaffModel } from "../../@types/staff-model";
import {
  getStaffs,
  updateStaff,
  updateStaffProfilePicture,
  changeStaffPassowrd,
  registerStaff,
} from "../thunks/staff-thunks";

const initialState: StaffState = {
  list: [] as StaffModel[],
  status: "idle" as "idle" | "loading" | "succeeded" | "failed",
  error: null as string | null,
  loading: false,
  message: null as string | null,
};

const staffSlice = createSlice({
  name: "staff",
  initialState,
  reducers: {
    resetMessage: (state) => {
      state.message = null;
    },
    resetError: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getStaffs.pending, (state) => {
        state.status = "loading";
        state.message = null;
        state.error = null;
        state.loading = true;
      })
      .addCase(getStaffs.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.list = action.payload;
        state.error = null;
        state.message = "List fetched successfully!";
        state.loading = false;
      })
      .addCase(getStaffs.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message || null;
        state.message = null;
        state.loading = false;
      });
    builder
      .addCase(updateStaff.pending, (state) => {
        state.status = "loading";
        state.message = null;
        state.error = null;
        state.loading = true;
      })
      .addCase(updateStaff.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = "Updated Successfully!";
        state.error = null;
        state.loading = false;
      })
      .addCase(updateStaff.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message || null;
        state.message = null;
        state.loading = false;
      });
    builder
      .addCase(updateStaffProfilePicture.pending, (state) => {
        state.status = "loading";
        state.message = null;
        state.error = null;
        state.loading = true;
      })
      .addCase(updateStaffProfilePicture.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = "Updated Piture Successfully!";
        state.error = null;
        state.loading = false;
      })
      .addCase(updateStaffProfilePicture.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message || null;
        state.message = null;
        state.loading = false;
      });
    builder
      .addCase(changeStaffPassowrd.pending, (state) => {
        state.status = "loading";
        state.message = null;
        state.error = null;
        state.loading = true;
      })
      .addCase(changeStaffPassowrd.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = "Password Updated  Successfully!";
        state.error = null;
        state.loading = false;
      })
      .addCase(changeStaffPassowrd.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message || null;
        state.message = null;
        state.loading = false;
      });
    builder
      .addCase(registerStaff.pending, (state) => {
        state.status = "loading";
        state.message = null;
        state.error = null;
        state.loading = true;
      })
      .addCase(registerStaff.fulfilled, (state) => {
        state.status = "succeeded";
        state.error = null;
        state.message = "Staff data created Successfully!";
        state.loading = false;
      })
      .addCase(registerStaff.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message || null;
        state.message = null;
        state.loading = false;
      });
  },
});
export const { resetMessage, resetError } = staffSlice.actions;
export default staffSlice.reducer;
