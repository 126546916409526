import { createAsyncThunk } from "@reduxjs/toolkit";
import roleService from "../../services/role-service";
import { RoleModel } from "../../@types/roles-model";
import { AxiosError } from "axios";
import { ErrorResponse } from "../../@types/common";
import logging from "../../logging";

export const getRoles = createAsyncThunk<RoleModel[], void>(
  "role/getRoles",
  async (_, thunkAPI) => {
    try {
      return await roleService.fetchRoles();
    } catch (error) {
      const axiosError = error as AxiosError;
      const data = axiosError.response?.data as ErrorResponse;
      const serverErrorMessage = data?.error ?? "Something bad went wrong";

      logging.error("Failed to fetch all roles:", serverErrorMessage);
      return thunkAPI.rejectWithValue({ message: serverErrorMessage });
    }
  },
);
export const getRoleById = createAsyncThunk<RoleModel, void>(
  "role/getRoleById",
  async (_, thunkAPI) => {
    try {
      return await roleService.fetchRolesByID();
    } catch (error) {
      const axiosError = error as AxiosError;
      const data = axiosError.response?.data as ErrorResponse;
      const serverErrorMessage = data?.error ?? "Something bad went wrong";

      logging.error("Failed to fetch user role info:", serverErrorMessage);
      return thunkAPI.rejectWithValue({ message: serverErrorMessage });
    }
  },
);

export const addNewRoles = createAsyncThunk<any, Partial<RoleModel>>(
  "role/addNewRoles",
  async (data: Partial<RoleModel>, thunkAPI) => {
    try {
      return await roleService.addNewRole(data);
    } catch (error) {
      const axiosError = error as AxiosError;
      const data = axiosError.response?.data as ErrorResponse;
      const serverErrorMessage = data?.error ?? "Something bad went wrong";

      logging.error("Failed to add new role info:", serverErrorMessage);
      return thunkAPI.rejectWithValue({ message: serverErrorMessage });
    }
  },
);

export const updateRole = createAsyncThunk<any, Partial<RoleModel>>(
  "role/updateRole",
  async (data: Partial<RoleModel>, thunkAPI) => {
    try {
      return await roleService.updateRole(data);
    } catch (error) {
      const axiosError = error as AxiosError;
      const data = axiosError.response?.data as ErrorResponse;
      const serverErrorMessage = data?.error ?? "Something bad went wrong";

      logging.error("Error updating role info:", serverErrorMessage);

      // Reject the thunk action with the server error message
      return thunkAPI.rejectWithValue({ message: serverErrorMessage });
    }
  },
);

export const deleteRole = createAsyncThunk<any, string>(
  "role/deleteRole",
  async (data: string, thunkAPI) => {
    try {
      return await roleService.deleteRoleById(data);
    } catch (error) {
      const axiosError = error as AxiosError;
      const data = axiosError.response?.data as ErrorResponse;
      const serverErrorMessage = data?.error ?? "Something bad went wrong";

      logging.error("Error deleting role info:", serverErrorMessage);

      // Reject the thunk action with the server error message
      return thunkAPI.rejectWithValue({ message: serverErrorMessage });
    }
  },
);
