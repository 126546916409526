// products-slice.ts

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  GameState,
  SettingsData,
  PlayerStats,
  GameRecord,
} from "../../@types/game-model";
import {
  getGameSettings,
  getAllUsersSpins,
  createGameSettings,
  updateGameSettings,
  getGameRecords,
} from "../thunks/game-thunk";

const initialize: GameState = {
  gameRecord: null,
  gameSettings: null,
  message: null,
  loading: false,
  error: null,
  allUsersSpinStats: null,
};

const boxSlice = createSlice({
  name: "game",
  initialState: initialize,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getGameSettings.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.message = null;
      })
      .addCase(
        getGameSettings.fulfilled,
        (state, action: PayloadAction<SettingsData>) => {
          state.gameSettings = action.payload;
          state.loading = false;
          state.message = null;
        },
      )
      .addCase(getGameSettings.rejected, (state, action) => {
        state.loading = false;
        state.message = null;
        if (action.payload) {
          state.error = action.payload.message;
        } else {
          state.error = action.error.message || null;
        }
      });
    builder
      .addCase(getAllUsersSpins.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.message = null;
      })
      .addCase(
        getAllUsersSpins.fulfilled,
        (state, action: PayloadAction<PlayerStats[]>) => {
          state.allUsersSpinStats = action.payload;
          state.loading = false;
          state.message = null;
        },
      )
      .addCase(getAllUsersSpins.rejected, (state, action) => {
        state.loading = false;
        state.message = null;
        if (action.payload) {
          state.error = action.payload.message;
        } else {
          state.error = action.error.message || null;
        }
      });
    builder
      .addCase(createGameSettings.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.message = null;
      })
      .addCase(createGameSettings.fulfilled, (state) => {
        state.loading = false;
        state.message = null;
      })
      .addCase(createGameSettings.rejected, (state, action) => {
        state.loading = false;
        state.message = null;
        if (action.payload) {
          state.error = action.payload.message;
        } else {
          state.error = action.error.message || null;
        }
      });
    builder
      .addCase(updateGameSettings.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.message = null;
      })
      .addCase(updateGameSettings.fulfilled, (state) => {
        state.loading = false;
        state.message = null;
      })
      .addCase(updateGameSettings.rejected, (state, action) => {
        state.loading = false;
        state.message = null;
        if (action.payload) {
          state.error = action.payload.message;
        } else {
          state.error = action.error.message || null;
        }
      });
    builder
      .addCase(getGameRecords.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.message = null;
      })
      .addCase(
        getGameRecords.fulfilled,
        (state, action: PayloadAction<GameRecord>) => {
          state.gameRecord = action.payload;
          state.loading = false;
          state.message = null;
        },
      )
      .addCase(getGameRecords.rejected, (state, action) => {
        state.loading = false;
        state.message = null;
        if (action.payload) {
          state.error = action.payload.message;
        } else {
          state.error = action.error.message || null;
        }
      });
  },
});

export default boxSlice.reducer;
