import React, { useEffect, useMemo } from "react";
import { Avatar, Box, Grid, Typography, useTheme } from "@mui/material";

import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import ReusableTable from "../../../../components/reusable-table";
import dayjs from "dayjs";
import { CustomColumn } from "../../../../@types/table";
import { searchQuery } from "../../../../selectors/seacrhSelector";
import { resetSearch } from "../../../../store/slices/search-slice";
import { AppDispatch } from "../../../../store";
import { BoxModel } from "../../../../@types/products-model";
import {
  boxesSelectorList,
  boxLoadingState,
} from "../../../../selectors/productSelector";

const BoxList = () => {
  const dispatch: AppDispatch = useDispatch();

  const packageDetails: BoxModel[] | null = useSelector(boxesSelectorList);
  const isLoading = useSelector(boxLoadingState);
  const search = useSelector(searchQuery);

  const theme = useTheme();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(resetSearch());
  }, [dispatch]);

  const handleUserClick = (box: BoxModel) => {
    navigate(`/dashboard/box-list/${box.boxID}`);
  };

  const columns: CustomColumn<BoxModel>[] = [
    {
      id: "picture",
      label: "Image",
      format: (value) => {
        return value ? (
          <Avatar
            src={typeof value === "string" ? value : URL.createObjectURL(value)}
            alt="Box Image"
          />
        ) : (
          <Avatar /> // Display a default avatar if no picture is available
        );
      },
    },
    { id: "name", label: "Name" },
    {
      id: "priceOffer",
      label: "Offer Price(NGN)",
      format: (value) => `${value}`,
    },
    { id: "rtp", label: "RTP", format: (value) => `${value * 100}%` },
    { id: "level", label: "Level" },
  ];

  const filteredBoxData = useMemo(() => {
    if (!search) return packageDetails;

    return packageDetails?.filter(
      (box) =>
        box.name.toLowerCase().includes(search.toLowerCase()) ||
        box.priceOffer.toString().includes(search.toLowerCase()),
    );
  }, [packageDetails, search]);

  const sortedBoxData = React.useMemo(() => {
    // Create a copy of usersData before sorting
    return [...(filteredBoxData || [])].sort(
      (a, b) => dayjs(a.priceOffer).unix() - dayjs(b.priceOffer).unix(),
    );
  }, [filteredBoxData]);

  return (
    <Box padding={theme.spacing(3)}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography
            variant="h4"
            gutterBottom
            component="div"
            sx={{ fontWeight: "bold" }}
          >
            Boxes
          </Typography>
        </Grid>
        {isLoading ? (
          <Grid
            item
            xs={12}
            display="flex"
            justifyContent="center"
            alignContent="center"
          >
            <Typography> Loading...</Typography>
          </Grid>
        ) : (
          <Grid item xs={12}>
            <ReusableTable
              data={sortedBoxData || []}
              columns={columns}
              paginationOptions={{
                rowsPerPage: 10,
                rowsPerPageOptions: [10, 20, 30],
                showPagination: true,
              }}
              enableRowClick={true}
              onRowClick={(item) => handleUserClick(item)}
            />
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default BoxList;
