import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TableHead,
  Paper,
  Box,
  TablePagination,
  useTheme,
} from "@mui/material";
import { CustomColumn } from "../../@types/table";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";

interface ReusableTableProps<T> {
  data: T[];
  columns: CustomColumn<T>[];
  paginationOptions?: {
    rowsPerPage?: number;
    rowsPerPageOptions?: number[];
    showPagination?: boolean;
  };
  headerColor?: string;
  contentColor?: string;
  bgHeaderColor?: string;
  bgContentColor?: string;
  containerComponent?: "box" | "paper";
  onRowClick?: (row: T) => void;
  enableRowClick?: boolean;
  onDelete?: (row: T) => void;
  onEdit?: (row: T) => void;
  showDeleteIcon?: boolean;
  showEditIcon?: boolean;
}

function ReusableTable<T>({
  data,
  columns,
  paginationOptions,
  enableRowClick = false,
  headerColor = "default",
  contentColor = "default",
  bgHeaderColor = "default",
  bgContentColor = "default",
  containerComponent = "paper",
  onRowClick,
  onDelete,
  onEdit,
  showDeleteIcon = false,
  showEditIcon = false,
}: ReusableTableProps<T>) {
  const theme = useTheme();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(
    paginationOptions?.rowsPerPage || 10,
  );

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  function renderTable() {
    return (
      <TableContainer component={containerComponent === "box" ? Box : Paper}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {columns.map((column, index) => (
                <TableCell
                  key={`${String(column.id)}-${index}`}
                  style={{ color: theme.palette.primary.main, fontWeight: 600 }}
                >
                  {column.label}
                </TableCell>
              ))}
              {(showDeleteIcon || showEditIcon) && (
                <TableCell
                  style={{ color: theme.palette.primary.main, fontWeight: 600 }}
                >
                  Actions
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {data
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => (
                <TableRow
                  hover
                  role="checkbox"
                  key={`${index + 1}-${index}`}
                  style={{
                    backgroundColor: bgContentColor,
                    cursor: enableRowClick ? "pointer" : "default",
                  }}
                  onClick={() =>
                    enableRowClick && onRowClick ? onRowClick(row) : undefined
                  }
                >
                  {columns.map((column, index) => (
                    <TableCell
                      key={`${String(column.id)}-${index}`}
                      style={{ color: contentColor }}
                    >
                      {column.format
                        ? column.format(row[column.id])
                        : String(row[column.id])}
                    </TableCell>
                  ))}
                  {(showDeleteIcon || showEditIcon) && (
                    <TableCell>
                      {showEditIcon && (
                        <IconButton onClick={() => onEdit && onEdit(row)}>
                          <EditIcon color="primary" />
                        </IconButton>
                      )}
                      {showDeleteIcon && (
                        <IconButton onClick={() => onDelete && onDelete(row)}>
                          <DeleteIcon color="error" />
                        </IconButton>
                      )}
                    </TableCell>
                  )}
                </TableRow>
              ))}
          </TableBody>
        </Table>
        {paginationOptions?.showPagination && (
          <TablePagination
            rowsPerPageOptions={
              paginationOptions.rowsPerPageOptions || [10, 25, 100]
            }
            component="div"
            count={data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
      </TableContainer>
    );
  }

  return containerComponent === "box" ? (
    <Box>{renderTable()}</Box>
  ) : (
    <Paper>{renderTable()}</Paper>
  );
}

export default ReusableTable;
