import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  AuthError,
  StaffLoginData,
  StaffModel,
} from "../../@types/staff-model";
import { AxiosError } from "axios";
import logging from "../../logging";
import authService from "../../services/auth-service";
import staffService from "../../services/staff-service";
import { ErrorResponse } from "../../@types/common";

export const loginStaff = createAsyncThunk<
  any,
  StaffLoginData,
  { rejectValue: AuthError }
>("auth/loginStaff", async (data: StaffLoginData, thunkAPI) => {
  try {
    return await authService.loginStaff(data);
  } catch (error) {
    const axiosError = error as AxiosError;
    const data = axiosError.response?.data as ErrorResponse;
    const serverErrorMessage = data?.error ?? "Something bad went wrong";

    logging.error("Error logging in staff:", serverErrorMessage);

    // Reject the thunk action with the server error message
    return thunkAPI.rejectWithValue({ message: serverErrorMessage });
  }
});

export const logoutStaff = createAsyncThunk(
  "auth/logoutStaff",
  async (_, { rejectWithValue }) => {
    try {
      await authService.logoutStaff();
      localStorage.removeItem("authToken");
      localStorage.removeItem("sessionID");
      return;
    } catch (error) {
      const err = error as Error;
      return rejectWithValue(
        err.message || "Unknown error occurred while logging out.",
      );
    }
  },
);

export const fetchAuthenticatedUser = createAsyncThunk<
  StaffModel,
  void,
  { rejectValue: string }
>("auth/fetchAuthenticatedUser", async (_, thunkAPI) => {
  try {
    return await staffService.fetchAuthenticatedUserInfo();
  } catch (error) {
    const axiosError = error as AxiosError;
    const data = axiosError.response?.data as ErrorResponse;
    const serverErrorMessage = data?.error ?? "Something bad went wrong";

    logging.error(
      "Failed to fetch authenticated user info:",
      serverErrorMessage,
    );
    return thunkAPI.rejectWithValue(serverErrorMessage);
  }
});
