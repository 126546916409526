import React, { FC, useEffect, useRef, useState } from "react";
import {
  Grid,
  Paper,
  Typography,
  Avatar,
  Rating,
  TextField,
  Box,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Slider,
  FormControlLabel,
  Switch,
  Autocomplete,
  Button,
  CircularProgress,
  IconButton,
} from "@mui/material";

import { useNavigate, useParams } from "react-router-dom";
import { Formik, Form, FormikProps } from "formik";
import states from "../../../../utils/states.json";
import citiesData from "../../../../utils/cities.json";
import { formatCurrency } from "../../../../utils";
import { BankDetailModel } from "../../../../@types/wallet";
import CheckIcon from "@mui/icons-material/Check";
import { useDispatch, useSelector } from "react-redux";
import { UserModel } from "../../../../@types/user-model";
import {
  usersSelectorList,
  usersSelectorLoadingState,
} from "../../../../selectors/userSelector";
import {
  allBanksDetails,
  allWalletsData,
} from "../../../../selectors/walletSelector";
import { WalletInfo } from "../../../../@types/wallet";
import { FileCopy } from "@mui/icons-material";
import { Spin } from "antd";
import { AppDispatch } from "../../../../store";
import {
  getUsers,
  updateCustomerData,
} from "../../../../store/thunks/users-thunks";
import logging from "../../../../logging";
import { StaffModel } from "../../../../@types/staff-model";
import { staffSelectorData } from "../../../../selectors/staffSelector";
import { getStaffs, updateStaff } from "../../../../store/thunks/staff-thunks";
import CustomDropdown from "../../../../components/custom-dropdown";
import { rolesSelectorList } from "../../../../selectors/roleSelector";
import { RoleModel } from "../../../../@types/roles-model";

const StaffEditForm = () => {
  const { id } = useParams<{ id: string }>();
  const allStaffData: StaffModel[] | null = useSelector(staffSelectorData);
  const navigate = useNavigate();
  const isLoading = useSelector(usersSelectorLoadingState);
  const roles = useSelector(rolesSelectorList);

  const [staff, setStaff] = useState<StaffModel | null>(null);

  const [editMode, setEditMode] = useState(false);

  const dispatch: AppDispatch = useDispatch();

  const formikRef = useRef<FormikProps<any>>(null);

  useEffect(() => {
    // Simulating fetching user data by ID
    if (id && allStaffData) {
      // Set initial selected bank
      const selectedStaff = allStaffData.find((staff) => staff.staffID === id);
      if (selectedStaff) {
        setStaff(selectedStaff);
      }
    }
  }, [id, allStaffData]);

  const getRoleData = (roleID: string) => {
    const role = roles.find((role) => role.roleID === roleID);
    return role;
  };
  const enterEditMode = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    console.log("Entering edit mode");
    setEditMode(true);
  };

  const exitEditMode = (event?: React.MouseEvent<HTMLButtonElement>) => {
    event?.preventDefault(); // Optional chaining in case event is undefined
    console.log("Exiting edit mode");
    if (formikRef.current) {
      formikRef.current.resetForm();
    }
    setEditMode(false);
  };

  const handleSubmit = async (values: StaffModel, actions: any) => {
    try {
      const resultAction = await dispatch(updateStaff(values));
      if (updateStaff.fulfilled.match(resultAction)) {
        await dispatch(getStaffs());
        logging.info("user data updated successful!");
      } else if (
        resultAction.payload &&
        typeof resultAction.payload === "string"
      ) {
        logging.error(resultAction.payload);
      } else {
        logging.error(resultAction.error);
      }
    } catch (error) {
      logging.error("Dispatch failed:", (error as Error).message);
    } finally {
      actions.setSubmitting(false);
      exitEditMode();
    }
  };

  if (isLoading || !staff) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <Spin size="large" />
      </Box>
    );
  }

  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text).then(
      () => {
        alert("Copied to clipboard!");
      },
      () => {
        alert("Failed to copy!");
      },
    );
  };

  const initialValues: StaffModel = {
    staffID: staff.staffID, // Ensure this is always defined and is never `undefined`
    roleID: staff.roleID ?? "", // Provide default values for potentially undefined properties
    email: staff.email ?? "",
    firstName: staff.firstName ?? "",
    lastName: staff.lastName ?? "",
    address: staff.address ?? "",
    postalCode: staff.postalCode ?? "",
    phoneNumber: staff.phoneNumber ?? "",
    pictureUrl: staff.pictureUrl ?? "",
    password: staff.password ?? "",
  };

  return (
    <Formik
      innerRef={formikRef}
      initialValues={initialValues}
      // validationSchema={UserSchema}
      onSubmit={handleSubmit}
      validateOnBlur={true}
      validateOnChange={true}
      enableReinitialize={true}
    >
      {({ errors, touched, values, handleChange, setFieldValue }) => (
        <Form>
          <Box>
            <Grid item xs={12}>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography
                  variant="h4"
                  gutterBottom
                  component="div"
                  sx={{ fontWeight: "bold" }}
                >
                  Staff Details
                </Typography>
                <Box display="flex" alignItems="center">
                  <IconButton
                    sx={{ marginRight: 2 }}
                    onClick={() => copyToClipboard(staff?.staffID)}
                  >
                    <FileCopy />
                  </IconButton>
                  <Typography
                    variant="body1"
                    color="primary"
                    sx={{
                      fontWeight: 600,
                      fontSize: 17,
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      maxWidth: 140,
                    }}
                  >
                    {staff?.staffID}
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Box display="flex" justifyContent="space-between" mb={3}>
              <Button
                onClick={() => navigate(-1)} // Use history.goBack() to navigate back
                variant="contained"
                color="primary"
              >
                Go Back
              </Button>
              {editMode ? (
                <>
                  <Button
                    sx={{ marginRight: 2 }}
                    type="submit"
                    variant="contained"
                    color="primary"
                  >
                    Save Changes
                  </Button>
                  <Button
                    type="button"
                    variant="contained"
                    color="secondary"
                    onClick={exitEditMode}
                  >
                    Cancel
                  </Button>
                </>
              ) : (
                <Button
                  type="button"
                  variant="contained"
                  onClick={enterEditMode}
                >
                  Edit
                </Button>
              )}
            </Box>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Paper elevation={3} sx={{ p: 2 }}>
                  <Typography
                    variant="subtitle1"
                    color="primary"
                    sx={{ fontWeight: "bold", mb: 2 }}
                  >
                    Personal Information
                  </Typography>
                  <TextField
                    fullWidth
                    label="Email"
                    name="email"
                    margin="normal"
                    inputProps={{ readOnly: true }}
                    value={values.email}
                  />
                  {editMode ? (
                    <CustomDropdown
                      label="Role"
                      roles={roles}
                      name="roleID"
                      defaultValue={values.roleID}
                      required
                    />
                  ) : (
                    <TextField
                      fullWidth
                      label="Role"
                      name="roleID"
                      margin="normal"
                      inputProps={{ readOnly: true }}
                      value={getRoleData(values.roleID)?.roleName}
                    />
                  )}
                  <TextField
                    fullWidth
                    label="Phone Number"
                    name="phoneNumber"
                    margin="normal"
                    inputProps={{ readOnly: editMode ? false : true }}
                    value={values.phoneNumber}
                    onChange={handleChange}
                    error={touched.email && !!errors.email}
                    helperText={
                      touched.email && typeof errors.email === "string"
                        ? errors.email
                        : ""
                    }
                  />

                  <TextField
                    fullWidth
                    label="First Name"
                    name="firstName"
                    margin="normal"
                    inputProps={{ readOnly: editMode ? false : true }}
                    value={values.firstName}
                    onChange={handleChange}
                    error={touched.firstName && !!errors.firstName}
                    helperText={
                      touched.firstName && typeof errors.firstName === "string"
                        ? errors.firstName
                        : ""
                    }
                  />
                  <TextField
                    fullWidth
                    label="Last Name"
                    name="lastName"
                    margin="normal"
                    inputProps={{ readOnly: editMode ? false : true }}
                    value={values.lastName}
                    onChange={handleChange}
                    error={touched.lastName && !!errors.lastName}
                    helperText={
                      touched.lastName && typeof errors.lastName === "string"
                        ? errors.lastName
                        : ""
                    }
                  />
                </Paper>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Paper elevation={3} sx={{ p: 2 }}>
                  <Typography
                    variant="subtitle1"
                    color="primary"
                    sx={{ fontWeight: "bold", mb: 2 }}
                  >
                    Contact Information
                  </Typography>

                  <TextField
                    fullWidth
                    label="Address"
                    name="address"
                    margin="normal"
                    inputProps={{ readOnly: editMode ? false : true }}
                    value={values.address}
                    onChange={handleChange}
                    error={touched.address && !!errors.address}
                    helperText={
                      touched.address && typeof errors.address === "string"
                        ? errors.address
                        : ""
                    }
                  />
                  <TextField
                    fullWidth
                    label="Postal Code"
                    name="postalCode"
                    margin="normal"
                    inputProps={{ readOnly: editMode ? false : true }}
                    value={values.postalCode}
                    onChange={handleChange}
                    error={touched.postalCode && !!errors.postalCode}
                    helperText={
                      touched.postalCode &&
                      typeof errors.postalCode === "string"
                        ? errors.postalCode
                        : ""
                    }
                  />
                </Paper>
              </Grid>
            </Grid>
          </Box>
        </Form>
      )}
    </Formik>
  );
};
export default StaffEditForm;
