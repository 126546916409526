import { AxiosResponse } from "axios";
import ApiService from "./api-service";
import { ManagesUsers } from "../@types/user-model";
import {
  BankDetailModel,
  BankVerificationDetails,
  BankVerifyRequest,
  UpdateStatusPayout,
  WalletInfo,
} from "../@types/wallet";

class WalletService extends ApiService {
  async fetchAllPayoutsPrices(): Promise<any> {
    try {
      const url = `/payouts/get-all-payouts`;
      const response: AxiosResponse = await this.axiosInstance.get(url);
      return response.data;
    } catch (error) {
      console.error("Failed to fetch payouts prices:", error);
      throw error;
    }
  }

  async fetchAllWallets(): Promise<any> {
    try {
      const url = `/wallets/get-all-wallets`;
      const response: AxiosResponse = await this.axiosInstance.get(url);
      return response.data;
    } catch (error) {
      console.error("Failed to fetch all wallets:", error);
      throw error;
    }
  }

  async updatePayoutStatus(payload: UpdateStatusPayout): Promise<any> {
    try {
      const response: AxiosResponse = await this.axiosInstance.post(
        "/payouts/update-pending-payout",
        payload,
      );
      await this.invalidateCacheByKey("get/payouts/get-all-payouts");
      return response.data;
    } catch (error) {
      console.error("Failed to update payout:", error);
      throw error;
    }
  }

  async updateUserWallet(payload: WalletInfo): Promise<any> {
    try {
      const response: AxiosResponse = await this.axiosInstance.post(
        "/wallets/update-user-wallets",
        payload,
      );
      await this.invalidateCacheByKey("get/wallets/get-all-wallets");
      return response.data;
    } catch (error) {
      console.error("Failed to update payout:", error);
      throw error;
    }
  }

  async fetchUsersSpinPrices(userIds: string[]): Promise<any> {
    try {
      const response: AxiosResponse = await this.nonCachingAxiosInstance.post(
        `/pay-table/get-pay-per-week`,
        { userIds },
      );
      return response.data;
    } catch (error) {
      console.error("Failed to fetch spin prices:", error);
      throw error;
    }
  }

  async fetchAgentUsersCommissions(payload: ManagesUsers[]): Promise<any> {
    try {
      const response: AxiosResponse = await this.getNonCachingInstance().post(
        `/paytables/get-agent-reffered-user-commissions`,
        payload,
      );
      return response.data;
    } catch (error) {
      console.error("Failed to fetch agents commission:", error);
      throw error;
    }
  }
  async getAllSumInboundTransactionsCommissions(): Promise<any> {
    try {
      const response: AxiosResponse = await this.getNonCachingInstance().get(
        `/inbound/get-all-inbound-transactions`,
      );
      return response.data;
    } catch (error) {
      console.error("Failed to fetch agents commission:", error);
      throw error;
    }
  }

  async getAllTransactions(): Promise<any> {
    try {
      const response: AxiosResponse = await this.getNonCachingInstance().get(
        `/transactions/get-all-transactions`,
      );

      return response.data;
    } catch (error) {
      console.error("Failed to fetch all transactions:", error);
      throw error;
    }
  }

  async getAllTransactionAmounts(): Promise<any> {
    try {
      const response: AxiosResponse = await this.getNonCachingInstance().get(
        `/transactions/get-all-transaction-amount`,
      );

      return response.data;
    } catch (error) {
      console.error("Failed to fetch all transactions:", error);
      throw error;
    }
  }

  async getAllBuyBackTransactions(): Promise<any> {
    try {
      const response: AxiosResponse = await this.getNonCachingInstance().get(
        `/buy-backs/get-all-buy-backs`,
      );

      return response.data;
    } catch (error) {
      console.error("Failed to fetch all buy backs:", error);
      throw error;
    }
  }

  async getAllBanksDetails(): Promise<BankDetailModel[]> {
    try {
      const response: AxiosResponse = await this.nonCachingAxiosInstance.get(
        `/paystack/get-all-bank-account`,
      );
      return response.data;
    } catch (error) {
      console.error("Failed to fetch all banks details:", error);
      throw error;
    }
  }
  async verifyBankDetails(
    payload: BankVerifyRequest,
  ): Promise<BankVerificationDetails> {
    try {
      const response: AxiosResponse = await this.axiosInstance.post(
        `/paystack/verify-bank-account`,
        payload,
        {
          headers: { "Content-Type": this.getContentType(payload) },
        },
      );
      return response.data;
    } catch (error) {
      console.error("Failed to verify bank request:", error);
      throw error;
    }
  }
}

const walletService = new WalletService();
export default walletService;
