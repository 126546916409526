import React, { useEffect, useState } from "react";
import { Box, Button, Grid, Paper, Typography, useTheme } from "@mui/material";

import { Formik } from "formik";
import { Form } from "formik";
import CustomTextField from "../../../../components/custom-textfield";
import * as yup from "yup";
import logging from "../../../../logging";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../../store";

import CustomSnackbar from "../../../../components/custom-snackbar.tsx";
import CustomDropdown from "../../../../components/custom-dropdown";
import { rolesSelectorList } from "../../../../selectors/roleSelector";
import { StaffModel } from "../../../../@types/staff-model";
import { resetError } from "../../../../store/slices/staff-slice";
import {
  getStaffs,
  registerStaff,
} from "../../../../store/thunks/staff-thunks";
import {
  staffErrorData,
  staffMessageData,
  staffSelectorLoadingState,
} from "../../../../selectors/staffSelector";
import { Spin } from "antd";

type TextFieldType = "text" | "email" | "password" | "number";

interface FieldConfig {
  label: string;
  name: string;
  type: TextFieldType;
  required?: boolean;
}

// Validation schema
const validationSchema = yup.object({
  roleID: yup.string().required("Role ID is required"),
  email: yup
    .string()
    .email("Enter a valid email")
    .required("Email is required"),
  password: yup
    .string()
    .min(8, "Password should be of minimum 8 characters length")
    .required("Password is required"),
  firstName: yup.string().required("First Name is required"),
  lastName: yup.string().required("Last Name is required"),
  address: yup.string().required("Address is required"),
  postalCode: yup.string().required("Postal Code is required"),
  phoneNumber: yup
    .string()
    .matches(/^[0-9]+$/, "Enter a valid phone number")
    .min(10)
    .max(12)
    .required("Phone number is required"),
});

const fieldsConfig: FieldConfig[] = [
  { label: "Role ID", name: "roleID", type: "text" },
  { label: "Email", name: "email", type: "email" },
  { label: "Password", name: "password", type: "password" },
  { label: "First Name", name: "firstName", type: "text" },
  { label: "Last Name", name: "lastName", type: "text" },
  { label: "Address", name: "address", type: "text" },
  { label: "Postal Code", name: "postalCode", type: "text" },
  { label: "Phone Number", name: "phoneNumber", type: "text" },
];
const AddStaff: React.FC = () => {
  const theme = useTheme();
  const dispatch: AppDispatch = useDispatch();

  const isLoading: boolean = useSelector(staffSelectorLoadingState);

  const error = useSelector(staffErrorData);
  const message = useSelector(staffMessageData);

  const roles = useSelector(rolesSelectorList);

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState<"success" | "error">(
    "success",
  );

  useEffect(() => {
    dispatch(resetError());
  }, [dispatch]);

  useEffect(() => {
    if (message) {
      setSnackbarMessage(message);
      setAlertSeverity("success");
      setOpenSnackbar(true);
    } else if (error) {
      setSnackbarMessage(error);
      setAlertSeverity("error");
      setOpenSnackbar(true);
    }
  }, [dispatch, message, error]);

  const handleRegister = async (values: StaffModel): Promise<void> => {
    try {
      const action = registerStaff(values);
      const resultAction = await dispatch(action);

      // Check if it's an error message/object based on some criterion
      if (
        typeof resultAction.payload === "string" ||
        "error" in resultAction.payload
      ) {
        logging.error(resultAction.payload);
      } else if (registerStaff.fulfilled.match(resultAction)) {
        dispatch(getStaffs());
        logging.info("Registered successful!");

        dispatch(resetError());
      } else {
        // Additional error handling if needed
        logging.error(resultAction.error);
      }
    } catch (error) {
      logging.error("Dispatch failed:", (error as Error).message);
    }
  };

  if (isLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <Spin size="large" />
      </Box>
    );
  }

  return (
    <Box padding={theme.spacing(3)}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography
            variant="h4"
            gutterBottom
            component="div"
            sx={{ fontWeight: "bold" }}
          >
            Register Staff
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Paper>
            <Box sx={{ maxWidth: 400, m: "auto", p: 3 }}>
              <Formik
                initialValues={{
                  staffID: "",
                  roleID: "",
                  email: "",
                  password: "",
                  firstName: "",
                  lastName: "",
                  address: "",
                  postalCode: "",
                  phoneNumber: "",
                  pictureUrl: null,
                }}
                validationSchema={validationSchema}
                onSubmit={handleRegister}
                validateOnBlur={false}
              >
                {({ isSubmitting }) => (
                  <Form noValidate>
                    <Grid container spacing={2}>
                      {fieldsConfig.map((field) => {
                        if (field.name === "roleID") {
                          return (
                            <Grid item xs={12} key={field.name}>
                              <CustomDropdown
                                label={field.label}
                                roles={roles}
                                name={field.name}
                                required
                              />
                            </Grid>
                          );
                        }
                        return (
                          <Grid item xs={12} key={field.name}>
                            <CustomTextField
                              label={field.label}
                              name={field.name}
                              type={field.type}
                              required
                            />
                          </Grid>
                        );
                      })}

                      <Grid item xs={12}>
                        <Button
                          type="submit"
                          fullWidth
                          variant="contained"
                          color="primary"
                          disabled={isSubmitting || isLoading}
                        >
                          Register
                        </Button>
                      </Grid>
                    </Grid>
                  </Form>
                )}
              </Formik>
              <CustomSnackbar
                message={snackbarMessage}
                severity={alertSeverity}
                open={openSnackbar}
                handleClose={() => setOpenSnackbar(false)}
              />
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AddStaff;
