const DEFAULT_NAMESPACE = "AdminPanel";

const logInDevelopment = (
  message: unknown,
  type: string,
  namespace?: string,
) => {
  if (process.env.NODE_ENV !== "production") {
    if (typeof message === "string") {
      console.log(
        `[${getDate()}] [${namespace || DEFAULT_NAMESPACE}] [${type}] ${message}`,
      );
    } else {
      console.log(
        `[${getDate()}] [${namespace || DEFAULT_NAMESPACE}] [${type}]`,
        message,
      );
    }
  }
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const info = (message: unknown, namespace?: string) => {
  logInDevelopment(message, "INFO", namespace);
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const warn = (message: unknown, namespace?: string) => {
  logInDevelopment(message, "WARN", namespace);
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const error = (message: unknown, namespace?: string) => {
  logInDevelopment(message, "ERROR", namespace);
};

const getDate = () => {
  return new Date().toISOString();
};

const logging = { info, warn, error };

export default logging;
