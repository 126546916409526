// products-slice.ts

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { BoxPacakgeState, Boxes } from "../../@types/products-model";
import {
  getBoxPackages,
  createBoxPackage,
  updateBoxPackage,
  deleteBoxPackage,
} from "../thunks/box-thunk";

const initialBoxPackageState: BoxPacakgeState = {
  data: [],
  message: null,
  loading: false,
  error: null,
};

const boxSlice = createSlice({
  name: "box",
  initialState: initialBoxPackageState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getBoxPackages.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.message = null;
      })
      .addCase(
        getBoxPackages.fulfilled,
        (state, action: PayloadAction<Boxes>) => {
          state.data = action.payload.boxes;
          state.loading = false;
          state.message = null;
        },
      )
      .addCase(getBoxPackages.rejected, (state, action) => {
        state.loading = false;
        state.message = null;
        if (action.payload) {
          state.error = action.payload.message;
        } else {
          state.error = action.error.message || null;
        }
      });
    builder
      .addCase(createBoxPackage.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.message = null;
      })
      .addCase(createBoxPackage.fulfilled, (state) => {
        state.loading = false;
        state.error = null;
        state.message = "Box package created successfully";
      })
      .addCase(createBoxPackage.rejected, (state, action) => {
        state.loading = false;
        state.message = null;
        if (action.payload) {
          state.error = action.payload.message;
        } else {
          state.error = action.error.message || null;
        }
      });
    builder
      .addCase(updateBoxPackage.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.message = null;
      })
      .addCase(updateBoxPackage.fulfilled, (state) => {
        state.loading = false;
        state.error = null;
        state.message = "Box package updated successfully";
      })
      .addCase(updateBoxPackage.rejected, (state, action) => {
        state.loading = false;
        state.message = null;
        if (action.payload) {
          state.error = action.payload.message;
        } else {
          state.error = action.error.message || null;
        }
      });
    builder
      .addCase(deleteBoxPackage.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.message = null;
      })
      .addCase(deleteBoxPackage.fulfilled, (state) => {
        state.loading = false;
        state.message = null;
        state.message = "Box package deleted successfully";
      })
      .addCase(deleteBoxPackage.rejected, (state, action) => {
        state.loading = false;
        state.message = null;
        if (action.payload) {
          state.error = action.payload.message;
        } else {
          state.error = action.error.message || null;
        }
      });
  },
});

export default boxSlice.reducer;
