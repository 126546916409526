import React, { useState } from "react";
import {
  Box,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  SxProps,
  Theme,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import PreviewIcon from "@mui/icons-material/Preview";

interface HeaderActionsProps {
  title: string;
  onEdit?: () => void;
  triggerTitle: string;
  titleColor?: string;
  titleFontSize?: string | number;
  titleFontWeight?: number | string;
  iconColor?: string;
  sxTitle?: SxProps<Theme>;
  sxIcon?: SxProps<Theme>;
  showMore?: boolean;
}

const HeaderActions: React.FC<HeaderActionsProps> = ({
  title,
  onEdit,
  triggerTitle,
  titleColor = "inherit",
  titleFontSize = 16,
  titleFontWeight = "bold",
  iconColor = "grey",
  sxTitle = {},
  sxIcon = {},
  showMore = true,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box
      mb={1}
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Typography
        sx={{
          fontSize: titleFontSize,
          fontWeight: titleFontWeight,
          color: titleColor,
          ...sxTitle,
        }}
      >
        {title}
      </Typography>
      {showMore && (
        <Box>
          <IconButton onClick={handleClick} sx={sxIcon}>
            <MoreVertIcon sx={{ color: iconColor }} />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem
              onClick={() => {
                handleClose();
                onEdit?.();
              }}
            >
              <ListItemIcon>
                <PreviewIcon />
              </ListItemIcon>
              <ListItemText>{triggerTitle}</ListItemText>
            </MenuItem>
            {/* Additional menu items can be added here */}
          </Menu>
        </Box>
      )}
    </Box>
  );
};

export default HeaderActions;
