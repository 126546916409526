import React, { useEffect, useMemo } from "react";
import { Avatar, Box, Grid, Typography, useTheme } from "@mui/material";

import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import ReusableTable from "../../../../components/reusable-table";
import dayjs from "dayjs";
import { CustomColumn } from "../../../../@types/table";
import { searchQuery } from "../../../../selectors/seacrhSelector";
import { resetSearch } from "../../../../store/slices/search-slice";
import { AppDispatch } from "../../../../store";
import { Product } from "../../../../@types/products-model";
import {
  productsLoadingState,
  productsSelectorList,
} from "../../../../selectors/productSelector";

const ProductList = () => {
  const dispatch: AppDispatch = useDispatch();

  const productsData: Product[] | null = useSelector(productsSelectorList);
  const isLoading = useSelector(productsLoadingState);
  const search = useSelector(searchQuery);

  const theme = useTheme();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(resetSearch());
  }, [dispatch]);

  const handleUserClick = (product: Product) => {
    navigate(`/dashboard/products/${product.productID}`);
  };

  const columns: CustomColumn<Product>[] = [
    {
      id: "picture",
      label: "Image",
      format: (value) => {
        return value ? (
          <Avatar
            src={typeof value === "string" ? value : URL.createObjectURL(value)}
            alt="Product Image"
          />
        ) : (
          <Avatar /> // Display a default avatar if no picture is available
        );
      },
    },
    { id: "name", label: "Name" },
    { id: "price", label: "Price(NGN)", format: (value) => `${value}` },
    { id: "brand", label: "Brand" },
  ];

  const filteredBoxData = useMemo(() => {
    if (!search) return productsData;

    return productsData?.filter(
      (product) =>
        product.name.toLowerCase().includes(search.toLowerCase()) ||
        product.price.toString().includes(search.toLowerCase()),
    );
  }, [productsData, search]);

  const sortedBoxData = React.useMemo(() => {
    // Create a copy of usersData before sorting
    return [...(filteredBoxData || [])].sort(
      (a, b) => dayjs(a.price).unix() - dayjs(b.price).unix(),
    );
  }, [filteredBoxData]);

  return (
    <Box padding={theme.spacing(3)}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography
            variant="h4"
            gutterBottom
            component="div"
            sx={{ fontWeight: "bold" }}
          >
            Products
          </Typography>
        </Grid>
        {isLoading ? (
          <Grid
            item
            xs={12}
            display="flex"
            justifyContent="center"
            alignContent="center"
          >
            <Typography> Loading...</Typography>
          </Grid>
        ) : (
          <Grid item xs={12}>
            <ReusableTable
              data={sortedBoxData || []}
              columns={columns}
              paginationOptions={{
                rowsPerPage: 10,
                rowsPerPageOptions: [10, 20, 30],
                showPagination: true,
              }}
              enableRowClick={true}
              onRowClick={(item) => handleUserClick(item)}
            />
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default ProductList;
