import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Form, FormikHelpers } from "formik";
import * as yup from "yup";
// import { Button, Input } from "antd";
import { AppDispatch } from "../../store";
import { setAuthenticated, resetError } from "../../store/slices/auth-slice";
import { loginStaff } from "../../store/thunks/auth-thunk";
import { Box, Button, Grid, Paper, Typography } from "@mui/material";
import {
  selectAutheticationLoadingState,
  authenticationError,
  authenticationMessage,
  selectAuthenticationState,
} from "../../selectors/authSelectors";
import logging from "../../logging";
import { preloadDataAfterLogin } from "../../store/thunks/preload";
import { useNavigate } from "react-router-dom";
import CustomTextField from "../../components/custom-textfield";
import CustomSnackbar from "../../components/custom-snackbar.tsx";

// Define the types for the form values
interface LoginFormValues {
  email: string;
  password: string;
}

// Validation schema
const validationSchema = yup.object({
  email: yup.string().email("Invalid email").required("Email is required"),
  password: yup.string().required("Password is required"),
});

const HomePage: React.FC = () => {
  const dispatch: AppDispatch = useDispatch();

  const isLoading = useSelector(selectAutheticationLoadingState);
  const error = useSelector(authenticationError);
  const message = useSelector(authenticationMessage);

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState<"success" | "error">(
    "success",
  );

  useEffect(() => {
    if (message) {
      setSnackbarMessage(message);
      setAlertSeverity("success");
      setOpenSnackbar(true);
    } else if (error) {
      setSnackbarMessage(error);
      setAlertSeverity("error");
      setOpenSnackbar(true);
    }
  }, [dispatch, message, error]);

  const handleLogin = async (
    values: LoginFormValues,
    actions: FormikHelpers<LoginFormValues>,
  ) => {
    try {
      const action = loginStaff(values);
      const resultAction = await dispatch(action);

      // Check if it's an error message/object based on some criterion
      if (
        typeof resultAction.payload === "string" ||
        "error" in resultAction.payload
      ) {
        logging.error(resultAction.payload);
      } else if (loginStaff.fulfilled.match(resultAction)) {
        await dispatch(preloadDataAfterLogin());
        logging.info("Login successful!");
        dispatch(setAuthenticated(true));
        dispatch(resetError());
      } else {
        // Additional error handling if needed
        logging.error(resultAction.error);
      }
    } catch (error) {
      logging.error("Dispatch failed:", (error as Error).message);
    }
    actions.setSubmitting(false);
  };

  return (
    <Box
      sx={{
        width: { xs: "100%", md: "50%" },
        margin: "auto",
        padding: 2,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "100vh",
      }}
    >
      <Formik
        initialValues={{
          email: "",
          password: "",
        }}
        validationSchema={validationSchema}
        onSubmit={handleLogin}
        validateOnBlur={false}
      >
        {({ isSubmitting }) => (
          <Form noValidate>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h6" style={{ marginBottom: "1rem" }}>
                  LOG IN
                </Typography>
                <CustomTextField
                  label="Email Address"
                  name="email"
                  required
                  autoComplete="email"
                  autoFocus
                />
              </Grid>
              <Grid item xs={12}>
                <CustomTextField
                  label="Password"
                  name="password"
                  required
                  type="password"
                  autoComplete="current-password"
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  disabled={isSubmitting || isLoading}
                >
                  Sign In
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
      <CustomSnackbar
        message={snackbarMessage}
        severity={alertSeverity}
        open={openSnackbar}
        handleClose={() => setOpenSnackbar(false)}
      />
    </Box>
  );
};

export default HomePage;
