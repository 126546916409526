import React, { FC, useEffect, useRef, useState } from "react";
import {
  Grid,
  Paper,
  Typography,
  Avatar,
  Rating,
  TextField,
  Box,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Slider,
  FormControlLabel,
  Switch,
  Autocomplete,
  Button,
  CircularProgress,
  IconButton,
} from "@mui/material";

import { useNavigate, useParams } from "react-router-dom";
import { Formik, Form, FormikProps } from "formik";
import states from "../../../../utils/states.json";
import citiesData from "../../../../utils/cities.json";
import { formatCurrency } from "../../../../utils";
import { BankDetailModel } from "../../../../@types/wallet";
import CheckIcon from "@mui/icons-material/Check";
import { useDispatch, useSelector } from "react-redux";
import { UserModel } from "../../../../@types/user-model";
import {
  usersSelectorList,
  usersSelectorLoadingState,
} from "../../../../selectors/userSelector";
import {
  allBanksDetails,
  allWalletsData,
} from "../../../../selectors/walletSelector";
import { WalletInfo } from "../../../../@types/wallet";
import { FileCopy } from "@mui/icons-material";
import { Modal, Spin, Upload } from "antd";
import { AppDispatch } from "../../../../store";
import {
  getUsers,
  updateCustomerData,
} from "../../../../store/thunks/users-thunks";
import logging from "../../../../logging";
import { StaffModel } from "../../../../@types/staff-model";
import { staffSelectorData } from "../../../../selectors/staffSelector";
import { getStaffs, updateStaff } from "../../../../store/thunks/staff-thunks";
import CustomDropdown from "../../../../components/custom-dropdown";
import { rolesSelectorList } from "../../../../selectors/roleSelector";
import { currentUserInfomation } from "../../../../selectors/authSelectors";
import { RcFile, UploadFile } from "antd/es/upload/interface";
import { PlusOutlined } from "@ant-design/icons";

const getBase64 = (file: RcFile): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });

const Profile = () => {
  const isLoading = useSelector(usersSelectorLoadingState);
  const roles = useSelector(rolesSelectorList);
  const user = useSelector(currentUserInfomation);

  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [editMode, setEditMode] = useState(false);

  const dispatch: AppDispatch = useDispatch();

  const formikRef = useRef<FormikProps<any>>(null);

  const getRoleData = (roleID: string) => {
    const role = roles.find((role) => role.roleID === roleID);
    return role;
  };
  const handleCancel = () => setPreviewOpen(false);

  const handlePreview = async (file: UploadFile) => {
    console.log("preview", file);

    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as RcFile);
    }

    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
  };

  const handlePictureChange = (info: { fileList: any }) => {
    let fileList = info.fileList;
    fileList = fileList.slice(-1);
    setFileList(fileList);
  };
  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );
  const enterEditMode = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    console.log("Entering edit mode");
    setEditMode(true);
  };

  const exitEditMode = (event?: React.MouseEvent<HTMLButtonElement>) => {
    event?.preventDefault(); // Optional chaining in case event is undefined
    console.log("Exiting edit mode");
    if (formikRef.current) {
      formikRef.current.resetForm();
    }
    setEditMode(false);
  };

  const handleSubmit = async (values: StaffModel, actions: any) => {
    try {
      const resultAction = await dispatch(updateStaff(values));
      if (updateStaff.fulfilled.match(resultAction)) {
        await dispatch(getStaffs());
        logging.info("user data updated successful!");
      } else if (
        resultAction.payload &&
        typeof resultAction.payload === "string"
      ) {
        logging.error(resultAction.payload);
      } else {
        logging.error(resultAction.error);
      }
    } catch (error) {
      logging.error("Dispatch failed:", (error as Error).message);
    } finally {
      actions.setSubmitting(false);
      exitEditMode();
    }
  };

  if (isLoading || !user) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <Spin size="large" />
      </Box>
    );
  }

  const initialValues: StaffModel = {
    staffID: user.staffID, // Ensure this is always defined and is never `undefined`
    roleID: user.roleID ?? "", // Provide default values for potentially undefined properties
    email: user.email ?? "",
    firstName: user.firstName ?? "",
    lastName: user.lastName ?? "",
    address: user.address ?? "",
    postalCode: user.postalCode ?? "",
    phoneNumber: user.phoneNumber ?? "",
    pictureUrl: user.pictureUrl ?? "",
    password: user.password ?? "",
  };

  return (
    <Formik
      innerRef={formikRef}
      initialValues={initialValues}
      // validationSchema={UserSchema}
      onSubmit={handleSubmit}
      validateOnBlur={true}
      validateOnChange={true}
      enableReinitialize={true}
    >
      {({ errors, touched, values, handleChange, setFieldValue }) => (
        <Form>
          <Box>
            <Grid item xs={12}>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography
                  variant="h4"
                  gutterBottom
                  component="div"
                  sx={{ fontWeight: "bold" }}
                >
                  Profile
                </Typography>
              </Box>
            </Grid>
            <Box display="flex" justifyContent="flex-end" mb={3}>
              {editMode ? (
                <>
                  <Button
                    sx={{ marginRight: 2 }}
                    type="submit"
                    variant="contained"
                    color="primary"
                  >
                    Save Changes
                  </Button>
                  <Button
                    type="button"
                    variant="contained"
                    color="secondary"
                    onClick={exitEditMode}
                  >
                    Cancel
                  </Button>
                </>
              ) : (
                <Button
                  type="button"
                  variant="contained"
                  onClick={enterEditMode}
                >
                  Edit
                </Button>
              )}
            </Box>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Paper
                  elevation={3}
                  sx={{
                    p: 2,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  {editMode ? (
                    <Upload
                      action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                      listType="picture-card"
                      fileList={fileList}
                      onPreview={handlePreview}
                      onChange={handlePictureChange}
                      beforeUpload={() => false} // Prevent auto-uploading
                    >
                      {fileList.length >= 1 ? null : uploadButton}
                    </Upload>
                  ) : (
                    <Avatar
                      src={
                        typeof user.pictureUrl === "string"
                          ? user.pictureUrl
                          : ""
                      }
                      sx={{ width: 200, height: 200, borderRadius: "10%" }}
                    />
                  )}
                  <Modal
                    open={previewOpen}
                    footer={null}
                    onCancel={handleCancel}
                  >
                    <img
                      alt="example"
                      style={{ width: "100%" }}
                      src={previewImage}
                    />
                  </Modal>
                  <TextField
                    fullWidth
                    label="Email"
                    name="email"
                    margin="normal"
                    inputProps={{ readOnly: true }}
                    value={values.email}
                  />
                  <TextField
                    fullWidth
                    label="Phone Number"
                    name="phoneNumber"
                    margin="normal"
                    inputProps={{ readOnly: editMode ? false : true }}
                    value={values.phoneNumber}
                    onChange={handleChange}
                    error={touched.email && !!errors.email}
                    helperText={
                      touched.email && typeof errors.email === "string"
                        ? errors.email
                        : ""
                    }
                  />
                </Paper>
              </Grid>
              <Grid item xs={12} md={6}>
                <Paper elevation={3} sx={{ p: 2 }}>
                  <Typography
                    variant="subtitle1"
                    color="primary"
                    sx={{ fontWeight: "bold", mb: 2 }}
                  >
                    Role & Name
                  </Typography>

                  {editMode ? (
                    <CustomDropdown
                      label="Role"
                      roles={roles}
                      name="roleID"
                      defaultValue={values.roleID}
                      required
                    />
                  ) : (
                    <TextField
                      fullWidth
                      label="Role"
                      name="roleID"
                      margin="normal"
                      inputProps={{ readOnly: true }}
                      value={getRoleData(values.roleID)?.roleName}
                    />
                  )}

                  <TextField
                    fullWidth
                    label="First Name"
                    name="firstName"
                    margin="normal"
                    inputProps={{ readOnly: editMode ? false : true }}
                    value={values.firstName}
                    onChange={handleChange}
                    error={touched.firstName && !!errors.firstName}
                    helperText={
                      touched.firstName && typeof errors.firstName === "string"
                        ? errors.firstName
                        : ""
                    }
                  />
                  <TextField
                    fullWidth
                    label="Last Name"
                    name="lastName"
                    margin="normal"
                    inputProps={{ readOnly: editMode ? false : true }}
                    value={values.lastName}
                    onChange={handleChange}
                    error={touched.lastName && !!errors.lastName}
                    helperText={
                      touched.lastName && typeof errors.lastName === "string"
                        ? errors.lastName
                        : ""
                    }
                  />
                </Paper>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Paper elevation={3} sx={{ p: 2 }}>
                  <Typography
                    variant="subtitle1"
                    color="primary"
                    sx={{ fontWeight: "bold", mb: 2 }}
                  >
                    Contact Information
                  </Typography>

                  <TextField
                    fullWidth
                    label="Address"
                    name="address"
                    margin="normal"
                    inputProps={{ readOnly: editMode ? false : true }}
                    value={values.address}
                    onChange={handleChange}
                    error={touched.address && !!errors.address}
                    helperText={
                      touched.address && typeof errors.address === "string"
                        ? errors.address
                        : ""
                    }
                  />
                  <TextField
                    fullWidth
                    label="Postal Code"
                    name="postalCode"
                    margin="normal"
                    inputProps={{ readOnly: editMode ? false : true }}
                    value={values.postalCode}
                    onChange={handleChange}
                    error={touched.postalCode && !!errors.postalCode}
                    helperText={
                      touched.postalCode &&
                      typeof errors.postalCode === "string"
                        ? errors.postalCode
                        : ""
                    }
                  />
                </Paper>
              </Grid>
            </Grid>
          </Box>
        </Form>
      )}
    </Formik>
  );
};
export default Profile;
