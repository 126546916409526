import React, { useState } from "react";
import {
  TextField,
  InputAdornment,
  IconButton,
  TextFieldProps,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useField } from "formik";

type CustomTextFieldProps = {
  label: string;
  name: string;
  type?: "text" | "password" | "number" | "email";
  required?: boolean;
  autoComplete?: string;
  autoFocus?: boolean;
  multiline?: boolean;
  visuallyHidden?: boolean;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  readOnly?: boolean;
  rows?: number;
} & Omit<TextFieldProps, "name" | "type">;

const visuallyHiddenStyles = {
  border: 0,
  clip: "rect(0 0 0 0)",
  height: "1px",
  margin: "-1px",
  overflow: "hidden",
  padding: 0,
  position: "absolute",
  width: "1px",
  whiteSpace: "nowrap",
};

const CustomTextField: React.FC<CustomTextFieldProps> = ({
  label,
  type = "text",
  readOnly = false,
  multiline = false,
  rows,
  ...props
}) => {
  const [field, meta] = useField(props.name);
  const [showPassword, setShowPassword] = useState(false);
  const hasError = meta.touched && meta.error;

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event: React.MouseEvent) => {
    event.preventDefault();
  };

  return (
    <TextField
      {...field}
      {...props}
      type={type === "password" && !showPassword ? "password" : "text"}
      label={label}
      id={props.name}
      variant="outlined"
      fullWidth
      aria-labelledby={label}
      error={Boolean(hasError)}
      helperText={hasError ? meta.error : ""}
      sx={props.visuallyHidden ? visuallyHiddenStyles : {}}
      InputProps={
        type === "password"
          ? {
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                  </IconButton>
                </InputAdornment>
              ),
            }
          : {}
      }
      inputProps={{ readOnly }}
      multiline={multiline} // Pass multiline prop to TextField
      rows={multiline ? rows : 1} // Pass rows prop to TextField if multiline is true
    />
  );
};

export default CustomTextField;
