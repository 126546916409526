import { createAsyncThunk } from "@reduxjs/toolkit";
import userService from "../../services/user-service";
import {
  AgentModel,
  AllAgentReferedUser,
  MessageType,
  SendMessageContent,
  SpinPriceResponse,
  UserModel,
} from "../../@types/user-model";
import { AxiosError } from "axios";
import { ErrorResponse, PayloadError } from "../../@types/common";
import logging from "../../logging";
import { fetchAllAgentUsersSpinPrice } from "./wallet-thunk";

export const getUsers = createAsyncThunk<UserModel[], void>(
  "users/getUsers",
  async (_, thunkAPI) => {
    try {
      return await userService.fetchUsers();
    } catch (error) {
      const axiosError = error as AxiosError;
      const data = axiosError.response?.data as ErrorResponse;
      const serverErrorMessage = data?.error ?? "Something bad went wrong";

      logging.error("Failed to fetch all users list:", serverErrorMessage);
      return thunkAPI.rejectWithValue({ message: serverErrorMessage });
    }
  },
);

export const getAgents = createAsyncThunk<AgentModel[], void>(
  "users/getAgents",
  async (_, thunkAPI) => {
    try {
      return await userService.fetchAgents();
    } catch (error) {
      const axiosError = error as AxiosError;
      const data = axiosError.response?.data as ErrorResponse;
      const serverErrorMessage = data?.error ?? "Something bad went wrong";

      logging.error("Failed to fetch all agents list:", serverErrorMessage);
      return thunkAPI.rejectWithValue({ message: serverErrorMessage });
    }
  },
);

export const getAllMessages = createAsyncThunk<
  MessageType[],
  void,
  { rejectValue: PayloadError }
>("users/getAllMessages", async (_, thunkAPI) => {
  try {
    return await userService.fetchAllMessages();
  } catch (error) {
    const axiosError = error as AxiosError;
    const data = axiosError.response?.data as ErrorResponse;
    const serverErrorMessage = data?.error ?? "Something bad went wrong";

    logging.error("Failed to fetch all message list:", serverErrorMessage);
    return thunkAPI.rejectWithValue({ message: serverErrorMessage });
  }
});

export const createMessage = createAsyncThunk<
  any,
  MessageType,
  { rejectValue: PayloadError }
>("users/createMessage", async (payload: MessageType, thunkAPI) => {
  try {
    return await userService.CreateMessage(payload);
  } catch (error) {
    const axiosError = error as AxiosError;
    const data = axiosError.response?.data as ErrorResponse;
    const serverErrorMessage = data?.error ?? "Something bad went wrong";

    logging.error("Failed to create message:", serverErrorMessage);
    return thunkAPI.rejectWithValue({ message: serverErrorMessage });
  }
});

export const updateMessage = createAsyncThunk<
  any,
  MessageType,
  { rejectValue: PayloadError }
>("users/updateMessage", async (payload: MessageType, thunkAPI) => {
  try {
    return await userService.UpdateMessage(payload);
  } catch (error) {
    const axiosError = error as AxiosError;
    const data = axiosError.response?.data as ErrorResponse;
    const serverErrorMessage = data?.error ?? "Something bad went wrong";

    logging.error("Failed to update message:", serverErrorMessage);
    return thunkAPI.rejectWithValue({ message: serverErrorMessage });
  }
});
export const sendUserMessages = createAsyncThunk<
  any,
  SendMessageContent,
  { rejectValue: PayloadError }
>("users/sendUserMessages", async (payload: SendMessageContent, thunkAPI) => {
  try {
    return await userService.SendUsersMessages(payload);
  } catch (error) {
    const axiosError = error as AxiosError;
    const data = axiosError.response?.data as ErrorResponse;
    const serverErrorMessage = data?.error ?? "Something bad went wrong";

    logging.error("Failed to send user message:", serverErrorMessage);
    return thunkAPI.rejectWithValue({ message: serverErrorMessage });
  }
});
type ErrorPayload = {
  error?: string;
  message?: string;
};
function isErrorPayload(payload: any): payload is ErrorPayload {
  return (
    payload &&
    (typeof payload.error === "string" || typeof payload.message === "string")
  );
}

export const fetchAllAgentUsers = createAsyncThunk<
  AllAgentReferedUser[],
  string
>("users/fetchAllAgentUsers", async (agentID: string, thunkAPI) => {
  try {
    const users: AllAgentReferedUser[] =
      await userService.fetchAllAgentUsers(agentID);
    const userIds = users.map((user) => user.userId);

    const spinPricesAction = await thunkAPI.dispatch(
      fetchAllAgentUsersSpinPrice(userIds),
    );
    if (fetchAllAgentUsersSpinPrice.rejected.match(spinPricesAction)) {
      let errorMessage = "Failed to fetch spin prices"; // Default error message

      // Use the custom type guard to check and access the error details
      if (isErrorPayload(spinPricesAction.payload)) {
        errorMessage =
          spinPricesAction.payload.error ??
          spinPricesAction.payload.message ??
          errorMessage;
      }

      throw new Error(errorMessage);
    }

    const spinPrices = spinPricesAction.payload as SpinPriceResponse;

    const combinedData = users.map((user) => ({
      ...user,
      spinPrice: spinPrices[user.userId] || 0,
    }));

    return combinedData;
  } catch (error) {
    const axiosError = error as AxiosError;
    const data = axiosError.response?.data as ErrorResponse;
    const serverErrorMessage = data?.error ?? "Something bad went wrong";
    logging.error("Failed to fetch all users list:", serverErrorMessage);
    return thunkAPI.rejectWithValue({ message: serverErrorMessage });
  }
});

export const updateCustomerData = createAsyncThunk<any, UserModel>(
  "users/updateCustomerData",
  async (payload: UserModel, thunkAPI) => {
    try {
      return await userService.updateCustomerProfiles(payload);
    } catch (error) {
      const axiosError = error as AxiosError;
      const data = axiosError.response?.data as ErrorResponse;
      const serverErrorMessage = data?.error ?? "Something bad went wrong";

      logging.error("Failed to update customer data:", serverErrorMessage);
      return thunkAPI.rejectWithValue({ message: serverErrorMessage });
    }
  },
);
