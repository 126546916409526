import React from "react";
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { useField } from "formik";
import { RoleModel } from "../../@types/roles-model";

type CustomDropdownProps = {
  label: string;
  name: string;
  roles: RoleModel[];
  required?: boolean;
  defaultValue?: string;
};

const CustomDropdown: React.FC<CustomDropdownProps> = ({
  label,
  roles,
  defaultValue,
  ...props
}) => {
  const [field, meta] = useField(props);
  const hasError = meta.touched && meta.error;

  return (
    <FormControl variant="outlined" fullWidth error={Boolean(hasError)}>
      <InputLabel>{label}</InputLabel>
      <Select {...field} {...props} label={label} defaultValue={defaultValue}>
        {roles.map((role) => (
          <MenuItem key={role.roleID} value={role.roleID}>
            {role.roleName}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default CustomDropdown;
