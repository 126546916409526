import { RootState } from "../store";

export const walletStateLoading = (state: RootState) => state.wallet.loading;
export const allWalletsData = (state: RootState) => state.wallet.allUserWallets;
export const allPayoutData = (state: RootState) => state.wallet.allPayout;
export const getAllTransactions = (state: RootState) =>
  state.wallet.allTransactions;
export const getAllAmounts = (state: RootState) =>
  state.wallet.allTransactionsAmount;
export const getAllBuyBackTransactions = (state: RootState) =>
  state.wallet.allBuyBackTransactions;
export const allBanksDetails = (state: RootState) =>
  state.wallet.allBanksDetails;
export const isBankAccountVerified = (state: RootState) =>
  state.wallet.isBankAccountVerified;
