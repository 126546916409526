import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { ErrorResponse, PayloadError } from "../../@types/common";
import logging from "../../logging";
import productService from "../../services/product-service";
import {
  AirtimeResponse,
  Product,
  Products,
  UpdateProductWonRequest,
  UserProductHistory,
  VtuPurchaseAggregates,
} from "../../@types/products-model";
import { AuthError } from "../../@types/staff-model";

export const getProducts = createAsyncThunk<
  Products,
  void,
  { rejectValue: PayloadError }
>("product/getProducts", async (_, thunkAPI) => {
  try {
    return await productService.fetchProducts();
  } catch (error) {
    const axiosError = error as AxiosError;
    const data = axiosError.response?.data as ErrorResponse;
    const serverErrorMessage = data?.error ?? "Something bad went wrong";

    logging.error("Failed to fetch all products:", serverErrorMessage);
    return thunkAPI.rejectWithValue({ message: serverErrorMessage });
  }
});

export const createProducts = createAsyncThunk<
  any,
  FormData,
  { rejectValue: PayloadError }
>("product/createProducts", async (data: FormData, thunkAPI) => {
  try {
    return await productService.createProducts(data);
  } catch (error) {
    const axiosError = error as AxiosError;
    const data = axiosError.response?.data as ErrorResponse;
    const serverErrorMessage = data?.error ?? "Something bad went wrong";

    logging.error("Failed to fetch all products:", serverErrorMessage);
    return thunkAPI.rejectWithValue({ message: serverErrorMessage });
  }
});

export const updateProducts = createAsyncThunk<
  any,
  FormData,
  { rejectValue: PayloadError }
>("product/updateProducts", async (data: FormData, thunkAPI) => {
  try {
    return await productService.updateProducts(data);
  } catch (error) {
    const axiosError = error as AxiosError;
    const data = axiosError.response?.data as ErrorResponse;
    const serverErrorMessage = data?.error ?? "Something bad went wrong";

    logging.error("Failed to update products:", serverErrorMessage);
    return thunkAPI.rejectWithValue({ message: serverErrorMessage });
  }
});

export const deleteProducts = createAsyncThunk<
  any,
  string,
  { rejectValue: PayloadError }
>("product/deleteProducts", async (productID: string, thunkAPI) => {
  try {
    return await productService.deleteProduct(productID);
  } catch (error) {
    const axiosError = error as AxiosError;
    const data = axiosError.response?.data as ErrorResponse;
    const serverErrorMessage = data?.error ?? "Something bad went wrong";

    logging.error("Failed to delete products:", serverErrorMessage);
    return thunkAPI.rejectWithValue({ message: serverErrorMessage });
  }
});

export const fetchAllUserProductsHistory = createAsyncThunk<
  UserProductHistory[],
  void,
  { rejectValue: PayloadError }
>("product/fetchAllUserProductsHistory", async (_, thunkAPI) => {
  try {
    return await productService.fetchAllUserProductsHistory();
  } catch (error) {
    const axiosError = error as AxiosError;
    const data = axiosError.response?.data as ErrorResponse;
    const serverErrorMessage = data?.error ?? "Something bad went wrong";

    logging.error(
      "Failed to get all user products history:",
      serverErrorMessage,
    );
    return thunkAPI.rejectWithValue({ message: serverErrorMessage });
  }
});
export const fetchAllVtuPurchases = createAsyncThunk<
AirtimeResponse[],
  void,
  { rejectValue: PayloadError }
>("product/fetchAllVtuPurchases", async (_, thunkAPI) => {
  try {
    return await productService.fetchAllVTUPurchases();
  } catch (error) {
    const axiosError = error as AxiosError;
    const data = axiosError.response?.data as ErrorResponse;
    const serverErrorMessage = data?.error ?? "Something bad went wrong";

    logging.error(
      "Failed to get all purchased vtu:",
      serverErrorMessage,
    );
    return thunkAPI.rejectWithValue({ message: serverErrorMessage });
  }
});

export const getVtuAggregatedAmounts = createAsyncThunk<
VtuPurchaseAggregates,
  void
>("product/getVtuAggregatedAmounts", async (_, thunkAPI) => {
  try {
    return await productService.getVtuAggregatedAmounts();
  } catch (error) {
    const axiosError = error as AxiosError;
    const data = axiosError.response?.data as ErrorResponse;
    const serverErrorMessage = data?.error ?? "Something bad went wrong";

    logging.error("Failed to fetch all vtu aggregated amounts:", serverErrorMessage);
    return thunkAPI.rejectWithValue({ message: serverErrorMessage });
  }
});

export const updateProductWon = createAsyncThunk<
  any,
  UpdateProductWonRequest,
  { rejectValue: AuthError }
>(
  "history/updateProductWon",
  async (payload: UpdateProductWonRequest, thunkAPI) => {
    try {
      const packageData = await productService.UpdateWonProduct(payload);
      return packageData;
    } catch (error) {
      const axiosError = error as AxiosError;
      const data = axiosError.response?.data as ErrorResponse;
      const serverErrorMessage = data?.error ?? "Something bad went wrong";

      logging.error(
        "Failed to fetch history won pacakges info:",
        serverErrorMessage,
      );
      return thunkAPI.rejectWithValue({ message: serverErrorMessage });
    }
  },
);
