import { AxiosResponse } from "axios";
import ApiService from "./api-service";
import localforage from "localforage";
import { Boxes } from "../@types/products-model";
import productService from "./product-service";

class BoxService extends ApiService {
  async fetchBoxPackages(): Promise<any> {
    try {
      const currentVersion = await productService.getVersions();
      const cacheKey = `get/box/get-all-packages`;
      const cachedData = await localforage.getItem<Boxes>(cacheKey);
      if (cachedData && cachedData.version === currentVersion.packageVersion) {
        return cachedData.boxes;
      } else {
        // Fetch new data if the version doesn't match
        const response: AxiosResponse = await this.axiosInstance.get(
          "/box/get-all-packages",
        );
        const dataToCache = {
          version: currentVersion,
          boxes: response.data.boxes,
        };
        await localforage.setItem(cacheKey, dataToCache);
        return response.data;
      }
    } catch (error) {
      console.error("Failed to fetch all box packages:", error);
      throw error;
    }
  }

  async createBoxPackages(data: FormData): Promise<any> {
    try {
      const response: AxiosResponse = await this.axiosInstance.post(
        "/box/create-package",
        data,
        {
          headers: { "Content-Type": this.getContentType(data) },
        },
      );
      await this.invalidateCacheByKey("get/box/get-all-packages");
      return response.data;
    } catch (error) {
      console.error("Error creating  box packages:", error);
      throw error;
    }
  }

  async updateBoxPackages(data: FormData): Promise<any> {
    try {
      const response: AxiosResponse = await this.axiosInstance.put(
        "/box/update-package",
        data,
        {
          headers: { "Content-Type": this.getContentType(data) },
        },
      );
      await this.invalidateCacheByKey("get/box/get-all-packages");
      return response.data;
    } catch (error) {
      console.error("Error updating  box packages:", error);
      throw error;
    }
  }

  async deleteBoxPackages(boxID: string): Promise<any> {
    try {
      const response: AxiosResponse = await this.axiosInstance.put(
        `/box/delete-package/${boxID}`,
      );
      await this.invalidateCacheByKey("get/box/get-all-packages");
      return response.data;
    } catch (error) {
      console.error("Error deleting  box packages:", error);
      throw error;
    }
  }
}

const boxService = new BoxService();
export default boxService;
