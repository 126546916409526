import React, { useEffect, useMemo } from "react";
import { Box, Grid, Typography, useTheme } from "@mui/material";

import { useDispatch, useSelector } from "react-redux";

import ReusableTable from "../../../../components/reusable-table";
import dayjs from "dayjs";
import { CustomColumn } from "../../../../@types/table";
import { searchQuery } from "../../../../selectors/seacrhSelector";
import { resetSearch } from "../../../../store/slices/search-slice";
import { AppDispatch } from "../../../../store";
import { StaffModel } from "../../../../@types/staff-model";
import {
  staffSelectorData,
  staffSelectorLoadingState,
} from "../../../../selectors/staffSelector";
import { rolesSelectorList } from "../../../../selectors/roleSelector";
import { resetUserMsgError } from "../../../../store/slices/user-slice";
import { useNavigate } from "react-router-dom";

const StaffList = () => {
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  const staffsData: StaffModel[] | null = useSelector(staffSelectorData);
  const isLoading = useSelector(staffSelectorLoadingState);
  const search = useSelector(searchQuery);
  const roles = useSelector(rolesSelectorList);

  const theme = useTheme();

  useEffect(() => {
    dispatch(resetSearch());
    dispatch(resetUserMsgError());
  }, [dispatch]);

  const columns: CustomColumn<StaffModel>[] = [
    { id: "roleID", label: "Role", format: (value) => getRoleName(value) },
    { id: "firstName", label: "First Name" },
    { id: "lastName", label: "Last Name" },
    { id: "email", label: "Email" },
    { id: "phoneNumber", label: "Phone Number" },
  ];

  const getRoleName = (roleID: string) => {
    const role = roles.find((role) => role.roleID === roleID);
    return role ? role.roleName : "Role not found";
  };

  const filteredStaffsData = useMemo(() => {
    if (!search) return staffsData;

    return staffsData?.filter(
      (user) =>
        user.firstName.toLowerCase().includes(search.toLowerCase()) ||
        user.lastName.toLowerCase().includes(search.toLowerCase()) ||
        user.email.toLowerCase().includes(search.toLowerCase()) ||
        user.phoneNumber.toLowerCase().includes(search.toLowerCase()),
    );
  }, [staffsData, search]);

  const sortedStaffsData = React.useMemo(() => {
    // Create a copy of usersData before sorting
    return [...(filteredStaffsData || [])].sort(
      (a, b) => dayjs(a.firstName).unix() - dayjs(b.firstName).unix(),
    );
  }, [filteredStaffsData]);

  const handleUserClick = (staff: StaffModel) => {
    navigate(`/dashboard/staffs/${staff.staffID}`);
  };
  return (
    <Box padding={theme.spacing(3)}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography
            variant="h4"
            gutterBottom
            component="div"
            sx={{ fontWeight: "bold" }}
          >
            Staffs
          </Typography>
        </Grid>
        {isLoading ? (
          <Grid
            item
            xs={12}
            display="flex"
            justifyContent="center"
            alignContent="center"
          >
            <Typography> Loading...</Typography>
          </Grid>
        ) : (
          <Grid item xs={12}>
            <ReusableTable
              data={sortedStaffsData || []}
              columns={columns}
              paginationOptions={{
                rowsPerPage: 20,
                rowsPerPageOptions: [10, 20, 30],
                showPagination: true,
              }}
              enableRowClick={true}
              onRowClick={(item) => handleUserClick(item)}
            />
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default StaffList;
