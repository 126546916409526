import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { MessageState, MessageType } from "../../@types/user-model";
import {
  createMessage,
  getAllMessages,
  updateMessage,
  sendUserMessages,
} from "../thunks/users-thunks";

const initialState: MessageState = {
  messages: [],
  loading: false,
  error: null,
  status: "loading",
  message: null,
};

const massagesSlice = createSlice({
  name: "messages",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createMessage.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.message = null;
      })
      .addCase(createMessage.fulfilled, (state) => {
        state.loading = false;
        state.message = "Message created successfully";
      })
      .addCase(createMessage.rejected, (state, action) => {
        state.loading = false;
        state.message = null;
        if (action.payload) {
          state.error = action.payload.message;
        } else {
          state.error = action.error.message || null;
        }
      });
    builder
      .addCase(getAllMessages.pending, (state) => {
        state.status = "loading";
        state.error = null;
        state.loading = true;
      })
      .addCase(
        getAllMessages.fulfilled,
        (state, action: PayloadAction<MessageType[]>) => {
          state.status = "succeeded";
          state.messages = action.payload;
          state.error = null;
          state.loading = false;
        },
      )
      .addCase(getAllMessages.rejected, (state, action) => {
        state.status = "failed";
        if (action.payload) {
          state.error = action.payload.message;
        } else {
          state.error = action.error.message || null;
        }
        state.loading = false;
      });
    builder
      .addCase(updateMessage.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.message = null;
        state.status = "loading";
      })
      .addCase(updateMessage.fulfilled, (state) => {
        state.loading = false;
        state.message = "Message updated successfully";
        state.status = "succeeded";
      })
      .addCase(updateMessage.rejected, (state, action) => {
        state.loading = false;
        state.message = null;
        state.status = "failed";
        if (action.payload) {
          state.error = action.payload.message;
        } else {
          state.error = action.error.message || null;
        }
      });
    builder
      .addCase(sendUserMessages.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.message = null;
        state.status = "loading";
      })
      .addCase(sendUserMessages.fulfilled, (state) => {
        state.loading = false;
        state.message = state.message;
        state.status = "succeeded";
      })
      .addCase(sendUserMessages.rejected, (state, action) => {
        state.loading = false;
        state.message = null;
        state.status = "failed";
        if (action.payload) {
          state.error = action.payload.message;
        } else {
          state.error = action.error.message || null;
        }
      });
  },
});

export default massagesSlice.reducer;
