import React from "react";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

type SnackbarProps = {
  message: string;
  severity: "success" | "warning" | "error" | "info";
  open: boolean;
  handleClose: () => void;
};

const CustomSnackbar: React.FC<SnackbarProps> = ({
  message,
  severity,
  open,
  handleClose,
}) => {
  return (
    <Snackbar
      open={open}
      autoHideDuration={6000}
      onClose={handleClose}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
    >
      <Alert onClose={handleClose} severity={severity} variant="filled">
        {message}
      </Alert>
    </Snackbar>
  );
};

export default CustomSnackbar;
