import React, { useEffect, useRef, useState } from "react";
import {
  Grid,
  Paper,
  Typography,
  Avatar,
  TextField,
  Box,
  Button,
  IconButton,
} from "@mui/material";

import { useNavigate, useParams } from "react-router-dom";
import { Formik, Form, FormikProps } from "formik";
import { useDispatch, useSelector } from "react-redux";

import {
  boxesSelectorList,
  productsHistorySelectorList,
  productsLoadingState,
  productsSelectorList,
} from "../../../../selectors/productSelector";
import {
  BoxModel,
  Product,
  UpdateProductWonRequest,
  UserProductHistory,
} from "../../../../@types/products-model";
import { DatePicker, Modal, Spin, Upload } from "antd";
import { RcFile, UploadFile } from "antd/es/upload/interface";
import { PlusOutlined } from "@ant-design/icons";
import * as Yup from "yup";
import logging from "../../../../logging";
import { AppDispatch } from "../../../../store";
import {
  fetchAllUserProductsHistory,
  getProducts,
  updateProducts,
  updateProductWon,
} from "../../../../store/thunks/product-thunk";
import { currentUserInfomation } from "../../../../selectors/authSelectors";
import { rolesSelectorList } from "../../../../selectors/roleSelector";
import { FileCopy } from "@mui/icons-material";
import dayjs, { Dayjs } from "dayjs";

const EditWinningForm = () => {
  const { id } = useParams<{ id: string }>();
  const winningData: UserProductHistory[] | null = useSelector(
    productsHistorySelectorList,
  );
  const navigate = useNavigate();
  const isLoading: boolean = useSelector(productsLoadingState);
  const dispatch: AppDispatch = useDispatch();

  const [winning, SetWinningHistory] = useState<UserProductHistory | null>(
    null,
  );
  const [editMode, setEditMode] = useState(false);
  const [actionMode, setActionMode] = useState("normal");

  const productsData: Product[] | null = useSelector(productsSelectorList);
  const packageDetails: BoxModel[] | null = useSelector(boxesSelectorList);
  const user = useSelector(currentUserInfomation);
  const roles = useSelector(rolesSelectorList);

  const formikRef = useRef<FormikProps<any>>(null);

  const deliveryOptions = [
    "pending",
    "processing",
    "buy-back",
    "delivered",
    "cash-exchange",
    "in-transit",
  ];
  const unchangedOptions = ["buy-back", "delivered", "cash-exchange"];
  const actionOption = ["status-change", "normal"];
  useEffect(() => {
    // Simulating fetching user data by ID
    if (id && winningData) {
      // Set initial selected bank
      const selectedWin = winningData.find(
        (winning) => winning.historyID === id,
      );

      SetWinningHistory(selectedWin || null);
    }
  }, [id]);

  const enterEditMode = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setEditMode(true);
  };

  const exitEditMode = (event?: React.MouseEvent<HTMLButtonElement>) => {
    event?.preventDefault(); // Optional chaining in case event is undefined
    if (formikRef.current) {
      formikRef.current.resetForm();
    }
    setEditMode(false);
  };

  if (!winning || !packageDetails || !productsData) {
    return <Typography>Loading user data...</Typography>;
  }

  const handleSubmit = async (values: UserProductHistory, actions: any) => {
    const updatedData: UpdateProductWonRequest = {
      userID: values.userID,
      historyID: values.historyID,
      action: actionMode,
      updatedFields: {
        postalCode: values.postalCode,
        street: values.street,
        city: values.city,
        state: values.state,
        country: values.country,
        transactionAmount: winning.transactionAmount,
        adminNotes: values.adminNotes,
        resolutionNotes: values.resolutionNotes,
        dispatchStatus: values.dispatchStatus,
        trackingNumber: values.trackingNumber,
        courierService: values.courierService,
        phoneNumber: values.phoneNumber,
        dispatchDate: values.dispatchDate
          ? dayjs(values.dispatchDate).format()
          : null,
        deliveryDate: values.deliveryDate
          ? dayjs(values.deliveryDate).format()
          : null,
      },
    };
    try {
      // Assuming you have an updateProducts thunk function.
      const resultAction = await dispatch(updateProductWon(updatedData));
      if (updateProductWon.fulfilled.match(resultAction)) {
        await dispatch(fetchAllUserProductsHistory());
        logging.info("Product updated successful!");
      } else if (
        resultAction.payload &&
        (typeof resultAction.payload === "string" ||
          "error" in resultAction.payload)
      ) {
        logging.error(resultAction.payload);
      } else {
        logging.error(resultAction.error);
      }
    } catch (error) {
      logging.error("Dispatch failed:", (error as Error).message);
    } finally {
      actions.setSubmitting(false);
      exitEditMode();
    }
  };

  const getProductDetails = (productID?: string) => {
    const product = productsData.find(
      (product) => product.productID === productID,
    );
    return product || { name: "Unknown", picture: "", price: 0 };
  };

  const getBoxDetails = (boxID?: string) => {
    const box = packageDetails.find((box) => box.boxID === boxID);
    return box || { name: "Unknown", picture: "", priceOffer: 0 };
  };
  const getRoleData = (roleID?: string) => {
    const role = roles && roles.find((role) => role.roleID === roleID);
    return role;
  };

  const getBoxImageUrl = (boxID?: string) => {
    if (boxID) {
      const item = getBoxDetails(boxID);
      if (!item || !item.picture) {
        return undefined;
      }
      const imageSrc =
        typeof item.picture === "string"
          ? item.picture // Use the string URL directly
          : URL.createObjectURL(item.picture); // Handle File objects
      return imageSrc;
    }
  };
  const getProductImageUrl = (productID?: string) => {
    if (productID) {
      const item = getProductDetails(productID);

      if (!item || !item.picture) {
        return undefined; // Return undefined instead of null if there's no picture
      }
      const imageSrc =
        typeof item.picture === "string"
          ? item.picture // Use the string URL directly
          : URL.createObjectURL(item.picture); // Handle File objects
      return imageSrc;
    }
  };
  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text).then(
      () => {
        alert("Copied to clipboard!");
      },
      () => {
        alert("Failed to copy!");
      },
    );
  };

  // Determine the source type and handle appropriately

  if (isLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <Spin size="large" />
      </Box>
    );
  }

  const initialValues: UserProductHistory = {
    userID: winning.userID ?? "",
    historyID: winning.historyID ?? "",
    productID: winning.productID ?? "",
    boxID: winning.boxID ?? "",
    unRead: winning.unRead ?? false,
    refID: winning.refID ?? "",
    postalCode: winning.postalCode ?? "",
    phoneNumber: winning.phoneNumber ?? "",
    street: winning.street ?? "",
    city: winning.city ?? "",
    state: winning.state ?? "",
    country: winning.country ?? "",
    transactionAmount: winning.transactionAmount ?? "",
    dispatchStatus: winning.dispatchStatus ?? "",
    dispatchDate: winning.dispatchDate ?? "",
    deliveryDate: winning.deliveryDate ?? "",
    trackingNumber: winning.trackingNumber ?? "",
    courierService: winning.courierService ?? "",
    userRating: winning.userRating ?? 0,
    userReview: winning.userReview ?? "",
    adminNotes: winning.adminNotes ?? "",
    resolutionNotes: winning.resolutionNotes ?? "",
    warrantyInformation: winning.warrantyInformation ?? "",
    createdAt: winning.createdAt ?? "",
  };

  const isStatusUnchangeable = unchangedOptions.includes(
    winning.dispatchStatus,
  );

  return (
    <Formik
      innerRef={formikRef}
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validateOnBlur={true}
      validateOnChange={true}
      enableReinitialize={true}
    >
      {({ errors, touched, values, handleChange, setFieldValue }) => (
        <Form>
          <Box>
            <Grid item xs={12}>
              <Typography
                variant="h4"
                gutterBottom
                component="div"
                sx={{ fontWeight: "bold" }}
              >
                Winning Details
              </Typography>
            </Grid>
            <Grid
              item
              mb={2}
              xs={12}
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Box>
                <IconButton
                  sx={{ marginRight: 2 }}
                  onClick={() => copyToClipboard(winning?.historyID || "")}
                >
                  <FileCopy />
                </IconButton>
                <Typography
                  variant="body1"
                  color="primary"
                  sx={{
                    fontWeight: 600,
                    fontSize: 17,
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    maxWidth: {
                      xs: 140,
                      md: 500,
                    },
                  }}
                >
                  {`History ID: ${winning?.historyID}`}
                </Typography>
              </Box>
              <Box>
                <IconButton
                  sx={{ marginRight: 2 }}
                  onClick={() => copyToClipboard(winning?.refID || "")}
                >
                  <FileCopy />
                </IconButton>
                <Typography
                  variant="body1"
                  color="primary"
                  sx={{
                    fontWeight: 600,
                    fontSize: 17,
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    maxWidth: {
                      xs: 140,
                      md: 500,
                    },
                  }}
                >
                  {`Ref ID: ${winning?.refID}`}
                </Typography>
              </Box>
            </Grid>
            <Box display="flex" justifyContent="space-between" mb={3}>
              <Button
                onClick={() => navigate(-1)} // Use history.goBack() to navigate back
                variant="contained"
                color="primary"
              >
                Go Back
              </Button>
              {getRoleData(user?.roleID)?.roleName === "Super Admin" && (
                <Box>
                  {editMode ? (
                    <>
                      <Button
                        sx={{ marginRight: 2 }}
                        type="submit"
                        variant="contained"
                        color="primary"
                      >
                        Save Changes
                      </Button>
                      <Button
                        type="button"
                        variant="contained"
                        color="secondary"
                        onClick={exitEditMode}
                      >
                        Cancel
                      </Button>
                    </>
                  ) : (
                    <Button
                      type="button"
                      variant="contained"
                      onClick={enterEditMode}
                    >
                      Edit
                    </Button>
                  )}
                </Box>
              )}
            </Box>
            <Grid container spacing={2}>
              {/* Profile Image and Edit */}
              <Grid item xs={12} sm={6}>
                <Paper
                  elevation={3}
                  sx={{
                    p: 2,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Box mb={2}>
                    <Avatar
                      src={getProductImageUrl(winning.productID)}
                      sx={{ width: 200, height: 200, borderRadius: "10%" }}
                    />
                  </Box>
                  {winning.boxID && (
                    <Box display="flex" justifyContent="flex-end">
                      <Avatar
                        src={getBoxImageUrl(winning.boxID)}
                        sx={{ width: 80, height: 80, borderRadius: "10%" }}
                      />
                    </Box>
                  )}
                </Paper>
              </Grid>
              <Grid item xs={12} md={6}>
                <Paper elevation={3} sx={{ p: 2 }}>
                  <Typography
                    variant="subtitle1"
                    color="primary"
                    sx={{ fontWeight: "bold", mb: 2 }}
                  >
                    Product Information
                  </Typography>

                  <TextField
                    fullWidth
                    label="Product Name"
                    name="name"
                    margin="normal"
                    value={getProductDetails(values.productID).name}
                    inputProps={{ readOnly: true }}
                  />
                  <TextField
                    fullWidth
                    label="Box Name"
                    name="name"
                    margin="normal"
                    value={getBoxDetails(values.boxID).name}
                    inputProps={{ readOnly: true }}
                  />
                  <TextField
                    fullWidth
                    label="Price"
                    name="price"
                    margin="normal"
                    inputProps={{ readOnly: true }}
                    value={getProductDetails(values.productID).price}
                  />

                  <TextField
                    fullWidth
                    label="Spin Price"
                    name="price"
                    margin="normal"
                    inputProps={{ readOnly: true }}
                    value={getBoxDetails(values.boxID).priceOffer}
                  />
                </Paper>
              </Grid>
              <Grid item xs={12} md={6}>
                <Paper elevation={3} sx={{ p: 2 }}>
                  <Typography
                    variant="subtitle1"
                    color="primary"
                    sx={{ fontWeight: "bold", mb: 2 }}
                  >
                    Updatable Information
                  </Typography>
                  <Grid item>
                    <TextField
                      fullWidth
                      label="Action Type"
                      name="action"
                      margin="normal"
                      select
                      SelectProps={{
                        native: true,
                        disabled: !editMode,
                      }}
                      value={actionMode}
                      onChange={(event) => {
                        const value = event.target.value; // Convert back to boolean
                        setActionMode(value); // Manually update the field value
                      }}
                    >
                      {actionOption.map((status, index) => {
                        return (
                          <option key={index} value={status}>
                            {status}
                          </option>
                        );
                      })}
                    </TextField>
                  </Grid>
                  <Grid item>
                    <TextField
                      fullWidth
                      label="Status"
                      name="dispatchStatus"
                      margin="normal"
                      select
                      SelectProps={{
                        native: true,
                        disabled: !editMode,
                      }}
                      disabled={isStatusUnchangeable}
                      value={values.dispatchStatus}
                      onChange={(event) => {
                        const value = event.target.value; // Convert back to boolean
                        setFieldValue("dispatchStatus", value); // Manually update the field value
                      }}
                    >
                      {deliveryOptions.map((status, index) => {
                        return (
                          <option key={index} value={status}>
                            {status}
                          </option>
                        );
                      })}
                    </TextField>
                  </Grid>
                  <Grid item>
                    <TextField
                      fullWidth
                      label="Tracking Number"
                      name="trackingNumber"
                      margin="normal"
                      value={values.trackingNumber}
                      inputProps={{ readOnly: editMode ? false : true }}
                      error={touched.trackingNumber && !!errors.trackingNumber}
                      helperText={
                        touched.trackingNumber &&
                        typeof errors.trackingNumber === "string"
                          ? errors.trackingNumber
                          : ""
                      }
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item>
                    <TextField
                      fullWidth
                      label="Transaction Amount"
                      name="transactionAmount"
                      margin="normal"
                      value={values.transactionAmount}
                      inputProps={{ readOnly: editMode ? false : true }}
                      error={
                        touched.transactionAmount && !!errors.transactionAmount
                      }
                      helperText={
                        touched.transactionAmount &&
                        typeof errors.transactionAmount === "string"
                          ? errors.transactionAmount
                          : ""
                      }
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item>
                    <TextField
                      fullWidth
                      label="Courier Service"
                      name="courierService"
                      margin="normal"
                      value={values.courierService}
                      inputProps={{ readOnly: editMode ? false : true }}
                      error={touched.courierService && !!errors.courierService}
                      helperText={
                        touched.courierService &&
                        typeof errors.courierService === "string"
                          ? errors.courierService
                          : ""
                      }
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item>
                    <TextField
                      fullWidth
                      label="Admin Notes"
                      name="adminNotes"
                      value={values.adminNotes}
                      onChange={handleChange}
                      multiline
                      rows={4}
                      margin="normal"
                      inputProps={{ readOnly: editMode ? false : true }}
                      error={touched.adminNotes && !!errors.adminNotes}
                      helperText={
                        touched.adminNotes &&
                        typeof errors.adminNotes === "string"
                          ? errors.adminNotes
                          : ""
                      }
                    />
                  </Grid>
                  <Grid item>
                    <TextField
                      fullWidth
                      label="Resolution Notes"
                      name="resolutionNotes"
                      value={values.resolutionNotes}
                      onChange={handleChange}
                      multiline
                      rows={4}
                      margin="normal"
                      inputProps={{ readOnly: editMode ? false : true }}
                      error={
                        touched.resolutionNotes && !!errors.resolutionNotes
                      }
                      helperText={
                        touched.resolutionNotes &&
                        typeof errors.resolutionNotes === "string"
                          ? errors.resolutionNotes
                          : ""
                      }
                    />
                  </Grid>
                </Paper>
              </Grid>
              <Grid item xs={12} md={6}>
                <Paper elevation={3} sx={{ p: 2 }}>
                  <Typography
                    variant="subtitle1"
                    color="primary"
                    sx={{ fontWeight: "bold", mb: 2 }}
                  >
                    Other Information
                  </Typography>

                  <Grid item>
                    <TextField
                      fullWidth
                      label="Transaction Amount"
                      name="transactionAmount"
                      margin="normal"
                      value={values.transactionAmount}
                      inputProps={{ readOnly: editMode ? false : true }}
                      error={
                        touched.transactionAmount && !!errors.transactionAmount
                      }
                      helperText={
                        touched.transactionAmount &&
                        typeof errors.transactionAmount === "string"
                          ? errors.transactionAmount
                          : ""
                      }
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item>
                    <TextField
                      fullWidth
                      label="Phone Number"
                      name="phoneNumber"
                      margin="normal"
                      value={values.phoneNumber}
                      inputProps={{ readOnly: editMode ? false : true }}
                      error={touched.phoneNumber && !!errors.phoneNumber}
                      helperText={
                        touched.phoneNumber &&
                        typeof errors.phoneNumber === "string"
                          ? errors.phoneNumber
                          : ""
                      }
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item>
                    <TextField
                      fullWidth
                      label="Street"
                      name="street"
                      margin="normal"
                      value={values.street}
                      inputProps={{ readOnly: editMode ? false : true }}
                      error={touched.street && !!errors.street}
                      helperText={
                        touched.street && typeof errors.street === "string"
                          ? errors.street
                          : ""
                      }
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item>
                    <TextField
                      fullWidth
                      label="City"
                      name="city"
                      margin="normal"
                      value={values.city}
                      inputProps={{ readOnly: editMode ? false : true }}
                      error={touched.city && !!errors.city}
                      helperText={
                        touched.city && typeof errors.city === "string"
                          ? errors.city
                          : ""
                      }
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item>
                    <TextField
                      fullWidth
                      label="State"
                      name="state"
                      margin="normal"
                      value={values.state}
                      inputProps={{ readOnly: editMode ? false : true }}
                      error={touched.state && !!errors.state}
                      helperText={
                        touched.state && typeof errors.state === "string"
                          ? errors.state
                          : ""
                      }
                      onChange={handleChange}
                    />
                    <Grid item mb={3} mt={2}>
                      <DatePicker
                        placeholder="Select DispatchDate"
                        value={
                          winning.dispatchDate
                            ? dayjs(winning.dispatchDate)
                            : null
                        }
                        onChange={(value: Dayjs | null) => {
                          setFieldValue("dispatchDate", value);
                        }}
                        format="D MMMM, YYYY"
                        style={{ width: "100%", height: 56 }}
                      />
                    </Grid>
                    <Grid item mb={3}>
                      <DatePicker
                        placeholder="Select DeliveryDate"
                        value={
                          winning.deliveryDate
                            ? dayjs(winning.deliveryDate)
                            : null
                        }
                        onChange={(value: Dayjs | null) => {
                          setFieldValue("deliveryDate", value);
                        }}
                        format="D MMMM, YYYY"
                        style={{ width: "100%", height: 56 }}
                      />
                    </Grid>

                    <Grid item mb={3}>
                      <DatePicker
                        placeholder="Date Created"
                        value={
                          winning.createdAt ? dayjs(winning.createdAt) : null
                        }
                        onChange={(value: Dayjs | null) => {
                          setFieldValue("createdAt", value);
                        }}
                        format="D MMMM, YYYY"
                        style={{ width: "100%", height: 56 }}
                      />
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export default EditWinningForm;
