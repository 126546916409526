import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { ErrorResponse, PayloadError } from "../../@types/common";
import logging from "../../logging";
import gameService from "../../services/game-service";
import { GameRecord, PlayerStats, SettingsData } from "../../@types/game-model";

export const getGameSettings = createAsyncThunk<
  SettingsData,
  void,
  { rejectValue: PayloadError }
>("game/getGameSettings", async (_, thunkAPI) => {
  try {
    return await gameService.getGameSettings();
  } catch (error) {
    const axiosError = error as AxiosError;
    const data = axiosError.response?.data as ErrorResponse;
    const serverErrorMessage = data?.error ?? "Something bad went wrong";

    logging.error("Failed to fetch game settings:", serverErrorMessage);
    return thunkAPI.rejectWithValue({ message: serverErrorMessage });
  }
});

export const getAllUsersSpins = createAsyncThunk<
  PlayerStats[],
  void,
  { rejectValue: PayloadError }
>("game/getAllUsersSpins", async (_, thunkAPI) => {
  try {
    return await gameService.getAllUserSpinStats();
  } catch (error) {
    const axiosError = error as AxiosError;
    const data = axiosError.response?.data as ErrorResponse;
    const serverErrorMessage = data?.error ?? "Something bad went wrong";

    logging.error("Failed to fetch all users stats:", serverErrorMessage);
    return thunkAPI.rejectWithValue({ message: serverErrorMessage });
  }
});

export const createGameSettings = createAsyncThunk<
  any,
  SettingsData,
  { rejectValue: PayloadError }
>("game/createGameSettings", async (payload: SettingsData, thunkAPI) => {
  try {
    return await gameService.createGameSettings(payload);
  } catch (error) {
    const axiosError = error as AxiosError;
    const data = axiosError.response?.data as ErrorResponse;
    const serverErrorMessage = data?.error ?? "Something bad went wrong";

    logging.error("Failed to create game settings:", serverErrorMessage);
    return thunkAPI.rejectWithValue({ message: serverErrorMessage });
  }
});

export const updateGameSettings = createAsyncThunk<
  any,
  SettingsData,
  { rejectValue: PayloadError }
>("game/updateGameSettings", async (payload: SettingsData, thunkAPI) => {
  try {
    return await gameService.updateGameSettings(payload);
  } catch (error) {
    const axiosError = error as AxiosError;
    const data = axiosError.response?.data as ErrorResponse;
    const serverErrorMessage = data?.error ?? "Something bad went wrong";

    logging.error("Failed to update game settings:", serverErrorMessage);
    return thunkAPI.rejectWithValue({ message: serverErrorMessage });
  }
});

export const getGameRecords = createAsyncThunk<
  GameRecord,
  void,
  { rejectValue: PayloadError }
>("game/getGameRecords", async (_, thunkAPI) => {
  try {
    return await gameService.getGameRecord();
  } catch (error) {
    const axiosError = error as AxiosError;
    const data = axiosError.response?.data as ErrorResponse;
    const serverErrorMessage = data?.error ?? "Something bad went wrong";

    logging.error("Failed to fetch game settings:", serverErrorMessage);
    return thunkAPI.rejectWithValue({ message: serverErrorMessage });
  }
});
