import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";

interface DecodedToken {
  email: string;
  staffID: string;
  roleID: string;
  exp: number;
}

export function getUserIdFromToken(): string | null {
  const token = Cookies.get("authToken");
  if (!token) return null;

  const decodedToken = jwtDecode(token) as DecodedToken;
  return decodedToken.staffID;
}
