import React, { useEffect, useRef, useState } from "react";
import {
  Grid,
  Paper,
  Typography,
  Avatar,
  TextField,
  Box,
  Button,
} from "@mui/material";

import { useNavigate, useParams } from "react-router-dom";
import { Formik, Form, FormikProps } from "formik";
import { useDispatch, useSelector } from "react-redux";

import {
  productsLoadingState,
  productsSelectorList,
} from "../../../../selectors/productSelector";
import { Product } from "../../../../@types/products-model";
import { Modal, Spin, Upload } from "antd";
import { RcFile, UploadFile } from "antd/es/upload/interface";
import { PlusOutlined } from "@ant-design/icons";
import * as Yup from "yup";
import logging from "../../../../logging";
import { AppDispatch } from "../../../../store";
import {
  getProducts,
  updateProducts,
} from "../../../../store/thunks/product-thunk";

const getBase64 = (file: RcFile): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Product Name is required"),
  price: Yup.string().required("Product Price is required"),
  brand: Yup.string().required("Product Brand is required"),
  picture: Yup.mixed().required("Product Picture is required"),
  description: Yup.string().required("Description is required"),
});

const EditProductForm = () => {
  const { id } = useParams<{ id: string }>();
  const productsData: Product[] | null = useSelector(productsSelectorList);
  const navigate = useNavigate();
  const isLoading: boolean = useSelector(productsLoadingState);
  const dispatch: AppDispatch = useDispatch();

  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [fileList, setFileList] = useState<UploadFile[]>([]);

  const [product, setProduct] = useState<Product | null>(null);
  const [editMode, setEditMode] = useState(false);

  const formikRef = useRef<FormikProps<any>>(null);

  useEffect(() => {
    // Simulating fetching user data by ID
    if (id && productsData) {
      // Set initial selected bank
      const selectedProduct = productsData.find(
        (product) => product.productID === id,
      );

      setProduct(selectedProduct || null);

      if (typeof selectedProduct?.picture === "string") {
        setFileList([
          {
            uid: "-1",
            name: "profile.jpg",
            status: "done",
            url:
              selectedProduct.picture ||
              "https://as2.ftcdn.net/v2/jpg/03/83/25/83/1000_F_383258331_D8imaEMl8Q3lf7EKU2Pi78Cn0R7KkW9o.jpg",
            thumbUrl:
              selectedProduct.picture ||
              "https://as2.ftcdn.net/v2/jpg/03/83/25/83/1000_F_383258331_D8imaEMl8Q3lf7EKU2Pi78Cn0R7KkW9o.jpg",
          },
        ]);
      }
    }
  }, [id]);

  const enterEditMode = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    console.log("Entering edit mode");
    setEditMode(true);
  };

  const exitEditMode = (event?: React.MouseEvent<HTMLButtonElement>) => {
    event?.preventDefault(); // Optional chaining in case event is undefined
    console.log("Exiting edit mode");
    if (formikRef.current) {
      formikRef.current.resetForm();
    }
    setEditMode(false);
  };

  if (!product) {
    return <Typography>Loading user data...</Typography>;
  }

  const initialValues: Product = {
    productID: product?.productID,
    name: product?.name,
    description: product?.description,
    price: product.price || 0,
    picture: product?.picture || null,
    brand: product.brand,
    createdAt: product.createdAt,
  };

  const handleCancel = () => setPreviewOpen(false);

  const handlePreview = async (file: UploadFile) => {
    console.log("preview", file);

    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as RcFile);
    }

    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
  };

  const handlePictureChange = (info: { fileList: any }) => {
    let fileList = info.fileList;
    fileList = fileList.slice(-1);
    setFileList(fileList);
  };
  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  const handleSubmit = async (values: Product, actions: any) => {
    try {
      const formData = createFormData(values);

      // Assuming you have an updateProducts thunk function.
      const resultAction = await dispatch(updateProducts(formData));
      if (updateProducts.fulfilled.match(resultAction)) {
        await dispatch(getProducts());
        logging.info("Product updated successful!");
      } else if (
        resultAction.payload &&
        (typeof resultAction.payload === "string" ||
          "error" in resultAction.payload)
      ) {
        logging.error(resultAction.payload);
      } else {
        logging.error(resultAction.error);
      }
    } catch (error) {
      logging.error("Dispatch failed:", (error as Error).message);
    } finally {
      actions.setSubmitting(false);
      exitEditMode();
    }
  };

  const createFormData = (values: Product) => {
    const formData = new FormData();

    const productData: Partial<Product> = {
      productID: product.productID,
      name: values.name,
      description: values.description,
      price: parseFloat(values.price.toString()),
      brand: values.brand,
    };

    formData.append("product_data", JSON.stringify(productData));

    if (values.picture) {
      formData.append("product_picture", values.picture);
    }

    return formData;
  };

  if (isLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <Spin size="large" />
      </Box>
    );
  }
  return (
    <Formik
      innerRef={formikRef}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      validateOnBlur={true}
      validateOnChange={true}
      enableReinitialize={true}
    >
      {({ errors, touched, values, handleChange, setFieldValue }) => (
        <Form>
          <Box>
            <Grid item xs={12}>
              <Typography
                variant="h4"
                gutterBottom
                component="div"
                sx={{ fontWeight: "bold" }}
              >
                Package Details
              </Typography>
            </Grid>
            <Box display="flex" justifyContent="space-between" mb={3}>
              <Button
                onClick={() => navigate(-1)} // Use history.goBack() to navigate back
                variant="contained"
                color="primary"
              >
                Go Back
              </Button>
              {editMode ? (
                <>
                  <Button
                    sx={{ marginRight: 2 }}
                    type="submit"
                    variant="contained"
                    color="primary"
                  >
                    Save Changes
                  </Button>
                  <Button
                    type="button"
                    variant="contained"
                    color="secondary"
                    onClick={exitEditMode}
                  >
                    Cancel
                  </Button>
                </>
              ) : (
                <Button
                  type="button"
                  variant="contained"
                  onClick={enterEditMode}
                >
                  Edit
                </Button>
              )}
            </Box>
            <Grid container spacing={2}>
              {/* Profile Image and Edit */}
              <Grid item xs={12} sm={6}>
                <Paper
                  elevation={3}
                  sx={{
                    p: 2,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  {editMode ? (
                    <Upload
                      action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                      listType="picture-card"
                      fileList={fileList}
                      onPreview={handlePreview}
                      onChange={handlePictureChange}
                      beforeUpload={() => false} // Prevent auto-uploading
                    >
                      {fileList.length >= 1 ? null : uploadButton}
                    </Upload>
                  ) : (
                    <Avatar
                      src={
                        typeof product.picture === "string"
                          ? product.picture
                          : ""
                      }
                      sx={{ width: 200, height: 200, borderRadius: "10%" }}
                    />
                  )}
                  <Modal
                    open={previewOpen}
                    footer={null}
                    onCancel={handleCancel}
                  >
                    <img
                      alt="example"
                      style={{ width: "100%" }}
                      src={previewImage}
                    />
                  </Modal>
                </Paper>
              </Grid>
              <Grid item xs={12} md={6}>
                <Paper elevation={3} sx={{ p: 2 }}>
                  <Typography
                    variant="subtitle1"
                    color="primary"
                    sx={{ fontWeight: "bold", mb: 2 }}
                  >
                    Product Information
                  </Typography>

                  <TextField
                    fullWidth
                    label="Product Name"
                    name="name"
                    margin="normal"
                    value={values.name}
                    inputProps={{ readOnly: editMode ? false : true }}
                    error={touched.name && !!errors.name}
                    helperText={
                      touched.name && typeof errors.name === "string"
                        ? errors.name
                        : ""
                    }
                    onChange={handleChange}
                  />
                  <TextField
                    fullWidth
                    label="Price"
                    name="price"
                    margin="normal"
                    inputProps={{ readOnly: editMode ? false : true }}
                    value={values.price}
                    onChange={handleChange}
                    error={touched.price && !!errors.price}
                    helperText={
                      touched.price && typeof errors.price === "string"
                        ? errors.price
                        : ""
                    }
                  />

                  <TextField
                    fullWidth
                    label="Brand"
                    name="brand"
                    margin="normal"
                    inputProps={{ readOnly: editMode ? false : true }}
                    value={values.brand}
                    onChange={handleChange}
                    error={touched.brand && !!errors.brand}
                    helperText={
                      touched.brand && typeof errors.brand === "string"
                        ? errors.brand
                        : ""
                    }
                  />
                  <TextField
                    fullWidth
                    label="Description"
                    name="description"
                    value={values.description}
                    onChange={handleChange}
                    multiline
                    rows={4}
                    margin="normal"
                    inputProps={{ readOnly: editMode ? false : true }}
                    error={touched.description && !!errors.description}
                    helperText={
                      touched.description &&
                      typeof errors.description === "string"
                        ? errors.description
                        : ""
                    }
                  />
                </Paper>
              </Grid>
            </Grid>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export default EditProductForm;
