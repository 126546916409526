import Cookies from "js-cookie";

export const isTokenThere = (): boolean => {
  return Boolean(Cookies.get("authToken"));
};

export const isTokenExpired = (): boolean => {
  const token = Cookies.get("authToken");
  if (!token) return true;

  try {
    const payload = token.split(".")[1];
    const decodedPayload = JSON.parse(atob(payload));
    const expiry = decodedPayload.exp;

    const currentTimestamp = Math.floor(Date.now() / 1000);
    return currentTimestamp >= expiry;
  } catch (error) {
    console.error("Failed to decode or parse the token:", error);
    return true;
  }
};

export const isTokenValid = (): boolean => {
  return isTokenThere() && !isTokenExpired();
};
