// src/components/PublicRoute.tsx
import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "./store";

const PublicRoute: React.FC = () => {
  const isAuthenticated = useSelector(
    (state: RootState) => state.auth.isLoggedIn,
  );

  if (isAuthenticated) {
    // If the user is authenticated, redirect to the dashboard
    return <Navigate to="/dashboard" replace />;
  }

  // If the user is not authenticated, render the public route (Outlet)
  return <Outlet />;
};

export default PublicRoute;
