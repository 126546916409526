// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Define your global styles including gradients */
body, #root { /* Ensure the gradient covers the entire React root */
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh; /* Full viewport height */
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.ant-layout {
  min-height: 100vh !important;
}

.ant-layout-sider{
  flex: 0 0 250px !important;
    max-width: 250px !important;
    min-width: 250px !important;
    width: 250px !important;
}

.ant-layout-sider-collapsed{
  flex: 0 0 80px !important;
    max-width: 80px !important;
    min-width: 80px !important;
    width: 80px !important;
}
.ant-menu-title-content{
  font-size: 16px;
}

main{
  overflow-y: scroll;
}

.ant-upload-list-item-container, .ant-upload{
  height: 200px !important;
  width: 200px !important;
}`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA,kDAAkD;AAClD,cAAc,qDAAqD;EACjE,SAAS;EACT,UAAU;EACV;;cAEY;EACZ,mCAAmC;EACnC,kCAAkC;EAClC,aAAa,EAAE,yBAAyB;AAC1C;AACA;EACE,SAAS;EACT,UAAU;EACV,sBAAsB;AACxB;;AAEA;EACE;aACW;AACb;;;AAGA;EACE,4BAA4B;AAC9B;;AAEA;EACE,0BAA0B;IACxB,2BAA2B;IAC3B,2BAA2B;IAC3B,uBAAuB;AAC3B;;AAEA;EACE,yBAAyB;IACvB,0BAA0B;IAC1B,0BAA0B;IAC1B,sBAAsB;AAC1B;AACA;EACE,eAAe;AACjB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,wBAAwB;EACxB,uBAAuB;AACzB","sourcesContent":["/* Define your global styles including gradients */\nbody, #root { /* Ensure the gradient covers the entire React root */\n  margin: 0;\n  padding: 0;\n  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',\n    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',\n    sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n  height: 100vh; /* Full viewport height */\n}\n* {\n  margin: 0;\n  padding: 0;\n  box-sizing: border-box;\n}\n\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',\n    monospace;\n}\n\n\n.ant-layout {\n  min-height: 100vh !important;\n}\n\n.ant-layout-sider{\n  flex: 0 0 250px !important;\n    max-width: 250px !important;\n    min-width: 250px !important;\n    width: 250px !important;\n}\n\n.ant-layout-sider-collapsed{\n  flex: 0 0 80px !important;\n    max-width: 80px !important;\n    min-width: 80px !important;\n    width: 80px !important;\n}\n.ant-menu-title-content{\n  font-size: 16px;\n}\n\nmain{\n  overflow-y: scroll;\n}\n\n.ant-upload-list-item-container, .ant-upload{\n  height: 200px !important;\n  width: 200px !important;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
