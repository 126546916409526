import React, { FC, useEffect, useRef, useState } from "react";
import {
  Grid,
  Paper,
  Typography,
  Avatar,
  Rating,
  TextField,
  Box,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Button,
} from "@mui/material";

import { useNavigate, useParams } from "react-router-dom";
import { Formik, Form, FormikProps } from "formik";
import { useDispatch, useSelector } from "react-redux";

import {
  boxesSelectorList,
  boxLoadingState,
  productsSelectorList,
} from "../../../../selectors/productSelector";
import {
  BoxModel,
  Product,
  ProductType,
} from "../../../../@types/products-model";
import { Modal, Spin, Upload } from "antd";
import { RcFile, UploadFile } from "antd/es/upload/interface";
import { PlusOutlined } from "@ant-design/icons";
import ReusableTable from "../../../../components/reusable-table";
import { CustomColumn } from "../../../../@types/table";
import { AppDispatch } from "../../../../store";
import logging from "../../../../logging";
import * as Yup from "yup";
import {
  getBoxPackages,
  updateBoxPackage,
} from "../../../../store/thunks/box-thunk";

const getBase64 = (file: RcFile): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Product Name is required"),
  picture: Yup.mixed().required("Product Picture is required"),
  description: Yup.string().required("Description is required"),
  rtp: Yup.number()
    .required("RTP is required")
    .positive("RTP must be a positive number")
    .typeError("RTP must be a number"),
});

const EditBoxForm = () => {
  const { id } = useParams<{ id: string }>();
  const packageDetails: BoxModel[] | null = useSelector(boxesSelectorList);
  const productsData: Product[] | null = useSelector(productsSelectorList);

  const isLoading: boolean = useSelector(boxLoadingState);

  const dispatch: AppDispatch = useDispatch();

  const navigate = useNavigate();

  //   const isLoading = useSelector(boxLoadingState);

  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [fileList, setFileList] = useState<UploadFile[]>([]);

  const [box, setPackage] = useState<BoxModel | null>(null);
  const [editMode, setEditMode] = useState(false);

  const formikRef = useRef<FormikProps<any>>(null);

  useEffect(() => {
    // Simulating fetching user data by ID
    if (id && packageDetails) {
      // Set initial selected bank
      const selectedBox = packageDetails.find((box) => box.boxID === id);

      setPackage(selectedBox || null);

      if (typeof selectedBox?.picture === "string") {
        setFileList([
          {
            uid: "-1",
            name: "profile.jpg",
            status: "done",
            url:
              selectedBox.picture ||
              "https://as2.ftcdn.net/v2/jpg/03/83/25/83/1000_F_383258331_D8imaEMl8Q3lf7EKU2Pi78Cn0R7KkW9o.jpg",
            thumbUrl:
              selectedBox.picture ||
              "https://as2.ftcdn.net/v2/jpg/03/83/25/83/1000_F_383258331_D8imaEMl8Q3lf7EKU2Pi78Cn0R7KkW9o.jpg",
          },
        ]);
      }
    }
  }, [id]);

  const enterEditMode = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    console.log("Entering edit mode");
    setEditMode(true);
  };

  const exitEditMode = (event?: React.MouseEvent<HTMLButtonElement>) => {
    event?.preventDefault(); // Optional chaining in case event is undefined
    console.log("Exiting edit mode");
    if (formikRef.current) {
      formikRef.current.resetForm();
    }
    setEditMode(false);
  };

  if (!box) {
    return <Typography>Loading user data...</Typography>;
  }

  const initialValues: BoxModel = {
    boxID: box?.boxID,
    name: box?.name,
    description: box?.description,
    priceOffer: box.priceOffer || 0,
    rtp: box.rtp || 0,
    status: box?.status,
    picture: box?.picture || null,
    products: box?.products || [],
    recommended: box?.recommended || false,
    category: box?.category,
    createdAt: box?.createdAt,
    level: box.level || 0,
    isActive: box?.isActive ?? false,
  };

  const handleCancel = () => setPreviewOpen(false);

  const handlePreview = async (file: UploadFile) => {
    console.log("preview", file);

    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as RcFile);
    }

    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
  };

  const handlePictureChange = (info: { fileList: any }) => {
    let fileList = info.fileList;
    fileList = fileList.slice(-1);
    setFileList(fileList);
  };
  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  const columns: CustomColumn<ProductType>[] = [
    {
      id: "productID",
      label: "Image",
      format: (value) => {
        return renderImageOrName(value);
      },
    },
    {
      id: "productID",
      label: "Product Name",
      format: (value) => {
        return renderImageOrName(value, false);
      },
    },
    { id: "probability", label: "Probability" },
    { id: "price", label: "Price" },
  ];

  const renderImageOrName = (productID: string, isImage: boolean = true) => {
    const selectedProduct = productsData.find(
      (product) => product.productID === productID,
    );

    if (isImage) {
      return selectedProduct?.picture ? (
        <Avatar
          src={
            typeof selectedProduct.picture === "string"
              ? selectedProduct.picture
              : URL.createObjectURL(selectedProduct.picture)
          }
          alt="Product Image"
        />
      ) : (
        <Avatar /> // Display a default avatar if no picture is available
      );
    }

    return selectedProduct?.name;
  };

  const handleSubmit = async (values: BoxModel, actions: any) => {
    try {
      const formData = createFormData(values);

      const resultAction = await dispatch(updateBoxPackage(formData));
      if (updateBoxPackage.fulfilled.match(resultAction)) {
        await dispatch(getBoxPackages());
        logging.info("Package updated successful!");
        // Move this line here
      } else if (
        resultAction.payload &&
        (typeof resultAction.payload === "string" ||
          "error" in resultAction.payload)
      ) {
        logging.error(resultAction.payload);
      } else {
        logging.error(resultAction.error);
      }
    } catch (error) {
      logging.error("Dispatch failed:", (error as Error).message);
    } finally {
      actions.setSubmitting(false);
      exitEditMode();
    }
  };

  const createFormData = (values: BoxModel) => {
    const formData = new FormData();
    let clonedProducts = JSON.parse(JSON.stringify(values.products));

    clonedProducts.forEach((product: ProductType, index: number) => {
      clonedProducts[index].probability = parseFloat(
        product.probability.toString(),
      );
    });

    const boxData: Partial<BoxModel> = {
      boxID: box.boxID,
      name: values.name,
      status: values.status,
      description: values.description,
      priceOffer: parseFloat(values.priceOffer.toString()),
      rtp: parseFloat(values.rtp.toString()),
      recommended: values.recommended,
      category: values.category,
      products: clonedProducts,
      level: parseFloat(values.level.toString()),
    };

    formData.append("box_data", JSON.stringify(boxData));

    if (values.picture) {
      formData.append("box_picture", values.picture);
    }

    return formData;
  };

  if (isLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <Spin size="large" />
      </Box>
    );
  }
  return (
    <Formik
      innerRef={formikRef}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values, actions) => {
        handleSubmit(values, actions);
      }}
      validateOnBlur={true}
      validateOnChange={true}
      enableReinitialize={true}
    >
      {({ errors, touched, values, handleChange, setFieldValue }) => (
        <Form>
          <Box>
            <Grid item xs={12}>
              <Typography
                variant="h4"
                gutterBottom
                component="div"
                sx={{ fontWeight: "bold" }}
              >
                Package Details
              </Typography>
            </Grid>
            <Box display="flex" justifyContent="space-between" mb={3}>
              <Button
                onClick={() => navigate(-1)} // Use history.goBack() to navigate back
                variant="contained"
                color="primary"
              >
                Go Back
              </Button>
              {editMode ? (
                <>
                  <Button
                    sx={{ marginRight: 2 }}
                    type="submit"
                    variant="contained"
                    color="primary"
                  >
                    Save Changes
                  </Button>
                  <Button
                    type="button"
                    variant="contained"
                    color="secondary"
                    onClick={exitEditMode}
                  >
                    Cancel
                  </Button>
                </>
              ) : (
                <Button
                  type="button"
                  variant="contained"
                  onClick={enterEditMode}
                >
                  Edit
                </Button>
              )}
            </Box>
            <Grid container spacing={2}>
              {/* Profile Image and Edit */}
              <Grid item xs={12} sm={6}>
                <Paper
                  elevation={3}
                  sx={{
                    p: 2,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  {editMode ? (
                    <Upload
                      action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                      listType="picture-card"
                      fileList={fileList}
                      onPreview={handlePreview}
                      onChange={handlePictureChange}
                      beforeUpload={() => false} // Prevent auto-uploading
                    >
                      {fileList.length >= 1 ? null : uploadButton}
                    </Upload>
                  ) : (
                    <Avatar
                      src={typeof box.picture === "string" ? box.picture : ""}
                      sx={{ width: 200, height: 200, borderRadius: "10%" }}
                    />
                  )}
                  <Modal
                    open={previewOpen}
                    footer={null}
                    onCancel={handleCancel}
                  >
                    <img
                      alt="example"
                      style={{ width: "100%" }}
                      src={previewImage}
                    />
                  </Modal>
                </Paper>
              </Grid>
              <Grid item xs={12} md={6}>
                <Paper elevation={3} sx={{ p: 2 }}>
                  <Typography
                    variant="subtitle1"
                    color="primary"
                    sx={{ fontWeight: "bold", mb: 2 }}
                  >
                    Box Main Information
                  </Typography>

                  <TextField
                    fullWidth
                    label="Package Name"
                    name="name"
                    margin="normal"
                    value={values.name}
                    inputProps={{ readOnly: editMode ? false : true }}
                    error={touched.name && !!errors.name}
                    helperText={
                      touched.name && typeof errors.name === "string"
                        ? errors.name
                        : ""
                    }
                    onChange={handleChange}
                  />
                  <TextField
                    fullWidth
                    label="Description"
                    name="description"
                    value={values.description}
                    onChange={handleChange}
                    multiline
                    rows={4}
                    margin="normal"
                    inputProps={{ readOnly: editMode ? false : true }}
                    error={touched.description && !!errors.description}
                    helperText={
                      touched.description &&
                      typeof errors.description === "string"
                        ? errors.description
                        : ""
                    }
                  />

                  <FormControl fullWidth margin="normal">
                    <InputLabel>Status</InputLabel>
                    <Select
                      name="status"
                      value={values.status}
                      label="Status"
                      onChange={handleChange}
                      inputProps={{ readOnly: editMode ? false : true }}
                    >
                      <MenuItem value="new">New</MenuItem>
                      <MenuItem value="hot">Hot</MenuItem>
                      <MenuItem value="updated">Updated</MenuItem>
                    </Select>
                  </FormControl>
                  <FormControl fullWidth margin="normal">
                    <InputLabel>Category</InputLabel>
                    <Select
                      name="category"
                      value={values.category}
                      label="Category"
                      onChange={handleChange}
                      inputProps={{ readOnly: editMode ? false : true }}
                    >
                      {/* Map your categories here */}
                      <MenuItem value="Featured">Featured</MenuItem>
                      <MenuItem value="Electronic">Electronic</MenuItem>
                    </Select>
                  </FormControl>

                  <TextField
                    fullWidth
                    label="Price"
                    name="priceOffer"
                    margin="normal"
                    inputProps={{ readOnly: editMode ? false : true }}
                    value={values.priceOffer}
                    onChange={handleChange}
                    error={touched.priceOffer && !!errors.priceOffer}
                    helperText={
                      touched.priceOffer &&
                      typeof errors.priceOffer === "string"
                        ? errors.priceOffer
                        : ""
                    }
                  />
                  <TextField
                    fullWidth
                    label="RTP"
                    name="rtp"
                    margin="normal"
                    inputProps={{ readOnly: editMode ? false : true }}
                    value={values.rtp}
                    onChange={handleChange}
                    error={touched.rtp && !!errors.rtp}
                    helperText={
                      touched.rtp && typeof errors.rtp === "string"
                        ? errors.rtp
                        : ""
                    }
                  />
                  <TextField
                    fullWidth
                    label="Level"
                    name="level"
                    margin="normal"
                    inputProps={{ readOnly: editMode ? false : true }}
                    value={values.level}
                    onChange={handleChange}
                    error={touched.level && !!errors.level}
                    helperText={
                      touched.level && typeof errors.level === "string"
                        ? errors.level
                        : ""
                    }
                  />

                  <TextField
                    fullWidth
                    label="Active"
                    name="isActive"
                    margin="normal"
                    select
                    SelectProps={{
                      native: true,
                    }}
                    inputProps={{ readOnly: editMode ? false : true }}
                    value={values.isActive ? "yes" : "no"}
                    onChange={(event) => {
                      const value = event.target.value === "yes"; // Convert back to boolean
                      setFieldValue("isActive", value); // Manually update the field value
                    }}
                  >
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                  </TextField>
                </Paper>
              </Grid>
            </Grid>
            <Box
              mb={2}
              mt={2}
              sx={{
                bgcolor: "background.paper",
                p: 2,
                border: 1, // Adds border
                borderColor: "grey.300", // Border color
                borderRadius: 2, // Rounded corners
              }}
            >
              <Typography variant="h6" gutterBottom>
                Products
              </Typography>
              {editMode && (
                <Box display="flex" justifyContent="flex-end" mb={2} mt={2}>
                  <Button
                    type="button"
                    variant="contained"
                    onClick={enterEditMode}
                  >
                    Add Products
                  </Button>
                </Box>
              )}
              <ReusableTable
                data={box.products}
                columns={columns}
                containerComponent="box"
                paginationOptions={{
                  rowsPerPage: 10,
                  rowsPerPageOptions: [10, 20, 30],
                  showPagination: true,
                }}
                showEditIcon={editMode}
                showDeleteIcon={editMode}
              />
            </Box>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export default EditBoxForm;
