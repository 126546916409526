import React, { ChangeEvent, useEffect, useState } from "react";
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import {
  People,
  Dashboard as DashboardIcon,
  Category,
  Ballot,
  Notifications,
  AccountBalanceWallet,
  AccountBalance,
  Receipt,
  Settings,
  Equalizer,
  Search,
  PersonAdd,
  GroupAdd,
  GifBox,
  AddBox,
  Redeem,
  MilitaryTech,
  AssignmentReturn,
  TransferWithinAStation,
  SdCardAlert,
} from "@mui/icons-material";

import { theme as antTheme, Layout, Menu as AntMenu, MenuProps } from "antd";

import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import {
  Avatar,
  Badge,
  Box,
  Drawer,
  InputAdornment,
  Button as MUIButton,
  MenuItem,
  Menu as MuiMenu,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { currentUserInfomation } from "../../selectors/authSelectors";
import { searchQuery } from "../../selectors/seacrhSelector";
import { AppDispatch } from "../../store";
import { setSearch } from "../../store/slices/search-slice";
import { rolesSelectorList } from "../../selectors/roleSelector";

const { Header, Sider, Content } = Layout;

const AdminDashboard: React.FC = () => {
  const dispatch: AppDispatch = useDispatch();

  const [collapsed, setCollapsed] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const theme = useTheme();
  const location = useLocation();
  const [selectedKey, setSelectedKey] = useState("");
  const user = useSelector(currentUserInfomation);
  const roles = useSelector(rolesSelectorList);

  const search = useSelector(searchQuery);

  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [mobileDrawerOpen, setMobileDrawerOpen] = useState(false);

  useEffect(() => {
    setCollapsed(isMobile);
    const pathKey = location.pathname.replace("/dashboard", "") || "/";

    setSelectedKey(pathKey);
  }, [isMobile, location.pathname]);

  const open = Boolean(anchorEl);
  const {
    token: { colorBgContainer },
  } = antTheme.useToken();

  const navigate = useNavigate();

  const handleMenuClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const toggleDrawer = () => {
    setMobileDrawerOpen(!mobileDrawerOpen);
  };

  const handleAntMenuClick: MenuProps["onClick"] = (e) => {
    const path = e.key === "/" ? "" : e.key;
    navigate(`/dashboard${path}`);
    setSelectedKey(e.key);
    if (isMobile) {
      toggleDrawer();
    }
  };

  const getRoleData = (roleID: string) => {
    const role = roles.find((role) => role.roleID === roleID);
    return role;
  };

  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    dispatch(setSearch(event.target.value));
  };

  const menuItems: MenuProps["items"] = [
    { key: "/", icon: <DashboardIcon />, label: "Dashboard" },
    {
      key: "/customers",
      icon: <People />,
      label: "Customers",
      children: [
        { key: "/users", icon: <GroupAdd />, label: "User List" },
        {
          key: "/register-staff",
          icon: <PersonAdd />,
          label: "Register Staff",
        },
        { key: "/staffs", icon: <GroupAdd />, label: "Staff List" },
      ],
    },
    {
      key: "/category",
      icon: <Category />,
      label: "Category",
      children: [
        { key: "/products", icon: <Ballot />, label: "Product List" },
        { key: "/add-product", icon: <AddBox />, label: "Add Product" },
      ],
    },

    {
      key: "/boxes",
      icon: <Redeem />,
      label: "Boxes",
      children: [
        { key: "/box-list", icon: <GifBox />, label: "Box List" },
        { key: "/add-box", icon: <AddBox />, label: "Add Box" },
      ],
    },
    { key: "/wallets", icon: <AccountBalanceWallet />, label: "Wallets" },
    {
      key: "/transactions",
      icon: <AccountBalance />,
      label: "Transactions",
      children: [
        { key: "/deposit", icon: <Receipt />, label: "Deposit" },
        { key: "/buy-back", icon: <AssignmentReturn />, label: "Buy Back" },
        {
          key: "/cash-out",
          icon: <TransferWithinAStation />,
          label: "Cash OuT",
        },
      ],
    },
    { key: "/winnings", icon: <MilitaryTech />, label: "Winning" },
    { key: "/vtu-purchases", icon: <SdCardAlert />, label: "VTU Purchase" },
    { key: "/profile", icon: <Equalizer />, label: "Profile" },
  ];

  if (
    user &&
    (getRoleData(user.roleID)?.roleName === "Super Admin" ||
      getRoleData(user.roleID)?.roleName === "Admin")
  ) {
    menuItems.push({
      key: "/settings",
      icon: <Settings />,
      label: "Settings",
      children: [
        { key: "/game-settings", icon: <Receipt />, label: "Game Settings" },
      ],
    });
  }

  const drawerContent = (
    <Box sx={{ height: "100vh", bgcolor: "#001529" }}>
      <Box
        sx={{
          height: isMobile ? 31 : 62,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          bgcolor: "grey",
          p: 2,
        }}
        mb={2}
      >
        <Typography variant="h6" sx={{ fontWeight: "900", color: "white" }}>
          {collapsed ? "PT" : "KO-KO BOX ADMIN"}
        </Typography>
      </Box>
      <AntMenu
        theme="dark"
        mode="inline"
        selectedKeys={[selectedKey]}
        items={menuItems}
        onClick={handleAntMenuClick}
      />
    </Box>
  );
  return (
    <Layout>
      {isMobile ? (
        <Drawer
          anchor="left"
          open={mobileDrawerOpen}
          onClose={toggleDrawer}
          style={{ zIndex: 1300 }} // Ensure it is above AppBar
        >
          {drawerContent}
        </Drawer>
      ) : (
        <Sider
          trigger={null}
          collapsible
          collapsed={collapsed}
          style={{
            overflow: "auto",
            height: "100vh",
            position: "fixed",
            left: 0,
          }}
        >
          {drawerContent}
        </Sider>
      )}

      <Layout style={{ marginLeft: isMobile ? 0 : collapsed ? 80 : 250 }}>
        <Header style={{ padding: 0, background: colorBgContainer }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
            mr={2}
            mt={1}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                ml: isMobile ? 0.1 : 2,
              }}
            >
              <MUIButton
                startIcon={
                  collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />
                }
                onClick={() =>
                  isMobile ? toggleDrawer() : setCollapsed(!collapsed)
                }
              />
              <TextField
                variant="outlined"
                size="small"
                placeholder="Search..."
                value={search}
                onChange={handleSearchChange}
                sx={{
                  bgcolor: "lightgrey",
                  borderRadius: 1,
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "transparent",
                  },
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  ),
                }}
              />
            </Box>

            <Box>
              <Box
                onClick={handleMenuClick}
                sx={{
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Box
                  ml={1}
                  mr={isMobile ? 1 : 2}
                  sx={{ position: "relative", display: "inline-flex" }}
                >
                  <Badge
                    badgeContent={3}
                    sx={{
                      // Fine-tune badge styling
                      "& .MuiBadge-badge": {
                        height: "16px", // Slightly smaller to better match the medium icon size
                        minWidth: "16px", // Keep a consistent circle shape
                        fontSize: "0.65rem", // Adjust font size to fit the smaller badge
                        top: "2px", // Adjust vertical positioning
                        right: "4px", // Adjust horizontal positioning
                        backgroundColor: "#f48fb1",
                      },
                    }}
                  >
                    <Notifications fontSize="medium" />
                  </Badge>
                </Box>
                <Avatar
                  // src={user?.pictureUrl || ""}
                  sx={{ width: 40, height: 40, borderRadius: "10%" }}
                />

                {!isMobile && (
                  <Box sx={{ ml: 1, overflow: "hidden" }}>
                    <Typography
                      variant="subtitle1"
                      sx={{
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        maxWidth: 140,
                        textTransform: "capitalize",
                      }}
                    >
                      {user?.firstName}
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        maxWidth: 180,
                      }}
                    >
                      {user?.email}
                    </Typography>
                  </Box>
                )}
              </Box>

              <MuiMenu
                anchorEl={anchorEl}
                open={open}
                onClose={handleMenuClose}
              >
                <MenuItem component={Link} to="/profile">
                  View Profile
                </MenuItem>
                <MenuItem component={Link} to="/logout">
                  Logout
                </MenuItem>
              </MuiMenu>
            </Box>
          </Box>
        </Header>
        <Content
          style={{
            margin: "24px 16px",
            minHeight: 280,
          }}
        >
          <Outlet />
        </Content>
      </Layout>
    </Layout>
  );
};

export default AdminDashboard;
