import { createSlice, PayloadAction, SerializedError } from "@reduxjs/toolkit";

// import { isTokenValid } from '../utils/token-utils';
import { isTokenValid } from "../../utils/token-utils";
import {
  AuthError,
  AuthResponse,
  AuthState,
  StaffModel,
} from "../../@types/staff-model";
import {
  loginStaff,
  fetchAuthenticatedUser,
  logoutStaff,
} from "../thunks/auth-thunk";
import { preloadDataAfterLogin } from "../thunks/preload";

const initialState: AuthState = {
  isLoggedIn: isTokenValid(),
  token: null,
  sessionID: null,
  userInfo: null,
  error: null,
  status: "idle",
  loading: false,
  message: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout: (state) => {
      state.isLoggedIn = false;
      state.token = null;
      localStorage.removeItem("authToken");
    },

    resetError: (state) => {
      state.error = null;
    },

    setAuthenticated: (state, action: PayloadAction<boolean>) => {
      console.log("is logged in", action.payload);

      state.isLoggedIn = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginStaff.pending, (state) => {
        state.status = "loading";
        state.loading = true;
        state.message = null;
      })
      .addCase(
        loginStaff.fulfilled,
        (state, action: PayloadAction<AuthResponse>) => {
          state.status = "succeeded";
          state.isLoggedIn = true;
          state.token = action.payload.token;
          state.sessionID = action.payload.sessionID;

          // Save to local storage
          localStorage.setItem("authToken", action.payload.token);
          localStorage.setItem("sessionID", action.payload.sessionID);
          state.loading = false;
          state.message = "Logged in successfully!";
        },
      )
      .addCase(
        loginStaff.rejected,
        (
          state,
          action: PayloadAction<
            AuthError | undefined,
            string,
            any,
            SerializedError
          >,
        ) => {
          if (action.payload) {
            state.status = "failed";
            state.error = action.payload.message;
            state.loading = false;
            state.message = null;
          } else {
            state.status = "failed";
            state.error = action.error.message || null;
            state.loading = false;
            state.message = null;
          }
        },
      );

    builder
      .addCase(fetchAuthenticatedUser.pending, (state) => {
        state.status = "loading";
        state.loading = false;
        state.message = null;
      })
      .addCase(
        fetchAuthenticatedUser.fulfilled,
        (state, action: PayloadAction<StaffModel>) => {
          state.status = "succeeded";
          state.userInfo = action.payload;
          state.message = "Current user info fetched!";
        },
      )
      .addCase(fetchAuthenticatedUser.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message || null;
        state.message = null;
        state.isLoggedIn = false;
      });

    builder
      .addCase(logoutStaff.pending, (state) => {
        state.status = "loading";
        state.message = null;
      })
      .addCase(logoutStaff.fulfilled, (state) => {
        state.status = "succeeded";
        state.isLoggedIn = false;
        state.token = null;
        state.sessionID = null;
        state.message = "Logged out successfully!";
      })
      .addCase(logoutStaff.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message || null;
        state.message = null;
      });
    builder
      .addCase(preloadDataAfterLogin.pending, (state) => {
        state.loading = true;
      })
      .addCase(preloadDataAfterLogin.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(preloadDataAfterLogin.rejected, (state) => {
        state.loading = false;
      });
  },
});

export const { logout, setAuthenticated, resetError } = authSlice.actions;
export default authSlice.reducer;
