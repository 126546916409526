import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { persistor, store } from "./store";

const rootElement = document.getElementById("root");

// Create the root only once here
const root = rootElement ? ReactDOM.createRoot(rootElement) : null;

const RenderApp = (AppComponent: React.ComponentType) => {
  if (root) {
    root.render(
      <React.StrictMode>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <AppComponent />
          </PersistGate>
        </Provider>
      </React.StrictMode>,
    );
  } else {
    console.error("Could not find root element to mount to!");
  }
};

RenderApp(App);

// HMR setup
if (module.hot) {
  module.hot.accept("./App", () => {
    const NextApp = require("./App").default;
    RenderApp(NextApp);
  });
}

reportWebVitals();

if (process.env.NODE_ENV === "production" && "serviceWorker" in navigator) {
  window.addEventListener("load", () => {
    navigator.serviceWorker.register("/service-worker.s").then(
      (registration) => {
        console.log("ServiceWorker registration successful:", registration);
      },
      (error) => {
        console.error("ServiceWorker registration failed:", error);
      },
    );
  });
}
