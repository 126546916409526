import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { ErrorResponse } from "../../@types/common";
import logging from "../../logging";
import walletService from "../../services/wallet-service";
import {
  BankDetailModel,
  BankVerificationDetails,
  BankVerifyRequest,
  TransactionPaymentTableData,
  InboundResponse,
  PayoutInfo,
  UpdateStatusPayout,
  WalletInfo,
  BuyBackTable,
  TransactionAggregates,
} from "../../@types/wallet";
import { SpinPriceResponse } from "../../@types/user-model";
import { AuthError } from "../../@types/staff-model";

export const getAllPayouts = createAsyncThunk<PayoutInfo[], void>(
  "wallet/getAllPayouts",
  async (_, thunkAPI) => {
    try {
      return await walletService.fetchAllPayoutsPrices();
    } catch (error) {
      const axiosError = error as AxiosError;
      const data = axiosError.response?.data as ErrorResponse;
      const serverErrorMessage = data?.error ?? "Something bad went wrong";

      logging.error("Failed to fetch all payouts:", serverErrorMessage);
      return thunkAPI.rejectWithValue({ message: serverErrorMessage });
    }
  },
);

export const getAllWallets = createAsyncThunk<WalletInfo[], void>(
  "wallet/getAllWallets",
  async (_, thunkAPI) => {
    try {
      return await walletService.fetchAllWallets();
    } catch (error) {
      const axiosError = error as AxiosError;
      const data = axiosError.response?.data as ErrorResponse;
      const serverErrorMessage = data?.error ?? "Something bad went wrong";

      logging.error("Failed to fetch all wallets:", serverErrorMessage);
      return thunkAPI.rejectWithValue({ message: serverErrorMessage });
    }
  },
);

export const updatePayoutStatus = createAsyncThunk<any, UpdateStatusPayout>(
  "wallet/updatePayoutStatus",
  async (payload: UpdateStatusPayout, thunkAPI) => {
    try {
      return await walletService.updatePayoutStatus(payload);
    } catch (error) {
      const axiosError = error as AxiosError;
      const data = axiosError.response?.data as ErrorResponse;
      const serverErrorMessage = data?.error ?? "Something bad went wrong";

      logging.error("Failed to update payout:", serverErrorMessage);
      return thunkAPI.rejectWithValue({ message: serverErrorMessage });
    }
  },
);
export const getSumOfAllInboundTransactions = createAsyncThunk<
  InboundResponse,
  void
>("wallet/getSumOfAllInboundTransactions", async (_, thunkAPI) => {
  try {
    return await walletService.getAllSumInboundTransactionsCommissions();
  } catch (error) {
    const axiosError = error as AxiosError;
    const data = axiosError.response?.data as ErrorResponse;
    const serverErrorMessage = data?.error ?? "Something bad went wrong";

    logging.error("Failed to fetch all sum of inbounds:", serverErrorMessage);
    return thunkAPI.rejectWithValue({ message: serverErrorMessage });
  }
});

export const getAllTransactions = createAsyncThunk<
  TransactionPaymentTableData[],
  void
>("wallet/getAllTransactions", async (_, thunkAPI) => {
  try {
    return await walletService.getAllTransactions();
  } catch (error) {
    const axiosError = error as AxiosError;
    const data = axiosError.response?.data as ErrorResponse;
    const serverErrorMessage = data?.error ?? "Something bad went wrong";

    logging.error("Failed to fetch all transactions:", serverErrorMessage);
    return thunkAPI.rejectWithValue({ message: serverErrorMessage });
  }
});

export const getAllTrasactionAmounts = createAsyncThunk<
  TransactionAggregates,
  void
>("wallet/getAllTrasactionAmounts", async (_, thunkAPI) => {
  try {
    return await walletService.getAllTransactionAmounts();
  } catch (error) {
    const axiosError = error as AxiosError;
    const data = axiosError.response?.data as ErrorResponse;
    const serverErrorMessage = data?.error ?? "Something bad went wrong";

    logging.error("Failed to fetch all transactions:", serverErrorMessage);
    return thunkAPI.rejectWithValue({ message: serverErrorMessage });
  }
});

export const getAllBuyBackTransactions = createAsyncThunk<BuyBackTable[], void>(
  "wallet/getAllBuyBackTransactions",
  async (_, thunkAPI) => {
    try {
      return await walletService.getAllBuyBackTransactions();
    } catch (error) {
      const axiosError = error as AxiosError;
      const data = axiosError.response?.data as ErrorResponse;
      const serverErrorMessage = data?.error ?? "Something bad went wrong";

      logging.error(
        "Failed to fetch all buy back transactions:",
        serverErrorMessage,
      );
      return thunkAPI.rejectWithValue({ message: serverErrorMessage });
    }
  },
);
export const fetchAllAgentUsersSpinPrice = createAsyncThunk<
  SpinPriceResponse,
  string[]
>("users/fetchAllAgentUsersSpinPrice", async (userIds: string[], thunkAPI) => {
  try {
    const response = await walletService.fetchUsersSpinPrices(userIds);
    return response;
  } catch (error) {
    const axiosError = error as AxiosError;
    const data = axiosError.response?.data as ErrorResponse;
    const serverErrorMessage = data?.error ?? "Something bad went wrong";

    logging.error("Failed to fetch all users list:", serverErrorMessage);
    return thunkAPI.rejectWithValue({ message: serverErrorMessage });
  }
});

export const getAllBanksDetails = createAsyncThunk<
  BankDetailModel[],
  void,
  { rejectValue: AuthError }
>("auth/verifyBankAccount", async (_, thunkAPI) => {
  try {
    return await walletService.getAllBanksDetails();
  } catch (error) {
    const axiosError = error as AxiosError;
    const data = axiosError.response?.data as ErrorResponse;
    const serverErrorMessage = data?.error ?? "Something bad went wrong";

    logging.error("Error fetching all bank datas:", serverErrorMessage);

    // Reject the thunk action with the server error message
    return thunkAPI.rejectWithValue({ message: serverErrorMessage });
  }
});

export const updateUserAccount = createAsyncThunk<
  any,
  WalletInfo,
  { rejectValue: AuthError }
>("auth/updateUserAccount", async (payload: WalletInfo, thunkAPI) => {
  try {
    return await walletService.updateUserWallet(payload);
  } catch (error) {
    const axiosError = error as AxiosError;
    const data = axiosError.response?.data as ErrorResponse;
    const serverErrorMessage = data?.error ?? "Something bad went wrong";

    logging.error("Error updating users wallet:", serverErrorMessage);

    // Reject the thunk action with the server error message
    return thunkAPI.rejectWithValue({ message: serverErrorMessage });
  }
});

export const verifyBankAccount = createAsyncThunk<
  BankVerificationDetails,
  BankVerifyRequest,
  { rejectValue: AuthError }
>("auth/verifyBankAccount", async (data: BankVerifyRequest, thunkAPI) => {
  try {
    return await walletService.verifyBankDetails(data);
  } catch (error) {
    const axiosError = error as AxiosError;
    const data = axiosError.response?.data as ErrorResponse;
    const serverErrorMessage = data?.error ?? "Something bad went wrong";

    logging.error("Error verify bank details:", serverErrorMessage);

    // Reject the thunk action with the server error message
    return thunkAPI.rejectWithValue({ message: serverErrorMessage });
  }
});
