import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { WalletInfo } from "../../../../@types/wallet";
import { useDispatch, useSelector } from "react-redux";
import {
  allWalletsData,
  walletStateLoading,
} from "../../../../selectors/walletSelector";
import { useEffect, useRef, useState } from "react";
import {
  Grid,
  Paper,
  Typography,
  Box,
  IconButton,
  Button,
  TextField,
} from "@mui/material";

import { Formik, Form, FormikProps } from "formik";
import { FileCopy } from "@mui/icons-material";
import { formatCurrency } from "../../../../utils";
import { Spin } from "antd";
import {
  getAllWallets,
  updateUserAccount,
} from "../../../../store/thunks/wallet-thunk";
import logging from "../../../../logging";
import { AppDispatch } from "../../../../store";
import { currentUserInfomation } from "../../../../selectors/authSelectors";
import { rolesSelectorList } from "../../../../selectors/roleSelector";

const EditWalletForm = () => {
  const { id } = useParams<{ id: string }>();
  const allWallets: WalletInfo[] | null = useSelector(allWalletsData);
  const dispatch: AppDispatch = useDispatch();

  const [wallet, setWallet] = useState<WalletInfo | null>(null);
  const [editMode, setEditMode] = useState(false);
  const navigate = useNavigate();
  const roles = useSelector(rolesSelectorList);
  const user = useSelector(currentUserInfomation);
  const isLoading: boolean = useSelector(walletStateLoading);

  const formikRef = useRef<FormikProps<any>>(null);

  useEffect(() => {
    // Simulating fetching user data by ID
    if (id && allWallets) {
      // Set initial selected bank
      const selectedWallet = allWallets.find(
        (wallet) => wallet.walletID === id,
      );

      setWallet(selectedWallet || null);
    }
  }, [id, allWallets]);

  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text).then(
      () => {
        alert("Copied to clipboard!");
      },
      () => {
        alert("Failed to copy!");
      },
    );
  };

  const exitEditMode = (event?: React.MouseEvent<HTMLButtonElement>) => {
    event?.preventDefault(); // Optional chaining in case event is undefined
    console.log("Exiting edit mode");
    if (formikRef.current) {
      formikRef.current.resetForm();
    }
    setEditMode(false);
  };

  const enterEditMode = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    console.log("Entering edit mode");
    setEditMode(true);
  };

  const handleSubmit = async (values: WalletInfo, actions: any) => {
    try {
      const processedValues = {
        ...values,
        mainAccount: parseFloat(values.mainAccount.toString()),
        buyBackAccount: parseFloat(values.buyBackAccount.toString()),
        bonusAccount: parseFloat(values.bonusAccount.toString()),
      };

      const resultAction = await dispatch(updateUserAccount(processedValues));
      if (updateUserAccount.fulfilled.match(resultAction)) {
        await dispatch(getAllWallets());
        logging.info("Wallet updated successful!");
      } else if (
        resultAction.payload &&
        (typeof resultAction.payload === "string" ||
          "error" in resultAction.payload)
      ) {
        logging.error(resultAction.payload);
      } else {
        logging.error(resultAction.error);
      }
    } catch (error) {
      logging.error("Dispatch failed:", (error as Error).message);
    } finally {
      actions.setSubmitting(false);
      exitEditMode();
    }
  };

  if (isLoading || !user) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <Spin size="large" />
      </Box>
    );
  }

  const initialValues: WalletInfo = {
    userID: wallet?.userID ?? "",
    walletID: wallet?.walletID ?? "",
    mainAccount: wallet?.mainAccount ?? 0,
    bonusAccount: wallet?.bonusAccount ?? 0,
    buyBackAccount: wallet?.buyBackAccount ?? 0,
    isFirstDeposit: wallet?.isFirstDeposit ?? false,
  };
  const getRoleData = (roleID: string) => {
    const role = roles.find((role) => role.roleID === roleID);
    return role;
  };

  return (
    <Formik
      innerRef={formikRef}
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validateOnBlur={true}
      validateOnChange={true}
      enableReinitialize={true}
    >
      {({ errors, touched, values, handleChange, setFieldValue }) => (
        <Form>
          <Grid item xs={12}>
            <Typography
              variant="h4"
              gutterBottom
              component="div"
              sx={{ fontWeight: "bold" }}
            >
              Wallet Details
            </Typography>
          </Grid>
          <Box display="flex" justifyContent="space-between" mb={3}>
            <Button
              onClick={() => navigate(-1)} // Use history.goBack() to navigate back
              variant="contained"
              color="primary"
            >
              Go Back
            </Button>
            {getRoleData(user.roleID)?.roleName === "Super Admin" && (
              <Box>
                {editMode ? (
                  <>
                    <Button
                      sx={{ marginRight: 2 }}
                      type="submit"
                      variant="contained"
                      color="primary"
                    >
                      Save Changes
                    </Button>
                    <Button
                      type="button"
                      variant="contained"
                      color="secondary"
                      onClick={exitEditMode}
                    >
                      Cancel
                    </Button>
                  </>
                ) : (
                  <Button
                    type="button"
                    variant="contained"
                    onClick={enterEditMode}
                  >
                    Edit
                  </Button>
                )}
              </Box>
            )}
          </Box>
          <Box>
            <Grid container spacing={2}>
              <Paper elevation={2} sx={{ p: 4, width: "100%" }}>
                <Grid item xs={12}>
                  <Typography
                    variant="subtitle1"
                    color="primary"
                    sx={{ fontWeight: "bold", mb: 2 }}
                  >
                    Wallet Infomation
                  </Typography>
                  <Grid
                    container
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{ mb: 1 }}
                  >
                    <Grid item>
                      <Typography
                        variant="body1"
                        color="textSecondary"
                        sx={{ fontWeight: 600 }}
                      >
                        Wallet ID:
                      </Typography>
                    </Grid>
                    <Grid item display="flex" alignItems="center">
                      <IconButton
                        sx={{ marginRight: 2 }}
                        onClick={() => copyToClipboard(wallet?.walletID || "")}
                      >
                        <FileCopy />
                      </IconButton>
                      <Typography
                        variant="body1"
                        color="primary"
                        sx={{
                          fontWeight: 600,
                          fontSize: 17,
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          maxWidth: {
                            xs: 140,
                            md: 500,
                          },
                        }}
                      >
                        {wallet?.walletID}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{ mb: 1 }}
                  >
                    <Grid item>
                      <Typography
                        variant="body1"
                        color="textSecondary"
                        sx={{ fontWeight: 600 }}
                      >
                        First Deposit:
                      </Typography>
                    </Grid>
                    <Grid item>
                      {editMode ? (
                        <TextField
                          fullWidth
                          label="First Deposit"
                          name="isFirstDeposit"
                          margin="normal"
                          select
                          SelectProps={{
                            native: true,
                          }}
                          value={values.isFirstDeposit ? "yes" : "no"}
                          onChange={(event) => {
                            const value = event.target.value === "yes"; // Convert back to boolean
                            setFieldValue("isFirstDeposit", value); // Manually update the field value
                          }}
                        >
                          <option value="yes">Yes</option>
                          <option value="no">No</option>
                        </TextField>
                      ) : (
                        <Typography
                          variant="body1"
                          color="primary"
                          sx={{ fontWeight: 600, fontSize: 17 }}
                        >
                          {wallet?.isFirstDeposit ? "Yes" : "No"}
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{ mb: 1 }}
                  >
                    <Grid item>
                      <Typography
                        variant="body1"
                        color="textSecondary"
                        sx={{ fontWeight: 600 }}
                      >
                        Main Account:
                      </Typography>
                    </Grid>
                    <Grid item>
                      {editMode ? (
                        <TextField
                          fullWidth
                          label="Main Account"
                          name="mainAccount"
                          margin="normal"
                          value={values.mainAccount}
                          inputProps={{ readOnly: editMode ? false : true }}
                          error={touched.mainAccount && !!errors.mainAccount}
                          helperText={
                            touched.mainAccount &&
                            typeof errors.mainAccount === "string"
                              ? errors.mainAccount
                              : ""
                          }
                          onChange={handleChange}
                        />
                      ) : (
                        <Typography
                          variant="body1"
                          color="primary"
                          sx={{ fontWeight: 900, fontSize: 22 }}
                        >
                          {wallet
                            ? formatCurrency(wallet?.mainAccount, true)
                            : ""}
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{ mb: 1 }}
                  >
                    <Grid item>
                      <Typography
                        variant="body1"
                        color="textSecondary"
                        sx={{ fontWeight: 600 }}
                      >
                        Buy Back Account:
                      </Typography>
                    </Grid>
                    <Grid item>
                      {editMode ? (
                        <TextField
                          fullWidth
                          label="Buy Back Account"
                          name="buyBackAccount"
                          margin="normal"
                          value={values.buyBackAccount}
                          inputProps={{ readOnly: editMode ? false : true }}
                          error={
                            touched.buyBackAccount && !!errors.buyBackAccount
                          }
                          helperText={
                            touched.buyBackAccount &&
                            typeof errors.buyBackAccount === "string"
                              ? errors.buyBackAccount
                              : ""
                          }
                          onChange={handleChange}
                        />
                      ) : (
                        <Typography
                          variant="body1"
                          color="primary"
                          sx={{ fontWeight: 900, fontSize: 22 }}
                        >
                          {wallet
                            ? formatCurrency(wallet?.buyBackAccount, true)
                            : ""}
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{ mb: 1 }}
                  >
                    <Grid item>
                      <Typography
                        variant="body1"
                        color="textSecondary"
                        sx={{ fontWeight: 600 }}
                      >
                        Bonus Account:
                      </Typography>
                    </Grid>
                    <Grid item>
                      {editMode ? (
                        <TextField
                          fullWidth
                          label="Bonus Account"
                          name="bonusAccount"
                          margin="normal"
                          value={values.bonusAccount}
                          inputProps={{ readOnly: editMode ? false : true }}
                          error={touched.bonusAccount && !!errors.bonusAccount}
                          helperText={
                            touched.bonusAccount &&
                            typeof errors.bonusAccount === "string"
                              ? errors.bonusAccount
                              : ""
                          }
                          onChange={handleChange}
                        />
                      ) : (
                        <Typography
                          variant="body1"
                          color="primary"
                          sx={{ fontWeight: 900, fontSize: 22 }}
                        >
                          {wallet
                            ? formatCurrency(wallet?.bonusAccount, true)
                            : ""}
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Box>
        </Form>
      )}
    </Formik>
  );
};
export default EditWalletForm;
