import { AxiosResponse } from "axios";
import ApiService from "./api-service";
import {
  MessageType,
  SendMessageContent,
  UserModel,
} from "../@types/user-model";

class UserService extends ApiService {
  async fetchAllAgentUsers(agentID: string): Promise<any> {
    try {
      const response: AxiosResponse = await this.axiosInstance.get(
        `/users/getAllAgentUsers/${agentID}`,
      );
      return response.data;
    } catch (error) {
      console.error("Failed to fetch users:", error);
      throw error;
    }
  }
  async fetchUsers(): Promise<any> {
    try {
      const response: AxiosResponse =
        await this.nonCachingAxiosInstance.get("/users/getAll");
      return response.data;
    } catch (error) {
      console.error("Failed to fetch users:", error);
      throw error;
    }
  }
  async fetchAgents(): Promise<any> {
    try {
      const response: AxiosResponse =
        await this.axiosInstance.get("/agents/getAll");
      return response.data;
    } catch (error) {
      console.error("Failed to fetch agents:", error);
      throw error;
    }
  }
  async fetchAllMessages(): Promise<any> {
    try {
      const response: AxiosResponse = await this.axiosInstance.get(
        "/messages/getAllMessages",
      );
      return response.data;
    } catch (error) {
      console.error("Failed to fetch agents:", error);
      throw error;
    }
  }

  async UpdateMessage(payload: MessageType): Promise<any> {
    try {
      const response: AxiosResponse = await this.axiosInstance.post(
        "/messages/updateMessage",
        payload,
        {
          headers: { "Content-Type": this.getContentType(payload) },
        },
      );
      await this.invalidateCacheByKey("get/messages/getAllMessages");
      return response.data;
    } catch (error) {
      console.error("Failed to fetch agents:", error);
      throw error;
    }
  }

  async SendUsersMessages(payload: SendMessageContent): Promise<any> {
    try {
      const response: AxiosResponse = await this.nonCachingAxiosInstance.post(
        "/users/sendUserMessages",
        payload,
      );
      return response.data;
    } catch (error) {
      console.error("Failed to fetch agents:", error);
      throw error;
    }
  }

  async CreateMessage(payload: MessageType): Promise<any> {
    try {
      const response: AxiosResponse = await this.axiosInstance.post(
        "/messages/createMessage",
        payload,
        {
          headers: { "Content-Type": this.getContentType(payload) },
        },
      );
      await this.invalidateCacheByKey("get/messages/getAllMessages");
      return response.data;
    } catch (error) {
      console.error("Failed to fetch agents:", error);
      throw error;
    }
  }

  async updateCustomerProfiles(payload: UserModel): Promise<any> {
    try {
      const response: AxiosResponse = await this.nonCachingAxiosInstance.post(
        `/users/update/${payload.userId}`,
        payload,
      );
      await this.invalidateCacheByKey("get/users/getAll");

      return response.data;
    } catch (error) {
      console.error("Failed to update users:", error);
      throw error;
    }
  }
}

const userService = new UserService();
export default userService;
