import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { SpinPriceResponse } from "../../@types/user-model";
import {
  WalletState,
  PayoutInfo,
  WalletInfo,
  InboundResponse,
  BankDetailModel,
  TransactionPaymentTableData,
  BuyBackTable,
  TransactionAggregates,
} from "../../@types/wallet";
import {
  getAllPayouts,
  updatePayoutStatus,
  getAllWallets,
  fetchAllAgentUsersSpinPrice,
  getAllBanksDetails,
  updateUserAccount,
  getAllTransactions,
  getAllBuyBackTransactions,
  getAllTrasactionAmounts,
} from "../thunks/wallet-thunk";

const initialState: WalletState = {
  allPayout: [],
  allUserWallets: [],
  userSpinsInfo: null,
  allTransactions: [],
  allBanksDetails: [],
  allBuyBackTransactions: [],
  allTransactionsAmount: null,
  isBankAccountVerified: false,
  status: "idle",
  sumOfInboundTransactions: null,
  error: null,
  loading: false,
  message: null,
};

const walletSlice = createSlice({
  name: "wallet",
  initialState,
  reducers: {
    setBankVerified: (state, action: PayloadAction<boolean>) => {
      state.isBankAccountVerified = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllPayouts.pending, (state) => {
        state.status = "loading";
        state.message = null;
        state.error = null;
        state.loading = true;
      })
      .addCase(
        getAllPayouts.fulfilled,
        (state, action: PayloadAction<PayoutInfo[]>) => {
          state.status = "succeeded";
          state.allPayout = action.payload;
          state.error = null;
          state.message = null;
          state.loading = false;
        },
      )
      .addCase(getAllPayouts.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message || null;
        state.message = null;
        state.loading = false;
      });
    builder
      .addCase(updatePayoutStatus.pending, (state) => {
        state.status = "loading";
        state.message = null;
        state.error = null;
        state.loading = true;
      })
      .addCase(updatePayoutStatus.fulfilled, (state) => {
        state.status = "succeeded";
        state.error = null;
        state.message = null;
        state.loading = false;
      })
      .addCase(updatePayoutStatus.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message || null;
        state.message = null;
        state.loading = false;
      });
    builder
      .addCase(getAllWallets.pending, (state) => {
        state.status = "loading";
        state.message = null;
        state.error = null;
        state.loading = true;
      })
      .addCase(
        getAllWallets.fulfilled,
        (state, action: PayloadAction<WalletInfo[]>) => {
          state.status = "succeeded";
          state.allUserWallets = action.payload;
          state.error = null;
          state.message = null;
          state.loading = false;
        },
      )
      .addCase(getAllWallets.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message || null;
        state.message = null;
        state.loading = false;
      });
    builder
      .addCase(getAllTransactions.pending, (state) => {
        state.status = "loading";
        state.message = null;
        state.error = null;
        state.loading = true;
      })
      .addCase(
        getAllTransactions.fulfilled,
        (state, action: PayloadAction<TransactionPaymentTableData[]>) => {
          state.status = "succeeded";
          state.allTransactions = action.payload;
          state.error = null;
          state.message = null;
          state.loading = false;
        },
      )
      .addCase(getAllTransactions.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message || null;
        state.message = null;
        state.loading = false;
      });
    builder
      .addCase(getAllTrasactionAmounts.pending, (state) => {
        state.status = "loading";
        state.message = null;
        state.error = null;
        state.loading = true;
      })
      .addCase(
        getAllTrasactionAmounts.fulfilled,
        (state, action: PayloadAction<TransactionAggregates>) => {
          state.status = "succeeded";
          state.allTransactionsAmount = action.payload;
          state.error = null;
          state.message = null;
          state.loading = false;
        },
      )
      .addCase(getAllTrasactionAmounts.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message || null;
        state.message = null;
        state.loading = false;
      });

    builder
      .addCase(getAllBuyBackTransactions.pending, (state) => {
        state.status = "loading";
        state.message = null;
        state.error = null;
        state.loading = true;
      })
      .addCase(
        getAllBuyBackTransactions.fulfilled,
        (state, action: PayloadAction<BuyBackTable[]>) => {
          state.status = "succeeded";
          state.allBuyBackTransactions = action.payload;
          state.error = null;
          state.message = null;
          state.loading = false;
        },
      )
      .addCase(getAllBuyBackTransactions.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message || null;
        state.message = null;
        state.loading = false;
      });
    builder
      .addCase(fetchAllAgentUsersSpinPrice.pending, (state) => {
        state.status = "loading";
        state.loading = true;
        state.message = null;
      })
      .addCase(
        fetchAllAgentUsersSpinPrice.fulfilled,
        (state, action: PayloadAction<SpinPriceResponse>) => {
          state.status = "succeeded";
          state.userSpinsInfo = action.payload;
          state.loading = false;
          state.message = null;
        },
      )
      .addCase(fetchAllAgentUsersSpinPrice.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message || null;
        state.message = null;
        state.loading = false;
      });

    builder
      .addCase(getAllBanksDetails.pending, (state) => {
        state.error = null;
        state.loading = true;
      })
      .addCase(
        getAllBanksDetails.fulfilled,
        (state, action: PayloadAction<BankDetailModel[]>) => {
          state.error = null;
          state.allBanksDetails = action.payload;
          state.loading = false;
        },
      )
      .addCase(getAllBanksDetails.rejected, (state, action) => {
        if (action.payload) {
          state.error = action.payload.message;
          state.loading = false;
        } else {
          state.error = action.error.message || null;
          state.loading = false;
        }
      });
    builder
      .addCase(updateUserAccount.pending, (state) => {
        state.status = "loading";
        state.loading = true;
        state.message = null;
      })
      .addCase(updateUserAccount.fulfilled, (state) => {
        state.status = "succeeded";
        state.loading = false;
        state.message = null;
      })
      .addCase(updateUserAccount.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message || null;
        state.message = null;
        state.loading = false;
      });
  },
});

export const { setBankVerified } = walletSlice.actions;
export default walletSlice.reducer;
