import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { ErrorResponse, PayloadError } from "../../@types/common";
import logging from "../../logging";
import { BoxModel, Boxes } from "../../@types/products-model";
import boxService from "../../services/box-service";

export const getBoxPackages = createAsyncThunk<
  Boxes,
  void,
  { rejectValue: PayloadError }
>("box/getBoxPackages", async (_, thunkAPI) => {
  try {
    return await boxService.fetchBoxPackages();
  } catch (error) {
    const axiosError = error as AxiosError;
    const data = axiosError.response?.data as ErrorResponse;
    const serverErrorMessage = data?.error ?? "Something bad went wrong";

    logging.error("Failed to fetch all products:", serverErrorMessage);
    return thunkAPI.rejectWithValue({ message: serverErrorMessage });
  }
});

export const createBoxPackage = createAsyncThunk<
  any,
  FormData,
  { rejectValue: PayloadError }
>("box/createBoxPackage", async (data: FormData, thunkAPI) => {
  try {
    return await boxService.createBoxPackages(data);
  } catch (error) {
    const axiosError = error as AxiosError;
    const data = axiosError.response?.data as ErrorResponse;
    const serverErrorMessage = data?.error ?? "Something bad went wrong";

    logging.error("Failed to fetch all box pacakges:", serverErrorMessage);
    return thunkAPI.rejectWithValue({ message: serverErrorMessage });
  }
});

export const updateBoxPackage = createAsyncThunk<
  any,
  FormData,
  { rejectValue: PayloadError }
>("box/updateBoxPackage", async (data: FormData, thunkAPI) => {
  try {
    return await boxService.updateBoxPackages(data);
  } catch (error) {
    const axiosError = error as AxiosError;
    const data = axiosError.response?.data as ErrorResponse;
    const serverErrorMessage = data?.error ?? "Something bad went wrong";

    logging.error("Failed to update box package:", serverErrorMessage);
    return thunkAPI.rejectWithValue({ message: serverErrorMessage });
  }
});

export const deleteBoxPackage = createAsyncThunk<
  any,
  string,
  { rejectValue: PayloadError }
>("box/deleteBoxPackage", async (boxID: string, thunkAPI) => {
  try {
    return await boxService.deleteBoxPackages(boxID);
  } catch (error) {
    const axiosError = error as AxiosError;
    const data = axiosError.response?.data as ErrorResponse;
    const serverErrorMessage = data?.error ?? "Something bad went wrong";

    logging.error("Failed to delete box package:", serverErrorMessage);
    return thunkAPI.rejectWithValue({ message: serverErrorMessage });
  }
});
