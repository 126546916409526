import React, { useEffect, useMemo, useState } from "react";
import { Box, Grid, Typography, useTheme } from "@mui/material";

import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  usersSelectorList,
  usersSelectorLoadingState,
} from "../../../../selectors/userSelector";
import { UserModel } from "../../../../@types/user-model";
import ReusableTable from "../../../../components/reusable-table";
import dayjs from "dayjs";
import { CustomColumn } from "../../../../@types/table";
import { searchQuery } from "../../../../selectors/seacrhSelector";
import { resetSearch } from "../../../../store/slices/search-slice";
import { AppDispatch } from "../../../../store";
import { Spin } from "antd";

// Define columns for the user table
const columns: CustomColumn<UserModel>[] = [
  { id: "email", label: "Email" },
  { id: "firstName", label: "First Name" },
  { id: "lastName", label: "Last Name" },
  { id: "phoneNumber", label: "Phone Number" },
  {
    id: "createdAt",
    label: "Created At",
    format: (value: string) => dayjs(value).format("YYYY-MM"), // Format date
  },
];

const UsersList = () => {
  const dispatch: AppDispatch = useDispatch();

  const usersData: UserModel[] | null = useSelector(usersSelectorList);
  const isLoading = useSelector(usersSelectorLoadingState);
  const search = useSelector(searchQuery);

  const theme = useTheme();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(resetSearch());
  }, [dispatch]);

  const handleUserClick = (user: UserModel) => {
    navigate(`/dashboard/users/${user.userId}`);
  };

  const filteredUsersData = useMemo(() => {
    if (!search) return usersData;

    return usersData?.filter(
      (user) =>
        user.firstName.toLowerCase().includes(search.toLowerCase()) ||
        user.lastName.toLowerCase().includes(search.toLowerCase()) ||
        user.email.toLowerCase().includes(search.toLowerCase()) ||
        user.phoneNumber.toLowerCase().includes(search.toLowerCase()),
    );
  }, [usersData, search]);

  const sortedUsersData = React.useMemo(() => {
    // Create a copy of usersData before sorting
    return [...(filteredUsersData || [])].sort(
      (a, b) => dayjs(b.createdAt).unix() - dayjs(a.createdAt).unix(),
    );
  }, [filteredUsersData]);
  if (isLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <Spin size="large" />
      </Box>
    );
  }

  return (
    <Box padding={theme.spacing(3)}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography
            variant="h4"
            gutterBottom
            component="div"
            sx={{ fontWeight: "bold" }}
          >
            Users
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <ReusableTable
            data={sortedUsersData || []}
            columns={columns}
            paginationOptions={{
              rowsPerPage: 20,
              rowsPerPageOptions: [10, 20, 30],
              showPagination: true,
            }}
            enableRowClick={true}
            onRowClick={(item) => handleUserClick(item)}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default UsersList;
