export const getStatusColor = (status: string) => {
  switch (status) {
    case "pending":
      return "#d9534f"; // Bootstrap's warning color
    case "processing":
      return "#ea8730"; // Bootstrap's info color
    case "delivered":
    case "delivered":
    case "buy-back":
    case "cash-exchange":
      return "green"; // Bootstrap's success color
    case "in-transit":
      return "#12b4e1"; // Bootstrap's primary color
    default:
      return "#6c7d78"; // Bootstrap's secondary color
  }
};

export function formatCurrency(
  amount: number,
  addCurrency = false,
  currencyCode = "NGN",
) {
  // Round the amount to the nearest whole number
  const roundedAmount = Math.round(amount);

  const formattedAmount = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: currencyCode,
  }).format(roundedAmount);

  // Remove the currency symbol (e.g., "₦") and trim any extra whitespace
  const cleanedAmount = formattedAmount.replace(/[^\d.,]/g, "").trim();

  // Remove the decimal portion (i.e., ".00")
  const amountWithoutDecimal = cleanedAmount.replace(/\.00$/, "");

  if (addCurrency) {
    return `₦ ${amountWithoutDecimal}`;
  } else {
    return amountWithoutDecimal;
  }
}
