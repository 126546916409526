import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import Home from "./pages/home";
import AdminRoute from "./admin-route";
import Dashboard from "./pages/admin/components/dashboard";
import AdminDashboard from "./pages/admin";
import { selectAuthenticationState } from "./selectors/authSelectors";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "./store";
import { preloadDataAfterLogin } from "./store/thunks/preload";
import Users from "./pages/admin/components/users";
import Staffs from "./pages/admin/components/staff";
import UserEditForm from "./pages/admin/components/edit-users";
import Wallets from "./pages/admin/components/wallets";
import AddStaff from "./pages/admin/components/register-staff";
import BoxList from "./pages/admin/components/boxes";
import ProductList from "./pages/admin/components/products";
import EditBoxForm from "./pages/admin/components/edit-box";
import AddBox from "./pages/admin/components/add-box";
import PublicRoute from "./public-routes";
import EditProductForm from "./pages/admin/components/edit-product";
import AddProduct from "./pages/admin/components/add-product";
import EditWalletForm from "./pages/admin/components/edit-wallet";
import StaffEditForm from "./pages/admin/components/edit-staff";
import DepositList from "./pages/admin/components/transactions/deposit";
import BuyBackList from "./pages/admin/components/transactions/buy-back";
import CashOutList from "./pages/admin/components/transactions/cash-out";
import Profile from "./pages/admin/components/profile";
import GameSettings from "./pages/admin/components/game-settings";
import Winnnings from "./pages/admin/components/winnings";
import EditWinningForm from "./pages/admin/components/edit-winnings";
import VTUPurchaseList from "./pages/admin/components/vtu-purchases";

const App: React.FC = () => {
  const dispatch: AppDispatch = useDispatch();
  const isAuthenticated = useSelector(selectAuthenticationState);

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(preloadDataAfterLogin());
    }
  }, [dispatch, isAuthenticated]);
  return (
    <Router>
      <Routes>
        <Route element={<PublicRoute />}>
          <Route path="/" element={<Home />} />
        </Route>
        <Route element={<AdminRoute />}>
          <Route path="/dashboard" element={<AdminDashboard />}>
            <Route index element={<Dashboard />} />
            <Route path="users" element={<Users />} />
            <Route path="staffs" element={<Staffs />} />
            <Route path="staffs/:id" element={<StaffEditForm />} />
            <Route path="box-list" element={<BoxList />} />
            <Route path="add-box" element={<AddBox />} />
            <Route path="add-product" element={<AddProduct />} />
            <Route path="products" element={<ProductList />} />
            <Route path="users/:id" element={<UserEditForm />} />
            <Route path="box-list/:id" element={<EditBoxForm />} />
            <Route path="products/:id" element={<EditProductForm />} />
            <Route path="wallets" element={<Wallets />} />
            <Route path="wallets/:id" element={<EditWalletForm />} />
            <Route path="register-staff" element={<AddStaff />} />
            <Route path="deposit" element={<DepositList />} />
            <Route path="buy-back" element={<BuyBackList />} />
            <Route path="cash-out" element={<CashOutList />} />
            <Route path="profile" element={<Profile />} />
            <Route path="game-settings" element={<GameSettings />} />
            <Route path="winnings" element={<Winnnings />} />
            <Route path="winnings/:id" element={<EditWinningForm />} />
            <Route path="vtu-purchases" element={<VTUPurchaseList />} />
          </Route>
        </Route>

        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </Router>
  );
};

export default App;
