// src/slices/usersSlice.ts
import { createSlice } from "@reduxjs/toolkit";
import { RoleState, RoleModel } from "../../@types/roles-model";
import { getRoles } from "../thunks/roles-thunks";

const initialState: RoleState = {
  list: [] as RoleModel[],
  status: "idle" as "idle" | "loading" | "succeeded" | "failed",
  error: null as string | null,
};

const rolesSlice = createSlice({
  name: "roles",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getRoles.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getRoles.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.list = action.payload;
      })
      .addCase(getRoles.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message || null;
      });
  },
});

export default rolesSlice.reducer;
