import React, { useMemo, useState } from "react";
import {
  Box,
  Button,
  Grid,
  Paper,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import dayjs from "dayjs";

import { Spin } from "antd";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { TrendingDown, TrendingUp } from "@mui/icons-material";
import HeaderActions from "../../../../components/header-action";
// import ReusableTable from '../../../../components/reusable-table';
import { Column as AntsColumnPlot } from "@ant-design/plots";
import { useSelector } from "react-redux";
import { GameRecord, MonthlyRecordsData } from "../../../../@types/game-model";
import {
  gameLoadingState,
  gameRecordsData,
} from "../../../../selectors/gameSelector";
import GameRecordModal from "../../../../components/record-modal";
import { UserModel } from "../../../../@types/user-model";
import { usersSelectorList } from "../../../../selectors/userSelector";
import { getAllAmounts } from "../../../../selectors/walletSelector";
import { TransactionAggregates } from "../../../../@types/wallet";
import vtuPurchases from "../vtu-purchases";
import { AirtimeResponse, VtuPurchaseAggregates } from "../../../../@types/products-model";
import { vtuTotalPurchases } from "../../../../selectors/productSelector";

dayjs.extend(customParseFormat);

interface InfoCardProps {
  value: string;
  change: string;
  decrease?: boolean;
  info: string;
  date: string;
  tabNumber: number;
}

interface FixInfoCardProps {
  value: string;
  info: string;
}
interface ChartConfig {
  data: { month: string; type: string; value: number }[];
  isGroup: boolean;
  xField: string;
  yField: string;
  seriesField: string;
  colorField: (data: { type: string }) => string;
  label: {
    // position: string;
    layout: { type: string }[];
  };
  xAxis: {
    label: {
      autoHide: boolean;
      autoRotate: false;
    };
  };
  yAxis: {
    label: {
      formatter: (text: string) => string;
    };
  };
  meta: {
    month: {
      alias: string;
    };
    value: {
      alias: string;
    };
    type: {
      alias: string;
      formatter?: (value: string) => string; // Optional formatter for the 'type' to ensure it displays properly in the legend
    };
  };
}

interface RtpCalculationResult {
  currentMonthRTP: number;
  previousMonthRTP: number;
  rtpChange: string;
  formattedData: {
    month: string;
    type: string;
    value: number;
  }[];
  currentSpin: number;
  spinchange: number;
  spinchangeDirection: boolean;
  changeDirection: boolean;
  previousMonth: string;
}

const Dashboard: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const gameRecord: GameRecord | null = useSelector(gameRecordsData);
  const vtuAggreagtor:VtuPurchaseAggregates| null = useSelector(vtuTotalPurchases);

  const isLoading = useSelector(gameLoadingState);
  const usersData: UserModel[] | null = useSelector(usersSelectorList);
  const aggregateAmount: TransactionAggregates | null =
    useSelector(getAllAmounts);

  const [isRecordOpen, setRecordOpen] = useState(false);
  const [tabNumber, setTabNumber] = useState(0);

  
  const InfoCard: React.FC<InfoCardProps> = ({
    value,
    change,
    decrease,
    info,
    date,
    tabNumber,
  }) => {
    return (
      <Box
        sx={{
          bgcolor: "background.paper",
          p: 2,
          width: 1,
          border: 1, // Adds border
          borderColor: "grey.300", // Border color
          borderRadius: 2, // Rounded corners
        }}
      >
        <HeaderActions
          title={info}
          triggerTitle="View"
          titleColor="grey"
          titleFontSize="13px"
          titleFontWeight="600"
          iconColor="grey"
          onEdit={() => {
            setRecordOpen(true);
            setTabNumber(tabNumber);
          }}
          sxIcon={{ transform: "scale(1.2)" }}
        />

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h6">{value}</Typography>
          <Box sx={{ textAlign: "right" }}>
            <Typography color={decrease ? "error" : "green"}>
              {decrease ? <TrendingDown /> : <TrendingUp />} {change}
            </Typography>
            <Typography
              sx={{ color: "grey", fontSize: 12, fontWeight: "500" }}
              variant="body2"
            >
              Compared to {date}
            </Typography>
          </Box>
        </Box>
      </Box>
    );
  };
  const FixInfoCard: React.FC<FixInfoCardProps> = ({ value, info }) => {
    return (
      <Paper
        elevation={3}
        sx={{ p: 2, display: "flex", flexDirection: "column" }}
      >
        <HeaderActions
          title={info}
          triggerTitle="View"
          titleFontSize="16px"
          titleFontWeight="800"
          iconColor="grey"
          showMore={false}
          sxIcon={{ transform: "scale(1.2)" }}
        />

        <Box
          p={3}
          mb={1}
          sx={{
            backgroundColor: "#ADD8E6",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Typography
            variant="h4"
            sx={{ fontWeight: "bolder", color: "#4169E1" }}
          >
            {value}
          </Typography>
        </Box>
      </Paper>
    );
  };

  const getTotalRTP = (payload: GameRecord) => {
    const rtp = (payload.totalWon / payload.totalSpent) * 100;

    return rtp.toFixed(1);
  };

  const {
    currentMonthRTP,
    previousMonthRTP,
    rtpChange,
    formattedData,
    currentSpin,
    spinchange,
    spinchangeDirection,
    previousMonth,
    changeDirection,
  } = useMemo<RtpCalculationResult>(() => {
    const formatDate = (
      date: string | number | Date | dayjs.Dayjs | null | undefined,
    ) => dayjs(date, "YYYY-MM").format("MMMM, YYYY");
    const currentMonth = dayjs().format("YYYY-MM");
    const previousMonth = dayjs().subtract(1, "month").format("YYYY-MM");

    const findDataByMonth = (month: string) =>
      gameRecord?.monthlyRecordsData.find((data) => data.month === month);

    const calculateRTP = (data: MonthlyRecordsData | undefined) =>
      data ? (data.totalWon / data.totalSpent) * 100 || 0 : 0;

    const currentData = findDataByMonth(currentMonth);
    const previousData = findDataByMonth(previousMonth);

    const currentRTP = calculateRTP(currentData);
    const previousRTP = calculateRTP(previousData);
    const rtpDifference = currentRTP - previousRTP;
    const change = Math.abs(rtpDifference / Math.max(previousRTP, 1)) * 100;
    const changeDirection = rtpDifference >= 0;

    const currentMontSpin = currentData?.totalSpin ?? 0;
    const previousMontSpin = previousData?.totalSpin ?? 0;
    const spinDifference = currentMontSpin - previousMontSpin;
    const spinchange =
      Math.abs(spinDifference / Math.max(previousMontSpin, 1)) * 100;
    const spinchangeDirection = spinDifference >= 0;

    const formattedData =
      gameRecord?.monthlyRecordsData.flatMap((data) => [
        {
          month: formatDate(data.month),
          type: "Total Spent",
          value: data.totalSpent,
        },
        {
          month: formatDate(data.month),
          type: "Total Won",
          value: data.totalWon,
        },
      ]) || [];

    return {
      currentMonthRTP: currentRTP,
      previousMonthRTP: previousRTP,
      rtpChange: change.toFixed(2),
      formattedData,
      currentSpin: currentMontSpin,
      spinchange,
      spinchangeDirection,
      previousMonth: formatDate(previousMonth),
      changeDirection,
    };
  }, [gameRecord]);

  const {
    currentMonthCashout,
    currentMonthDeposit,
    overallCashout,
    cashoutChange,
    cashoutDirection,
    overallDeposit,
    depositChange,
    depositDirection,
    previousTransactionMonth,
    totalTrasactionCount,
  } = useMemo(() => {
    const formatDate = (
      date: string | number | Date | dayjs.Dayjs | null | undefined,
    ) => dayjs(date).format("MM-YYYY"); // Ensure you use this format everywhere

    const formatForDisplay = (
      date: string | number | Date | dayjs.Dayjs | null | undefined,
    ) => dayjs(date).format("MMMM, YYYY");
    const currentMonth = formatDate(dayjs());
    const previousMonth = formatDate(dayjs().subtract(1, "month"));

    // Safely find data for the current and previous months
    const findCashoutDataByMonth = (month: string) =>
      aggregateAmount?.cashOuts.monthlyTransactions.find(
        (data) => data.month === month,
      );
    const findDepositDataByMonth = (month: string) =>
      aggregateAmount?.deposits.monthlyTransactions.find(
        (data) => data.month === month,
      );

    const currentCashoutData = findCashoutDataByMonth(currentMonth);
    const previousCashoutData = findCashoutDataByMonth(previousMonth);

    const currentDepositData = findDepositDataByMonth(currentMonth);
    const previousDepositData = findDepositDataByMonth(previousMonth);

    // Safely calculate differences, ensuring undefined values are handled
    const cashoutDifference =
      (previousCashoutData?.totalAmount || 0) -
      (currentCashoutData?.totalAmount || 0);
    const cashoutChange =
      Math.abs(
        cashoutDifference / Math.max(previousCashoutData?.totalAmount || 1, 1),
      ) * 100;
    const cashoutChangeDirection = cashoutDifference >= 0;

    const depositDifference =
      (currentDepositData?.totalAmount || 0) -
      (previousDepositData?.totalAmount || 0);
    const depositChange =
      Math.abs(
        depositDifference / Math.max(previousDepositData?.totalAmount || 1, 1),
      ) * 100;
    const depositChangeDirection = depositDifference >= 0;

    const totalCashout = aggregateAmount?.cashOuts.overallCashOut || 0;
    const totalDeposit = aggregateAmount?.deposits.overallDeposit || 0;
    const totalTransactionCount =
      (aggregateAmount?.cashOuts.totalTransactionCount || 0) +
      (aggregateAmount?.deposits.totalTransactionCount || 0);

    return {
      currentMonthCashout: currentCashoutData?.totalAmount || 0,
      currentMonthDeposit: currentDepositData?.totalAmount || 0,

      totalTrasactionCount: totalTransactionCount,
      cashoutChange: cashoutChange,
      depositChange: depositChange,

      overallCashout: totalCashout,
      overallDeposit: totalDeposit,

      cashoutDirection: cashoutChangeDirection,
      depositDirection: depositChangeDirection,

      previousTransactionMonth: formatForDisplay(dayjs().subtract(1, "month")),
    };
  }, [aggregateAmount]);

  const {
    currentMonthPurchase,
      totalPurchaseCount,
      purchaseChange,
      totalPurchase,
      purchasesChangeDirection,
      previousVtuPurchasesMonth,
  } = useMemo(() => {
    const formatDate = (
      date: string | number | Date | dayjs.Dayjs | null | undefined,
    ) => dayjs(date).format("MM-YYYY"); // Ensure you use this format everywhere

    const formatForDisplay = (
      date: string | number | Date | dayjs.Dayjs | null | undefined,
    ) => dayjs(date).format("MMMM, YYYY");
    const currentMonth = formatDate(dayjs());
    const previousMonth = formatDate(dayjs().subtract(1, "month"));

    // Safely find data for the current and previous months
    const findPurchasestDataByMonth = (month: string) =>
    vtuAggreagtor?.monthlyPurchases.find(
        (data) => data.month === month,
      );

    const currentPurchasesData = findPurchasestDataByMonth(currentMonth);
    const previousPurchasesData = findPurchasestDataByMonth(previousMonth);

    // Safely calculate differences, ensuring undefined values are handled
    const purchaseDifference =
      (previousPurchasesData?.totalAmount || 0) -
      (currentPurchasesData?.totalAmount || 0);
    const purchaseChange =
      Math.abs(
        purchaseDifference / Math.max(previousPurchasesData?.totalAmount || 1, 1),
      ) * 100;
    const purchasesChangeDirection = purchaseDifference >= 0;

    const totalPurchase = vtuAggreagtor?.overallPurchases || 0;

    const totalPurchaseCount =
      vtuAggreagtor?.totalPurchasesCount || 0

    return {
      currentMonthPurchase: currentPurchasesData?.totalAmount || 0,
      totalPurchaseCount: totalPurchaseCount,
      purchaseChange: purchaseChange,
      totalPurchase: totalPurchase,
      purchasesChangeDirection: purchasesChangeDirection,
      previousVtuPurchasesMonth: formatForDisplay(dayjs().subtract(1, "month")),
    };
  }, [vtuAggreagtor]);

  const chartConfig: ChartConfig = {
    data: formattedData,
    isGroup: true,
    xField: "month",
    yField: "value",
    seriesField: "type",
    colorField: (data) => (data.type === "Total Spent" ? "#8884d8" : "#82ca9d"),

    label: {
      // position: 'middle',
      layout: [
        { type: "interval-adjust-position" },
        { type: "interval-hide-overlap" },
        { type: "adjust-color" },
      ],
    },
    xAxis: {
      label: {
        autoHide: true,
        autoRotate: false,
      },
    },
    yAxis: {
      label: {
        formatter: (text) => `₦ ${parseInt(text).toLocaleString()}`,
      },
    },
    meta: {
      month: {
        alias: "Month",
      },
      value: {
        alias: "Amount",
      },
      type: {
        alias: "Transaction Type", // Provide a friendly name for legend and tooltip
      },
    },
  };

  if (isLoading || !gameRecord) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <Spin size="large" />
      </Box>
    );
  }
  // Calculate monthly RTP and format data for the bar chart

  return (
    <Box sx={{ p: isMobile ? 1 : 3 }}>
      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        spacing={2}
        mb={3}
      >
        <Grid item xs={12} sm={6}>
          <Typography
            variant="h4"
            gutterBottom
            component="div"
            sx={{ fontWeight: "bold" }}
          >
            Dashboard
          </Typography>
        </Grid>
        <Grid
          item
          container
          xs={12}
          sm={6}
          spacing={2}
          justifyContent="flex-end"
        >
          <Grid item mb={isMobile ? 2 : 1}>
            <Button variant="contained">Export</Button>
          </Grid>
        </Grid>
      </Grid>

      <Grid container spacing={2} marginBottom={4}>
        <Grid item xs={12} sm={6} md={4}>
          <FixInfoCard info="Total Deposit" value={overallDeposit.toString()} />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <InfoCard
            info="Monthly Deposit"
            value={currentMonthDeposit.toString()}
            change={`${depositChange}%`}
            date={previousTransactionMonth}
            decrease={depositDirection ? false : true}
            tabNumber={1}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <FixInfoCard
            info="Total Spins"
            value={gameRecord.totalSpin.toString()}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} marginBottom={4}>
        <Grid item xs={12} sm={6} md={4}>
          <FixInfoCard info="Total RTP" value={getTotalRTP(gameRecord)} />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <InfoCard
            info="Monthly RTP"
            value={currentMonthRTP.toFixed(2)}
            change={`${rtpChange}%`}
            decrease={changeDirection ? false : true}
            date={previousMonth}
            tabNumber={0}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <InfoCard
            info="Monthly Spin"
            value={currentSpin.toString()}
            change={`${spinchange}%`}
            date={previousMonth}
            decrease={spinchangeDirection ? false : true}
            tabNumber={0}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} marginBottom={4}>
        <Grid item xs={12} sm={6} md={4}>
          <FixInfoCard info="Total Cashout" value={overallCashout.toString()} />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <InfoCard
            info="Monthly Cashout"
            value={currentMonthCashout.toString()}
            change={`${cashoutChange}%`}
            date={previousTransactionMonth}
            decrease={cashoutDirection ? false : true}
            tabNumber={2}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <FixInfoCard
            info="Total Transactions"
            value={totalTrasactionCount.toString()}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} marginBottom={4}>
        <Grid item xs={12} sm={6} md={4}>
        <FixInfoCard
            info="Total VTU Purchase"
            value={totalPurchase.toString()}
          />        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <InfoCard
            info="Monthly VTU Purchase"
            value={currentMonthPurchase.toString()}
            change={`${purchaseChange}%`}
            date={previousVtuPurchasesMonth}
            decrease={purchasesChangeDirection ? false : true}
            tabNumber={3}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <FixInfoCard
            info="No. Of VTU Purchases"
            value={totalPurchaseCount.toString()}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4} mb={2}>
          <FixInfoCard
            info="Total Users"
            value={(usersData.length ?? 0).toString()}
          />
        </Grid>
        <Grid item xs={12} md={8}>
          <Box
            mb={2}
            sx={{
              bgcolor: "background.paper",
              p: isMobile ? 1 : 2,
              border: 1, // Adds border
              borderColor: "grey.300", // Border color
              borderRadius: 2, // Rounded corners
            }}
          >
            <Typography variant="h6" gutterBottom>
              Monthly Spent/Won
            </Typography>
            {formattedData && formattedData.length > 0 ? (
              <AntsColumnPlot {...chartConfig} />
            ) : (
              <Typography>No data available</Typography>
            )}
          </Box>
        </Grid>
      </Grid>
      <GameRecordModal
        open={isRecordOpen}
        tabNumber={tabNumber}
        onClose={() => setRecordOpen(false)}
        record={gameRecord}
        transactionData={aggregateAmount}
        vtuPurchases={vtuAggreagtor}
      />
    </Box>
  );
};

export default Dashboard;
