// src/slices/usersSlice.ts
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
  UserState,
  UserModel,
  AllAgentReferedUser,
} from "../../@types/user-model";
import {
  getUsers,
  getAgents,
  fetchAllAgentUsers,
  updateCustomerData,
} from "../thunks/users-thunks";

const initialState: UserState = {
  list: [] as UserModel[],
  agentList: [],
  referredUser: [],
  status: "idle" as "idle" | "loading" | "succeeded" | "failed",
  error: null as string | null,
  loading: false,
  message: null,
};

const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    resetUserMsgError: (state) => {
      state.error = null;
      state.message = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUsers.pending, (state) => {
        state.status = "loading";
        state.message = null;
        state.error = null;
        state.loading = true;
      })
      .addCase(getUsers.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.list = action.payload;
        state.error = null;
        state.message = "User list fetched successfully!";
        state.loading = false;
      })
      .addCase(getUsers.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message || null;
        state.message = null;
        state.loading = false;
      });

    builder
      .addCase(getAgents.pending, (state) => {
        state.status = "loading";
        state.message = null;
        state.error = null;
        state.loading = true;
      })
      .addCase(getAgents.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.agentList = action.payload;
        state.error = null;
        state.message = "User list fetched successfully!";
        state.loading = false;
      })
      .addCase(getAgents.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message || null;
        state.message = null;
        state.loading = false;
      });
    builder
      .addCase(fetchAllAgentUsers.pending, (state) => {
        state.status = "loading";
        state.loading = true;
        state.message = null;
        state.error = null;
      })
      .addCase(
        fetchAllAgentUsers.fulfilled,
        (state, action: PayloadAction<AllAgentReferedUser[]>) => {
          state.status = "succeeded";
          state.loading = false;
          state.referredUser = action.payload;
          state.error = null;
        },
      )
      .addCase(fetchAllAgentUsers.rejected, (state, action) => {
        if (action.payload) {
          state.status = "failed";
          state.error = null;
          state.loading = false;
          state.message = null;
        } else {
          state.status = "failed";
          state.error = action.error.message || null;
          state.loading = false;
          state.message = null;
        }
      });
    builder
      .addCase(updateCustomerData.pending, (state) => {
        state.status = "loading";
        state.loading = true;
        state.message = null;
        state.error = null;
      })
      .addCase(updateCustomerData.fulfilled, (state) => {
        state.status = "succeeded";
        state.loading = false;
        state.error = null;
      })
      .addCase(updateCustomerData.rejected, (state, action) => {
        if (action.payload) {
          state.status = "failed";
          state.error = null;
          state.loading = false;
          state.message = null;
        } else {
          state.status = "failed";
          state.error = action.error.message || null;
          state.loading = false;
          state.message = null;
        }
      });
  },
});

export const { resetUserMsgError } = usersSlice.actions;
export default usersSlice.reducer;
