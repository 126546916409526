import React, { useEffect, useMemo } from "react";
import { Box, Grid, Typography, useTheme } from "@mui/material";

import { useDispatch, useSelector } from "react-redux";
import { usersSelectorList } from "../../../../../selectors/userSelector";
import { UserModel } from "../../../../../@types/user-model";
import ReusableTable from "../../../../../components/reusable-table";
import dayjs from "dayjs";
import { CustomColumn } from "../../../../../@types/table";
import { searchQuery } from "../../../../../selectors/seacrhSelector";
import { resetSearch } from "../../../../../store/slices/search-slice";
import { AppDispatch } from "../../../../../store";
import { Spin } from "antd";
import {
  getAllTransactions,
  walletStateLoading,
} from "../../../../../selectors/walletSelector";
import { TransactionPaymentTableData } from "../../../../../@types/wallet";
import { formatCurrency } from "../../../../../utils";

const CashOutList = () => {
  const dispatch: AppDispatch = useDispatch();

  const transactionData: TransactionPaymentTableData[] | [] =
    useSelector(getAllTransactions);
  const usersData: UserModel[] | null = useSelector(usersSelectorList);

  const isLoading = useSelector(walletStateLoading);
  const search = useSelector(searchQuery);

  const theme = useTheme();

  useEffect(() => {
    dispatch(resetSearch());
  }, [dispatch]);

  if (!transactionData || !usersData) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <Spin size="large" />
      </Box>
    );
  }

  const getUserDetails = (userID: string) => {
    const user = usersData.find((user) => user.userId === userID);
    return (
      user || { firstName: "Unknown", lastName: "Unknown", email: "Unknown" }
    );
  };
  const transactionColumns: CustomColumn<TransactionPaymentTableData>[] = [
    {
      id: "userID",
      label: "User",
      accessor: (item) => {
        const user = getUserDetails(item.userID);
        return `${user.firstName} ${user.lastName}`;
      },
      format: (value) =>
        `${getUserDetails(value).firstName} ${getUserDetails(value).lastName}` ||
        "No user details",
    },
    {
      id: "userID",
      label: "User Email",

      format: (value) => getUserDetails(value).email || "No email",
    },

    { id: "walletID", label: "Wallet ID" },
    {
      id: "amount",
      label: "Amount",
      format: (value: number) => `${formatCurrency(value, true)}`,
    },
    { id: "phoneNumber", label: "Phone Number" },

    { id: "tx_ref", label: "Transaction Ref" },
    { id: "status", label: "Status" },

    { id: "paymentType", label: "Payment Type" },
    {
      id: "data",
      label: "Channel",
      accessor: (item) => {
        if (!item) return null; // Return null immediately if the item is falsy

        // Destructure with defaults to ensure every field is covered
        const { channel = "Not Available" } = item.data || {};

        return {
          channel,
        };
      },
      format: (values) =>
        values ? (
          <div>{values.channel}</div>
        ) : (
          <div>No transaction details available.</div>
        ),
    },
    { id: "reason", label: "Reason" },
    {
      id: "createdAt",
      label: "Created At",
      format: (value: Date) => dayjs(value).format("YYYY-MM-DD"),
    },
  ];

  const filteredTransactions = useMemo(() => {
    const searchLower = search?.toLowerCase().trim();
    if (!searchLower)
      return (
        transactionData?.filter(
          (transaction) => transaction.paymentType.toLowerCase() === "cash-out",
        ) || []
      );

    return (transactionData || []).filter(
      (transaction) =>
        transaction.paymentType === "cash-out" &&
        (transaction.status.toLowerCase().includes(searchLower) ||
          transaction.amount.toString().includes(searchLower) ||
          transaction.phoneNumber.includes(searchLower) ||
          transaction.tx_ref.includes(searchLower) ||
          transaction.walletID.includes(searchLower) ||
          transaction.paymentType.includes(searchLower)),
    );
  }, [transactionData, search]);

  const sortedTransactions = useMemo(() => {
    // Make a copy of filteredTransactions to avoid mutating a potentially immutable array
    const transactionsCopy = (filteredTransactions || []).slice();
    return transactionsCopy.sort(
      (a, b) => dayjs(b.createdAt).unix() - dayjs(a.createdAt).unix(),
    );
  }, [filteredTransactions]);

  if (isLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <Spin size="large" />
      </Box>
    );
  }

  return (
    <Box padding={theme.spacing(3)}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography
            variant="h4"
            gutterBottom
            component="div"
            sx={{ fontWeight: "bold" }}
          >
            Cash Out
          </Typography>
        </Grid>
        {filteredTransactions.length == 0 ? (
          <Grid
            item
            xs={12}
            display="flex"
            justifyContent="center"
            alignContent="center"
          >
            <Typography> No Transaction available</Typography>
          </Grid>
        ) : (
          <Grid item xs={12}>
            <ReusableTable
              data={sortedTransactions || []}
              columns={transactionColumns}
              paginationOptions={{
                rowsPerPage: 20,
                rowsPerPageOptions: [10, 20, 30],
                showPagination: true,
              }}
              enableRowClick={true}
              //   onRowClick={(item) => handleUserClick(item)}
            />
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default CashOutList;
